const dummyClans = [
  'ANONYMOUS',
  'BYTEBUSTERS',
  'CODEKRAKS',
  'DIGIHEROES',
  'DIGITAL SKILLS-KAMP',
  'E-RIDERS',
  'FEVERFREAKS',
  'MINECRAFTERS',
  'MINECRAFTKAMP - GEVORDERDEN',
  'PYTHONS',
  'ROBOTKAMP - EXPERTEN',
  'ROBOTKAMP - GEVORDERDEN',
  'SCHOOLS',
  'START2CODE - 3E & 4E LEERJAAR',
  'START2CODE - 5E & 6E LEERJAAR',
];

export default dummyClans;
