import { useMutation } from '@apollo/client';

import {
  CREATE_SCRATCH_EXERCISE_VERSION,
  REMOVE_SCRATCH_EXERCISE_VERSION,
  UPDATE_SCRATCH_EXERCISE_VERSION,
} from '../../api/exercise';

export default function useExerciseVersionMutation() {
  const createScratchExerciseVersion = useMutation(
    CREATE_SCRATCH_EXERCISE_VERSION,
  );

  const updateScratchExerciseVersion = useMutation(
    UPDATE_SCRATCH_EXERCISE_VERSION,
  );

  const removeScratchExerciseVersion = useMutation(
    REMOVE_SCRATCH_EXERCISE_VERSION,
  );

  return {
    createScratchExerciseVersion,
    updateScratchExerciseVersion,
    removeScratchExerciseVersion,
  };
}
