import { useCallback, useState } from 'react';
import { FaWindowRestore } from 'react-icons/fa';
import { FormikProvider, useFormik } from 'formik';
import { number, object, string } from 'yup';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogHeader,
  Label,
  ActionStyledAsButton,
} from '@ftrprf/tailwind-components';

import useFormatMessage from '../../hooks/useFormatMessage';

import FormikInput from '../Form/FormikInput';
import FormikUrl from '../Form/FormikUrl';

function Plugin({ editorAPI }) {
  const t = useFormatMessage();

  const [isExternalShown, setIsExternalShown] = useState(false);

  const openModal = useCallback(() => setIsExternalShown(true), []);
  const closeModal = useCallback(() => setIsExternalShown(false), []);

  const insertIframeHtml = useCallback(
    (url) => {
      editorAPI.insertHtml(
        `
        <div style="display: grid; width: 100%; place-items: center;">
          <iframe src="${url}"></iframe>
        </div>
       `,
      );
    },
    [editorAPI],
  );

  const formik = useFormik({
    initialValues: {
      url: '',
      width: 300,
      height: 150,
    },
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: false,
    validationSchema: object({
      url: string().url('validation.url').required('validation.empty'),
      width: number().max(1500, 'editor.iframeplugin.error.iframe.size'),
      height: number().max(1500, 'editor.iframeplugin.error.iframe.size'),
    }),
    onSubmit: ({ url }) => {
      insertIframeHtml(url);
      closeModal();
    },
  });

  const { errors, isValid, resetForm, submitForm, values } = formik;

  return (
    <>
      {isExternalShown && (
        <Dialog>
          <DialogHeader>{t('editor.iframeplugin.label')}</DialogHeader>
          <DialogContent>
            <FormikProvider value={formik}>
              <FormikUrl label={t('global.URL')} name="url" />
              <FormikInput
                className="w-1/3"
                label={t('global.height')}
                max="1500"
                min="0"
                name="height"
                type="number"
              />
              <FormikInput
                className="w-1/3"
                label={t('global.width')}
                max="1500"
                min="0"
                name="width"
                type="number"
              />
            </FormikProvider>
            <div>
              <Label>{t('content-editor.header.preview')}</Label>

              <div className="w-full grid place-items-center h-40">
                {!errors.url && values.url && (
                  <iframe
                    height={values.height > 150 ? 150 : values.height}
                    src={values.url}
                    title="iframe source"
                    width={values.width > 300 ? 300 : values.width}
                  />
                )}
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <ActionStyledAsButton
              onClick={() => {
                closeModal();
                resetForm();
              }}
              variant="text"
            >
              {t('global.cancel')}
            </ActionStyledAsButton>
            <ActionStyledAsButton disabled={!isValid} onClick={submitForm}>
              {t('editor.iframeplugin.label')}
            </ActionStyledAsButton>
          </DialogActions>
        </Dialog>
      )}
      <FaWindowRestore
        className="text-base"
        onClick={openModal}
        title={t('editor.iframeplugin.label')}
      />
    </>
  );
}

export function IframePlugin({ ...props }) {
  const t = useFormatMessage();

  // given that the iframePlugin is initialized by the ckeditor, spreading can't be avoided
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Plugin label={t('editor.iframeplugin.label')} {...props} />;
}
