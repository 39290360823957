export function getRidOfFalseAttribute(htmlString) {
  let sanitizedHtml = htmlString.indexOf('false\n')
    ? htmlString.replaceAll('false\n', '')
    : htmlString;
  sanitizedHtml = sanitizedHtml.indexOf('false=""')
    ? sanitizedHtml.replaceAll('false=""', '')
    : sanitizedHtml;

  return sanitizedHtml;
}
