import useFormatMessage from './useFormatMessage';

export default function useCustomMainEditorStyles() {
  const t = useFormatMessage();

  return [
    {
      name: t('content-editor.styles.default'),
      element: 'p',
      styles: {
        'background-color': '#ffffff',
        color: '#000000',
        'font-weight': 'normal',
        'font-family': 'var(--default-font)',
        'font-size': '24px',
        padding: '0px 0px',
      },
    },
    {
      name: t('content-editor.styles.intertitle'),
      element: 'h1',
      styles: {
        'font-family': 'var(--titles-font)',
        'font-weight': '700',
        'font-size': '36px',
        'background-color': '#ffffff',
        margin: '24px 0px',
        color: '#2980b9',
      },
    },
    {
      name: t('content-editor.styles.subtitle'),
      element: 'h2',
      styles: {
        'font-family': 'var(--titles-font)',
        'font-weight': '700',
        'font-size': '28px',
        margin: '16px 0px',
        color: '#3498db',
      },
    },
    {
      name: t('content-editor.styles.question'),
      element: 'h3',
      styles: {
        'font-family': 'var(--titles-font)',
        'font-weight': 'normal',
        'font-size': '28px',
        margin: '24px 0px',
        color: '#000000',
        'text-align': 'center',
      },
    },
    {
      // Replaced with the "Default" block style, could be removed in the future.
      // Keeping it for now so CKeditor will show the correct style in the GUI
      name: t('content-editor.styles.text'),
      element: 'span',
      styles: {
        'background-color': '#ffffff',
        color: '#000000',
        'font-family': 'var(--default-font)',
        'font-size': '24px',
        padding: '0px 0px',
      },
    },
    {
      name: t('content-editor.styles.definition'),
      element: 'span',
      styles: {
        'background-color': '#3498db',
        color: '#ffffff',
        'font-weight': 'bold',
        'font-size': '24px',
        padding: '0px 10px',
      },
    },
    {
      name: t('content-editor.styles.tip'),
      element: 'span',
      styles: {
        'background-color': '#c0c0c0',
        color: '#ffffff',
        'font-weight': 'bold',
        'font-size': '24px',
        padding: '0px 10px',
      },
    },
  ];
}
