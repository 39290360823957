import parse from 'html-react-parser';
import { useRecoilState } from 'recoil';

import alphabet from '../../../../../utils/functions/alphabet';
import c from '../../../../../utils/functions/c';
import useQuestionAnswerMultipleChoice from '../../../hooks/useQuestionAnswerMultipleChoice';

import { sideBarAtom } from '../../../utils/atom';

import { SIDE_BARS } from '../../SideBars/SideBarContextProvider';
import useFormatMessage from '../../../../../hooks/useFormatMessage';

export default function QuestionAnswerMultipleChoice({
  index,
  questionAnswerMultipleChoiceId,
  questionId,
}) {
  const [sideBar, setSideBar] = useRecoilState(sideBarAtom);

  const t = useFormatMessage();

  const { questionAnswerMultipleChoice: answer } =
    useQuestionAnswerMultipleChoice(questionAnswerMultipleChoiceId);

  if (!answer) {
    return null;
  }

  const isActive =
    sideBar?.type === SIDE_BARS.QUESTION_ANSWER_MULTIPLE_CHOICE_SIDEBAR &&
    sideBar.id === answer.id;

  return (
    <div className="relative cursor-pointer w-full h-full min-h-16">
      <div
        className={c(
          'absolute right-0 top-0 w-6 h-6 text-white font-semibold flex items-center justify-center -mt-2 rounded',
          answer.correct ? 'bg-green-500' : 'bg-accent-300',
        )}
      >
        {alphabet(index)}
      </div>

      <div
        className={c(
          'w-full h-full border p-2 rounded',
          'flex items-center justify-center text-left',
          !isActive && 'border-gray-400 bg-white',
          isActive && 'border-accent-300 bg-accent-50',
          'SlideViewerTextSlide',
        )}
      >
        <div className="break-words w-full">{parse(answer.value) || ''}</div>
        <button
          className="absolute left-0 top-0 w-full h-full"
          onClick={(e) => {
            e.stopPropagation();

            setSideBar({
              type: SIDE_BARS.QUESTION_ANSWER_MULTIPLE_CHOICE_SIDEBAR,
              id: answer.id,
              questionId,
              index,
            });
          }}
          type="button"
        >
          <span className="sr-only">{`${t(
            'question-answer-multiple-choice.button',
          )} ${alphabet(index)}`}</span>
        </button>
      </div>
    </div>
  );
}
