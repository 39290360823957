import { useContext } from 'react';
import { useMutation } from '@apollo/client';

import { NotificationContext } from '@ftrprf/tailwind-components';

import useFormatMessage from 'hooks/useFormatMessage';

import { MOVE_FOLDER_CONTENT } from 'api/filemanager';

export default function useFileMove() {
  const t = useFormatMessage();
  const { addNotification } = useContext(NotificationContext);
  const [moveFiles, { error, loading }] = useMutation(MOVE_FOLDER_CONTENT, {
    onCompleted: () => {
      addNotification({
        type: 'success',
        content: t('file.manager.content-moved'),
      });
    },
  });

  return { moveFiles, loading, error };
}
