export const swap = (array, index1, index2) => {
  const swappedArray = [...array];

  swappedArray[index1] = array[index2];
  swappedArray[index2] = array[index1];

  return swappedArray;
};

export const reorderItems = (items, selectedItemIds, result) => {
  const selectedItems = [];

  const orderedItems = items.map((item) =>
    selectedItemIds.includes(item.id)
      ? selectedItems.push(item) && undefined
      : item,
  );

  orderedItems.splice(result.source.index, 1);
  orderedItems.splice(result.destination.index, 0, ...selectedItems);

  return orderedItems.filter(Boolean);
};

export const multiSelectTo = (selectedSlide, slides, selectedSlideIds) => {
  const newSelectedSlideIndex = slides.findIndex(
    (slide) => slide.id === selectedSlide.id,
  );

  const lastSelectedSlideId = selectedSlideIds[selectedSlideIds.length - 1];
  const lastSelectedSlideIndex = slides.findIndex(
    (slide) => slide.id === lastSelectedSlideId,
  );

  const newSelectedSlideIds = [...selectedSlideIds];

  if (newSelectedSlideIndex > lastSelectedSlideIndex) {
    for (let i = lastSelectedSlideIndex + 1; i <= newSelectedSlideIndex; i++) {
      newSelectedSlideIds.push(slides[i].id);
    }
  } else {
    for (let i = newSelectedSlideIndex; i < lastSelectedSlideIndex; i++) {
      newSelectedSlideIds.push(slides[i].id);
    }
  }

  return newSelectedSlideIds;
};
