import PaginatedContentCardList from '../../../components/PaginatedContentCardList';

import useArchiveContentMutation from '../../../hooks/graphql/useArchiveContentMutation';
import useCopyContentMutation from '../../../hooks/graphql/useCopyContentMutation';
import useRemoveContentMutation from '../../../hooks/graphql/useRemoveContentMutation';
import useUnarchiveContentMutation from '../../../hooks/graphql/useUnarchiveContentMutation';

import StudioContentCard from './ContentCard/StudioContentCard';
import { studioDataTestIds } from '../../../utils/constants/studioDataTestIds';

export default function ContentOverviewCards({
  contentList,
  contentType,
  currentPage,
  refetchContentList,
  setCurrentPage,
  totalItems,
  totalPages,
}) {
  const copy = useCopyContentMutation();
  const archive = useArchiveContentMutation();
  const unarchive = useUnarchiveContentMutation();
  const remove = useRemoveContentMutation();

  const itemRender = ({ item }) => (
    <StudioContentCard
      key={item.id}
      archive={() => archive(item.id)}
      content={item}
      contentType={contentType}
      copy={() => copy(item.id).then(refetchContentList)}
      remove={() =>
        remove(item).then(() => {
          if (contentList.length === 1 && currentPage === totalPages) {
            setCurrentPage(totalPages - 1);
          }

          refetchContentList();
        })
      }
      unarchive={() => unarchive(item.id)}
    />
  );

  return (
    <div
      className="w-full flex flex-col"
      data-test={studioDataTestIds.lessons.cardsList}
    >
      <PaginatedContentCardList
        currentPage={currentPage}
        itemRender={itemRender}
        items={contentList}
        setCurrentPage={setCurrentPage}
        totalItems={totalItems}
        totalPages={totalPages}
      />
    </div>
  );
}
