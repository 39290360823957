import { Form, Formik } from 'formik';
import { object, string } from 'yup';
import {
  ActionStyledAsButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogHeader,
} from '@ftrprf/tailwind-components';
import useFormatMessage from 'hooks/useFormatMessage';
import FormikUrl from '../Form/FormikUrl';

export function UrlDialog({ setImageUrl, setOpenExternalUrlDialog }) {
  const t = useFormatMessage();

  return (
    <Dialog onDismiss={() => setOpenExternalUrlDialog(false)}>
      <Formik
        initialValues={{
          url: '',
          editorVersion: 5,
        }}
        onSubmit={({ url }) => {
          setImageUrl(url);
          setOpenExternalUrlDialog(false);
        }}
        validateOnBlur={false}
        validateOnChange
        validationSchema={object({
          url: string().url('validation.url').required('validation.empty'),
        })}
      >
        {({ isSubmitting, isValid, submitCount }) => (
          <Form>
            <DialogHeader>
              {t('editor.imageplugin.label-external')}
            </DialogHeader>
            <DialogContent>
              <FormikUrl label={t('global.URL')} name="url" />
            </DialogContent>
            <DialogActions>
              <ActionStyledAsButton
                onClick={() => setOpenExternalUrlDialog(false)}
                variant="text"
              >
                {t('global.cancel')}
              </ActionStyledAsButton>
              <ActionStyledAsButton
                disabled={isSubmitting || (!isValid && submitCount > 0)}
                type="submit"
              >
                {t('editor.imageplugin.label')}
              </ActionStyledAsButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}
