import { useContext, useEffect } from 'react';
import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';

import { useMsal } from '@azure/msal-react';
import ReactGA from 'react-ga4';

import { NotificationBar } from '@ftrprf/tailwind-components';

import ExamContentEditorContainer from 'pages/ContentEditor/ExamContainer';
import LessonContentEditorContainer from 'pages/ContentEditor/LessonContainer';
import ContentOverviewContainer from 'pages/ContentOverview/ContentOverviewContainer';
import ErrorPage from 'pages/ErrorPage';
import ExerciseContainer from 'pages/Exercise/ExerciseContainer';
import ExerciseVersionContainer from 'pages/Exercise/ExerciseVersionContainer';
import ExerciseOverviewContainer from 'pages/ExerciseOverview/ExerciseOverviewContainer';
import Logout from 'pages/Logout/Logout';
import SlideViewerContainer from 'pages/SlideViewer/SlideViewerContainer';

import FileManagerContainer from 'components/FileManager/FileManagerContainer';
import { UserContext } from 'providers/UserProvider';
import CopyLesson from 'components/CopyLesson';
import { ArchiveLesson } from 'components/ArchiveLesson';
import { UnarchiveLesson } from 'components/UnarchiveLesson';

import Impersonate from 'pages/Impersonate/Impersonate';
import ImpersonateRedirect from 'pages/Impersonate/ImpersonateRedirect';
import { CodeFeverContext } from 'providers/CodeFeverProvider';
import isCodeFever from 'utils/isCodeFever';
import URLS from 'utils/constants/urls';
import GeneralLayout from './layouts/GeneralLayout';
import FullPageLayout from './layouts/FullPageLayout';
import { handleRedirectAndLocalStorage } from './partials/handleRedirectAndLocalStorage';
import programs from './programs';
import dummyClans from './dummyClans';
import dummyLevels from './dummyLevels';
import dummyVersions from './dummyVersions';

function PrivateOutlet() {
  return <Outlet />;
}

export default function App() {
  const location = useLocation();
  const { signedIn } = useContext(UserContext);
  const {
    setClans,
    setLessonNumbers,
    setLevels,
    setProgramTypes,
    setPrograms,
    setVersions,
  } = useContext(CodeFeverContext);

  const { inProgress, instance } = useMsal();
  const account = instance.getActiveAccount();

  useEffect(() => {
    handleRedirectAndLocalStorage(account, inProgress, instance);
  }, [account, inProgress, instance]);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      ReactGA.initialize('G-98CW94G71E');
    }
  }, []);

  useEffect(() => {
    if (isCodeFever) {
      setClans(dummyClans);
      setLevels(dummyLevels);
      setLessonNumbers([1, 2, 3, 4, 5, 6, 7, 9, 10]);
      setVersions(dummyVersions);
      setPrograms(programs);
      setProgramTypes(['series', 'workshop', 'camp']);
    }
  }, [
    setClans,
    setLevels,
    setLessonNumbers,
    setPrograms,
    setProgramTypes,
    setVersions,
  ]);

  return (
    <div className="antialiased w-full h-full flex flex-col flex-grow items-center relative">
      <NotificationBar />

      <Routes>
        {signedIn && (
          <Route element={<PrivateOutlet />} path={URLS.ROOT}>
            <Route element={<Logout />} path={URLS.LOGOUT} />

            <Route element={<Navigate to={URLS.LESSONS} />} path={URLS.ROOT} />

            <Route
              element={
                <GeneralLayout>
                  <ContentOverviewContainer />
                </GeneralLayout>
              }
              path={URLS.LESSONS}
            />

            <Route
              element={
                <GeneralLayout>
                  <Impersonate />
                </GeneralLayout>
              }
              path={URLS.IMPERSONATE}
            />
            <Route
              element={
                <GeneralLayout>
                  <ImpersonateRedirect />
                </GeneralLayout>
              }
              path={URLS.IMPERSONATE_ID}
            />

            <Route
              element={
                <GeneralLayout>
                  <ContentOverviewContainer createNewLesson />
                </GeneralLayout>
              }
              path={URLS.LESSONS_NEW}
            />

            <Route
              element={
                <GeneralLayout>
                  <ContentOverviewContainer />
                </GeneralLayout>
              }
              path={URLS.LESSON_TEMPLATES}
            />

            <Route
              element={
                <GeneralLayout>
                  <ContentOverviewContainer />
                </GeneralLayout>
              }
              path={URLS.EXAMS}
            />

            <Route
              element={
                <GeneralLayout>
                  <ExerciseOverviewContainer />
                </GeneralLayout>
              }
              path={URLS.EXERCISES}
            />

            <Route
              element={
                <GeneralLayout>
                  <ExerciseOverviewContainer createNewExercise />
                </GeneralLayout>
              }
              path={URLS.EXERCISES_NEW}
            />

            <Route
              element={
                <FullPageLayout>
                  <ExerciseContainer />
                </FullPageLayout>
              }
              path={URLS.EXERCISE_ID}
            />

            <Route
              element={
                <FullPageLayout>
                  <ExerciseContainer />
                </FullPageLayout>
              }
              path={URLS.EXERCISE_ID_VERSION_ID}
            />

            <Route
              element={
                <FullPageLayout>
                  <ExerciseVersionContainer />
                </FullPageLayout>
              }
              path={URLS.EXERCISE_VERSION_ID}
            />

            <Route
              element={
                <FullPageLayout>
                  <LessonContentEditorContainer />
                </FullPageLayout>
              }
              path={`${URLS.LESSON}/:lessonContentId/edit`}
            />
            <Route
              element={
                <FullPageLayout>
                  <LessonContentEditorContainer />
                </FullPageLayout>
              }
              path={`${URLS.LESSON}/:lessonContentId/edit/:slideId`}
            />

            <Route
              element={
                <FullPageLayout>
                  <ExamContentEditorContainer />
                </FullPageLayout>
              }
              path={`${URLS.EXAM}/:lessonContentId/edit`}
            />
            <Route
              element={
                <FullPageLayout>
                  <ExamContentEditorContainer />
                </FullPageLayout>
              }
              path={`${URLS.EXAM}/:lessonContentId/edit/:slideId`}
            />

            <Route
              element={
                <FullPageLayout>
                  <LessonContentEditorContainer />
                </FullPageLayout>
              }
              path={`${URLS.LESSON_TEMPLATE}/:lessonContentId/edit`}
            />
            <Route
              element={
                <FullPageLayout>
                  <LessonContentEditorContainer />
                </FullPageLayout>
              }
              path={`${URLS.LESSON_TEMPLATE}/:lessonContentId/edit/:slideId`}
            />

            <Route
              element={
                <GeneralLayout>
                  <SlideViewerContainer />
                </GeneralLayout>
              }
              path={URLS.CONTENT_PARAMS}
            />

            <Route
              element={
                <GeneralLayout>
                  <CopyLesson />
                </GeneralLayout>
              }
              path={URLS.COPY}
            />

            <Route
              element={
                <GeneralLayout>
                  <ArchiveLesson />
                </GeneralLayout>
              }
              path={URLS.ARCHIVE}
            />

            <Route
              element={
                <GeneralLayout>
                  <UnarchiveLesson />
                </GeneralLayout>
              }
              path={URLS.UNARCHIVE}
            />

            {/* converts links from filemanager => to files, so intercompatibility is assured */}
            <Route
              element={
                <Navigate
                  replace
                  to={`${URLS.FILES}/${location.pathname.replace(
                    `${URLS.FILE_MANAGER}/`,
                    '',
                  )}`}
                />
              }
              path={`${URLS.FILE_MANAGER}/*`}
            />

            <Route
              element={<FileManagerContainer />}
              path={`${URLS.FILES}/*`}
            />

            <Route
              element={
                <GeneralLayout>
                  <ErrorPage />
                </GeneralLayout>
              }
              path="*"
            />
          </Route>
        )}
      </Routes>
    </div>
  );
}
