import { useMutation, useQuery } from '@apollo/client';
import { useDebounce } from '@ftrprf/hooks';
import {
  ActionStyledAsButton,
  Dialog,
  DialogActions,
  DialogHeader,
  Dropdown,
  Input,
  InputGroup,
  Label,
} from '@ftrprf/tailwind-components';
import {
  CREATE_EXERCISE_VERSION_LINK,
  FIND_SCRATCH_EXERCISE,
} from 'api/exercise';
import useFindAllExercises from 'hooks/graphql/useFindAllExercises';
import useFormatMessage from 'hooks/useFormatMessage';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

export function ExercisePlugin({
  isNew = true,
  onDismiss,
  save,
  selectedExercise: exerciseElement,
}) {
  const t = useFormatMessage();

  const [getLinkId] = useMutation(CREATE_EXERCISE_VERSION_LINK);

  const { data: exerciseToEdit } = useQuery(FIND_SCRATCH_EXERCISE, {
    variables: { id: exerciseElement?.id },
    skip: !exerciseElement,
  });

  const [undebouncedFilters, setFilters] = useState({});
  const {
    formState: { isValid },
    handleSubmit,
    register,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      label: isNew
        ? t('exercise-plugin.url.exercise-default')
        : exerciseElement.label,
      exerciseId: isNew ? null : exerciseElement.id,
      versionId: isNew ? null : exerciseElement.versionId,
    },
  });

  const filters = useDebounce(undebouncedFilters, 200);

  const { data, loading } = useFindAllExercises({
    currentPage: 1,
    filters,
  });
  const [prevAllExercises, setPrevAllExercises] = useState([]);

  const exerciseDropdownOptions = useMemo(() => {
    const options = [
      ...(data?.findAllExercises?.content.map((exercise) => ({
        value: exercise.id,
        label: exercise.title,
      })) || []),
    ];

    if (exerciseToEdit) options.push(exerciseToEdit);

    return options;
  }, [data, exerciseToEdit]);

  const selectedExercise = useMemo(() => {
    if (exerciseToEdit && watch('exerciseId') === exerciseToEdit.id) {
      return exerciseToEdit;
    }

    return prevAllExercises?.find(
      (exercise) => exercise.id === watch('exerciseId'),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch('exerciseId'), prevAllExercises]);

  const onSave = useCallback(async () => {
    const { label, versionId: watchedVersionId } = watch();
    const versionId = watchedVersionId || selectedExercise.versions[0].id;
    const { data: linkData } = await getLinkId({
      variables: {
        exerciseVersionId: versionId,
      },
    });

    save(
      `<a class='SlideViewer__StyledButton SlideViewer__ExerciseLink' data-exercise-id="${selectedExercise.id}" data-studio-link-id="${linkData.createExerciseVersionLink.id}"
      data-version-id="${versionId}"
      'target="_blank">${label}</a>`,
    );
    onDismiss();
  }, [
    getLinkId,
    onDismiss,
    save,
    selectedExercise?.id,
    selectedExercise?.versions,
    watch,
  ]);

  useEffect(() => {
    setPrevAllExercises((prev) => [
      ...(prev ?? []),
      ...(data?.findAllExercises?.content ?? []),
    ]);
  }, [data]);

  return (
    <Dialog>
      <DialogHeader>
        {t(`link-plugin.url.${isNew ? 'add' : 'modify'}-exercise`)}
      </DialogHeader>
      <form className="m-4 mb-0" onSubmit={handleSubmit(onSave)}>
        <InputGroup>
          <Label className="font-bold">
            {t('content-editor.buttons.exercise')}
          </Label>
          <Dropdown
            isClearable
            isLoading={loading}
            onChange={(value) => setValue('exerciseId', value)}
            onInputChange={(title) => setFilters({ title })}
            options={exerciseDropdownOptions}
            value={loading ? null : watch('exerciseId')}
          />
        </InputGroup>
        <InputGroup>
          <Label className="font-bold">{t('link-plugin.display-text')}</Label>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <Input {...register('label', { required: true })} />
        </InputGroup>
        <InputGroup>
          <Label className="font-bold">
            {t('content-overview.filters.version')}
          </Label>
          <Dropdown
            onChange={(value) => setValue('versionId', value)}
            options={selectedExercise?.versions.map((version) => ({
              value: version.id,
              label: version.name || `${version.versionType} - ${version.id}`,
            }))}
            value={watch('versionId') || selectedExercise?.versions[0]?.id}
          />
        </InputGroup>
        <DialogActions>
          <ActionStyledAsButton
            onClick={(e) => {
              e.preventDefault();
              onDismiss();
            }}
            variant="text"
          >
            {t('global.cancel')}
          </ActionStyledAsButton>
          <ActionStyledAsButton
            disabled={!selectedExercise || !isValid}
            onClick={onSave}
            type="button"
          >
            {t(`link-plugin.url.${isNew ? 'add' : 'modify'}-exercise`)}
          </ActionStyledAsButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}
