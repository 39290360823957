const dummyVersions = [
  '0.5',
  '1.0',
  '1.0.5',
  '1.1',
  '1.1.5',
  '1.2',
  '2.0',
  '2.1',
  '3.0',
  '3.1',
  '3.2',
  '4.0',
  '4.1',
];

export default dummyVersions;
