import { useContext, useEffect } from 'react';
import { FileManagerContext } from './FileManagerContextProvider';
import FileManagerTree from './partials/FileManagerTree';
import { SearchInput } from './partials/SearchInput';
import CurrentPath from './partials/CurrentPath';
import FolderDetail from './partials/FolderDetail';

export default function EmbeddedFileManager({ contentFilter, onFileClick }) {
  const { activePath, fileTreeKey, selectedContent } =
    useContext(FileManagerContext);

  useEffect(() => {
    if (selectedContent.length === 1) {
      onFileClick(selectedContent[0]);
    }
  }, [onFileClick, selectedContent]);

  return (
    <>
      {activePath && <FileManagerTree key={fileTreeKey} className="hidden" />}
      <div className="m-2 flex">
        <SearchInput />
      </div>
      <CurrentPath />
      <div className="overflow-auto grow">
        <FolderDetail contentFilter={contentFilter} isEmbedded />
      </div>
    </>
  );
}
