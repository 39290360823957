import { InteractionStatus } from '@azure/msal-browser';

import { clearCacheAndCookies } from '../../utils/functions/clearCacheAndCookies';

export function handleRedirectAndLocalStorage(account, inProgress, instance) {
  if (!account && inProgress === InteractionStatus.None) {
    clearCacheAndCookies();

    instance.loginRedirect().catch(() => instance.handleRedirectPromise());
  }
}
