import { Dropdown, InputGroup, Label } from '@ftrprf/tailwind-components';
import { Controller } from 'react-hook-form';
import useFormatMessage from 'hooks/useFormatMessage';
import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { FIND_ENDGOALS_BY_KEY, FIND_TAGS_WITH_NAME } from 'api/content';
import useDebounce from 'hooks/useDebounce';
import { mapTagToOption } from './helpers';

export function BetaUserOnlyFields({ content, control, filterInput }) {
  const t = useFormatMessage();
  const [tagsFilter, setTagsFilter] = useState('');
  const [endGoalsFilter, setEndGoalsFilter] = useState('');
  const debouncedTagsFilter = useDebounce(tagsFilter, 500);
  const debouncedEndGoalsFilter = useDebounce(endGoalsFilter, 500);

  const handleTagsInputChange = (value) => {
    setTagsFilter(value);
  };
  const { data: tagsData, fetchMore: fetchMoreTags } = useQuery(
    FIND_TAGS_WITH_NAME,
    {
      variables: {
        name: debouncedTagsFilter,
      },
    },
  );

  useEffect(() => {
    void fetchMoreTags({
      variables: {
        name: debouncedTagsFilter,
      },
    });
  }, [debouncedTagsFilter, fetchMoreTags]);

  /**
   * The two below variables are unused because we currently do not use endGoals.
   * They also don't get saved in the backend atm (backend bug).
   * As this is not used we're suppressing the warnings & commenting out the jsx for it.
   * The idea is to use it in the future, so don't throw it away!!!
   */
  // eslint-disable-next-line no-unused-vars
  const handleEndGoalsInputChange = (value) => {
    setEndGoalsFilter(value);
  };
  // eslint-disable-next-line no-unused-vars
  const { data: endGoalsData, fetchMore: fetchMoreEndGoals } = useQuery(
    FIND_ENDGOALS_BY_KEY,
    {
      variables: {
        key: debouncedEndGoalsFilter,
      },
    },
  );

  useEffect(() => {
    void fetchMoreEndGoals({
      variables: {
        name: debouncedEndGoalsFilter,
      },
    });
  }, [debouncedEndGoalsFilter, fetchMoreEndGoals]);

  return (
    <InputGroup>
      <Label className="mt-3" htmlFor="contentMetaDialog_contentTags">
        {t('content.tags')}
      </Label>
      <Controller
        control={control}
        name="tags"
        render={({ field: { onBlur, onChange, value } }) => (
          <Dropdown
            creatable
            defaultValue={content?.tags?.map(mapTagToOption) || []}
            inputId="contentMetaDialog_contentTags"
            isMulti
            onBlur={onBlur}
            onChange={onChange}
            onInputChange={handleTagsInputChange}
            onKeyDown={filterInput}
            options={tagsData?.findTagsWithName?.map(mapTagToOption)}
            value={value}
          />
        )}
        rules={{
          required: {
            value: false,
            message: t('validation.required'),
          },
        }}
        type="select"
      />

      {/*
          This currently does not get saved in the backend (we do send it correctly there).
          As this is not used we're just commenting this out for now, but the idea is to use it in the future.
       */}
      {/* <Label className="mt-3" htmlFor="contentMetaDataDialog_endGoals"> */}
      {/*  {t('content.endGoals')} */}
      {/* </Label> */}
      {/* <Controller */}
      {/*  control={control} */}
      {/*  name="endGoals" */}
      {/*  render={({ field: { onBlur, onChange, value } }) => ( */}
      {/*    <Dropdown */}
      {/*      defaultValue={content?.endGoals?.map(mapEndGoalToOption) || []} */}
      {/*      endgoal */}
      {/*      inputId="contentMetaDataDialog_endGoals" */}
      {/*      isMulti */}
      {/*      onBlur={onBlur} */}
      {/*      onChange={onChange} */}
      {/*      onInputChange={handleEndGoalsInputChange} */}
      {/*      options={endGoalsData?.findEndGoalsByKey?.map(mapEndGoalToOption)} */}
      {/*      value={value} */}
      {/*    /> */}
      {/*  )} */}
      {/*  rules={{ */}
      {/*    required: { */}
      {/*      value: false, */}
      {/*      message: t('validation.required'), */}
      {/*    }, */}
      {/*  }} */}
      {/*  type="select" */}
      {/* /> */}
    </InputGroup>
  );
}
