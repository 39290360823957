import {
  ActionStyledAsButton,
  Dialog,
  DialogActions,
  DialogHeader,
} from '@ftrprf/tailwind-components';
import EmbeddedFileManagerContainer from 'components/FileManager/EmbeddedFileManagerContainer';
import { imageFileExtensions } from 'components/FileManager/constants/imageFileExtensions';
import useFormatMessage from 'hooks/useFormatMessage';
import { FILES } from 'utils/constants/urls';

const imageHtml = (src) =>
  `<span class="image-inline ck-widget ck-widget_with-resizer ck-widget_selected" contenteditable="false"><img src='${src}'/></span>`;

export function ImagePlugin({ insert, onDismiss }) {
  const t = useFormatMessage();

  const imageFilter = (c) =>
    !c.isFile || imageFileExtensions.includes(c.extension?.toLowerCase());

  return (
    <Dialog className="my-2 h-[70vh]" onDismiss={onDismiss}>
      <DialogHeader
        otherButton={
          <ActionStyledAsButton href={FILES} size="small" target="_blank">
            {t('file.manager.manage-files')}
          </ActionStyledAsButton>
        }
      >
        {t('content-selector.image')}
      </DialogHeader>
      <EmbeddedFileManagerContainer
        contentFilter={imageFilter}
        onFileClick={(file) => {
          insert(imageHtml(file.blobUri));
          onDismiss();
        }}
      />
      <DialogActions>
        <ActionStyledAsButton onClick={onDismiss} variant="text">
          {t('global.cancel')}
        </ActionStyledAsButton>
      </DialogActions>
    </Dialog>
  );
}
