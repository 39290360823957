import {
    AssistantTranslations,
    ContentFlowTranslations,
    DialogTranslations,
    GeneralTranslations,
    NotificationTranslations,
    StyledSlideViewerTranslations
} from '@ftrprf/tailwind-components';

// Add external translations in the array below
const externalTranslations = [
  DialogTranslations,
  NotificationTranslations,
  ContentFlowTranslations,
  StyledSlideViewerTranslations,
  GeneralTranslations,
  AssistantTranslations
];

export const externalEn = Object.assign(
  {},
  ...externalTranslations.flatMap((tr) => tr.en),
);
export const externalEs = Object.assign(
  {},
  ...externalTranslations.flatMap((tr) => tr.es),
);
export const externalDe = Object.assign(
  {},
  ...externalTranslations.flatMap((tr) => tr.de),
);
export const externalFr = Object.assign(
  {},
  ...externalTranslations.flatMap((tr) => tr.fr),
);

export const externalNl = Object.assign(
  {},
  ...externalTranslations.flatMap((tr) => tr.nl),
);
