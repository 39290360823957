import { useField } from 'formik';

import { Input, InputGroup, Label } from '@ftrprf/tailwind-components';

import useFormatMessage from '../../hooks/useFormatMessage';

export default function FormikUrl({ label, ...props }) {
  const t = useFormatMessage();
  const [field, meta, helpers] = useField(props);

  return (
    <InputGroup>
      {label && (
        <Label
          aria-labelledby={props.id || props.name}
          htmlFor={props.id || props.name}
        >
          {label}{' '}
          {meta.touched && meta.error ? (
            <span className="mt-1 text-sm text-red-700">- {t(meta.error)}</span>
          ) : null}
        </Label>
      )}
      <Input
        id={props.id || props.name}
        type="url"
        {...field}
        {...props}
        onBlur={(e) => {
          helpers.setTouched(true);

          const { value } = e.target;

          return helpers.setValue(
            value.includes('://') || value.includes('localhost')
              ? value
              : `http://${value}`,
          );
        }}
      />
    </InputGroup>
  );
}
