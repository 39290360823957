/* eslint-disable react/jsx-props-no-spreading */
import {
  ActionStyledAsButton,
  CheckBox,
  Dialog,
  DialogActions,
  DialogHeader,
  Input,
  InputGroup,
  Label,
  STYLED_BUTTON_CLASS,
} from '@ftrprf/tailwind-components';
import useFormatMessage from 'hooks/useFormatMessage';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import c from 'utils/functions/c';
import { object, string } from 'yup';
import { PreviewLink } from './partials/PreviewLink';
import { styledLinks } from './helpers/styledLinks';
import { determineClasses } from './helpers/determineClasses';
import { generatePrefixedType } from './helpers/generatePrefixedType';
import { sanitizeFilename } from './helpers/sanitizeFilename';
import {
  HTTPS_PREFIX,
  MAIL_PREFIX,
  SLIDE_PREFIX,
  TEL_PREFIX,
  urlMinLength,
} from '../../../../../utils/constants/urlRequirements';

export const TYPE_SLIDE_PREFIX = 'slide://';

export function LinkPlugin({ isNew = true, onDismiss, save, selectedLink }) {
  const t = useFormatMessage();
  const [checked, setChecked] = useState(selectedLink?.isExternal ?? isNew);
  const {
    formState: { errors, isValid },
    handleSubmit,
    register,
    setValue,
    trigger,
    watch,
  } = useForm({
    defaultValues: {
      type: selectedLink?.type ?? null,
      isExternal: checked,
      label: selectedLink?.label ?? '',
      url: selectedLink?.url ?? null,
      fileName: selectedLink?.fileName ?? '',
    },
    mode: 'all',
  });
  const styledLinksTypes = [
    { value: styledLinks.VIDEO, label: t('link-plugin.type.video') },
    { value: styledLinks.SLIDE, label: t('link-plugin.type.slide') },
    { value: styledLinks.EXTERNAL, label: t('link-plugin.type.external') },
    { value: styledLinks.OTHER, label: t('link-plugin.type.other') },
    { value: styledLinks.DOWNLOAD, label: t('link-plugin.type.download') },
  ];
  const onSave = useCallback(async () => {
    // we do our own state management here, because we use a custom checkbox component.
    // The integration with react-hook-form doesn't work as desired, so we side-step it.
    setValue('isExternal', checked);
    const { fileName, label, type, url } = watch();
    const isDownload = type === styledLinks.DOWNLOAD;
    const slideId = url.startsWith(TYPE_SLIDE_PREFIX)
      ? url.replace(TYPE_SLIDE_PREFIX, '')
      : null;
    const downloadFilename = fileName
      ? sanitizeFilename(fileName)
      : sanitizeFilename(label);

    /**
     * url cannot be href, otherwise it opens in the editor the link, instead of opening the editing modal.
     */
    save(
      `&nbsp;&nbsp;<a
      class="${c(determineClasses(type), 'cursor-pointer select-none mb-2')}"
      ${checked ? 'target="_blank" rel="noreferrer noopener"' : ''}
      ${
        isDownload
          ? `download-file-name="${downloadFilename}" download-url="${url}"`
          : ''
      }
      ${Boolean(slideId) ? `data-target-slide-id=${slideId}` : ''}
      ${!isDownload && !Boolean(slideId) ? `data-href="${url}"` : ''}
    >
      ${label.trim() || url.trim()}
    </a>&nbsp;`,
    );
    onDismiss();
  }, [checked, onDismiss, save, setValue, watch]);
  const validateUrl = async (value) => {
    const trimmedValue = value.trim();
    const isSlideLink = trimmedValue.startsWith(SLIDE_PREFIX);
    const isMailLink = trimmedValue.startsWith(MAIL_PREFIX);
    const isTelLink = trimmedValue.startsWith(TEL_PREFIX);
    const isHttpsLink = trimmedValue.startsWith(HTTPS_PREFIX);
    const isValidNonSlideLink = isHttpsLink || isMailLink || isTelLink;

    if (isSlideLink) {
      setValue('type', styledLinks.SLIDE);
      return true;
    }

    if (isValidNonSlideLink) {
      return true;
    }

    const type = watch('type');
    const isNotSlideType = type !== styledLinks.SLIDE;

    if (isNotSlideType) {
      return t('link-plugin.url.use-https');
    }

    const urlSchema = object({
      url: string().url('validation.url').required('validation.required'),
    });

    return urlSchema
      .validate({ url: trimmedValue })
      .then(() => null)
      .catch((error) => {
        if (error.errors && error.errors.length > 0) {
          return error.errors[0];
        }
        return null;
      });
  };

  return (
    <Dialog onDismiss={onDismiss}>
      <DialogHeader>{t(`link-plugin.url.dialog`)}</DialogHeader>
      <form className="m-4 mb-0" onSubmit={handleSubmit(onSave)}>
        <InputGroup>
          <Label className="font-bold" htmlFor="plugin-link.url" required>
            {t(`link-plugin.url.enter`)}
          </Label>
          <Input
            aria-errormessage="plugin-link.url.error"
            aria-invalid={!!errors.url}
            id="plugin-link.url"
            {...register('url', {
              required: t('validation.required'),
              minLength: {
                value: urlMinLength,
                message: t('link-plugin.url.length'),
              },
              validate: validateUrl,
            })}
          />
        </InputGroup>
        {errors.url && (
          <p className="text-sm text-red-500 mb-2" id="plugin-link.url.error">
            {errors.url.message}
          </p>
        )}
        <InputGroup>
          <Label className="font-bold" htmlFor="plugin-link.label">
            {t('link-plugin.display-text')}
          </Label>
          <Input id="plugin-link.label" {...register('label')} />
        </InputGroup>

        <InputGroup>
          <CheckBox
            checked={checked}
            label={t('link-plugin.open_new_tab')}
            onChange={setChecked}
          />
        </InputGroup>

        <InputGroup>
          <Label className="font-bold" htmlFor="plugin-link.type.dropdown">
            {t('link-plugin.type.label')}
          </Label>

          {Object.values(styledLinksTypes).map(({ label, value: style }) => (
            <button
              key={style}
              className={c(
                STYLED_BUTTON_CLASS,
                'cursor-pointer select-none mb-2',
                generatePrefixedType(style),
                style !== watch('type') && 'opacity-25',
                'mr-4',
              )}
              onClick={() => {
                if (style !== watch('type')) {
                  setValue('type', style);
                } else {
                  setValue('type', null);
                }
                trigger('url');
              }}
              type="button"
            >
              {label}
            </button>
          ))}
        </InputGroup>

        {watch('type') === styledLinks.DOWNLOAD && (
          <InputGroup>
            <Label className="font-bold" htmlFor="link-plugin.file_name">
              {t('link-plugin.file_name')}
            </Label>
            <Input id="link-plugin.file_name" {...register('fileName')} />
          </InputGroup>
        )}

        <PreviewLink
          isExternal={watch('isExternal')}
          label={watch('label')}
          type={watch('type')}
          url={watch('url')}
        />

        <DialogActions>
          <ActionStyledAsButton
            onClick={(e) => {
              e.preventDefault();
              onDismiss();
            }}
            variant="text"
          >
            {t('global.cancel')}
          </ActionStyledAsButton>
          <ActionStyledAsButton
            disabled={!isValid}
            onClick={onSave}
            type="button"
          >
            {t(`link-plugin.url.${isNew ? 'add' : 'modify'}-url`)}
          </ActionStyledAsButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}
