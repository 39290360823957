import { gql } from '@apollo/client';

export const FIND_ALL_TRANSLATIONS = gql`
  query FindAllTranslations(
    $page: Int!
    $size: Int!
    $filter: [JSON]
    $sort: JSON
  ) {
    findAllTranslations(
      page: $page
      size: $size
      filter: $filter
      sort: $sort
    ) {
      total
      pages
      currentPage
      content {
        id
        valueNl
        valueEn
        valueFr
        valueDe
        valueEs
        groupName
      }
    }
  }
`;

export const FIND_ALL_TRANSLATION_GROUPNAMES = gql`
  query FindAllTranslationGroupNames {
    findAllTranslationGroupNames {
      groupName
    }
  }
`;

export const CREATE_TRANSLATION = gql`
  mutation CreateTranslation(
    $id: String!
    $valueNl: String!
    $valueEn: String!
    $valueFr: String!
    $valueDe: String!
    $valueEs: String!
    $groupName: String!
  ) {
    createTranslation(
      input: {
        id: $id
        valueNl: $valueNl
        valueEn: $valueEn
        valueFr: $valueFr
        valueDe: $valueDe
        valueEs: $valueEs
        groupName: $groupName
      }
    ) {
      id
      valueNl
      valueEn
      groupName
    }
  }
`;

export const UPDATE_TRANSLATION = gql`
  mutation UpdateTranslation(
    $id: String!
    $valueNl: String!
    $valueEn: String!
    $valueFr: String!
    $valueDe: String!
    $valueEs: String!
    $groupName: String!
  ) {
    updateTranslation(
      id: $id
      input: {
        valueNl: $valueNl
        valueEn: $valueEn
        valueFr: $valueFr
        valueDe: $valueDe
        valueEs: $valueEs
        groupName: $groupName
      }
    ) {
      id
      valueNl
      valueEn
      valueFr
      valueDe
      valueEs
      groupName
    }
  }
`;
