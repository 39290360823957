import { FolderIcon } from '@heroicons/react/24/solid';
import c from 'utils/functions/c';
import FileIcon from './FileIcon';

const dimensions = 'm-4 w-[80%]';
const color = 'text-gray-500';

export default function ThumbnailLarge({
  blobUri,
  extension,
  isFile,
  thumbnail,
}) {
  if (thumbnail) {
    return (
      <img
        alt=""
        className={c('cursor-not-allowed')}
        onContextMenu={(e) => e.preventDefault()}
        src={thumbnail}
      />
    );
  }

  if (extension === 'svg') {
    return (
      <img
        alt=""
        className={c('cursor-not-allowed')}
        onContextMenu={(e) => e.preventDefault()}
        src={blobUri}
      />
    );
  }

  if (isFile) {
    return (
      <FileIcon color={color} dimensions={dimensions} extension={extension} />
    );
  }

  return <FolderIcon className={c(dimensions, color)} />;
}
