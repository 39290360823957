import { useState } from 'react';

import { Loader } from '@ftrprf/tailwind-components';

import c from 'utils/functions/c';
import { PlusIcon } from '@heroicons/react/24/outline';
import useFormatMessage from '../hooks/useFormatMessage';

export default function DragArea({
  children,
  className,
  isUploading,
  setReturnData = () => {},
  uploadFiles = () => {},
}) {
  const t = useFormatMessage();

  const [isVisible, setIsVisible] = useState(false);

  const onDragEnter = (e) => {
    setIsVisible(true);
    e.stopPropagation();
    e.preventDefault();
  };

  const onDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const onDragLeave = (e) => {
    setIsVisible(false);
    e.stopPropagation();
    e.preventDefault();
  };

  const onDrop = (e) => {
    e.preventDefault();
    uploadFiles(e.dataTransfer.files).then((data) => {
      setReturnData(data);
      setIsVisible(false);
    });
  };

  return (
    <div
      className={c('flex flex-grow h-full w-full overflow-hidden', className)}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      onDragOver={onDragOver}
      onDrop={onDrop}
    >
      {(isVisible || isUploading) && (
        <div
          className={c(
            'w-full flex content-center items-center justify-center',
            !isUploading &&
              'bg-accent-100 border-dashed border-2 bordery-gray-600',
          )}
        >
          {isUploading ? (
            <div className="flex flex-col items-center justify-center">
              <div className="h-5 w-5">
                <Loader />
              </div>
              <span>{t('dragarea.uploading')}</span>
            </div>
          ) : (
            <>
              <PlusIcon className="w-5 h-5" />
              <span className="ml-2 leading-snug">
                {t('content-selector.add')}
              </span>
            </>
          )}
        </div>
      )}
      {!isVisible && !isUploading && children}
    </div>
  );
}
