import Header from 'components/Header';
import FileManager from './FileManager';
import FileManagerContextProvider from './FileManagerContextProvider';
import FileManagerButtons from './partials/FileManagerButtons';

export default function FileManagerContainer() {
  return (
    <main className="h-screen w-screen bg-gray-100">
      <FileManagerContextProvider>
        <div className="sticky top-0 left-0 right-0 z-20">
          <Header />
          <FileManagerButtons />
        </div>
        <FileManager />
      </FileManagerContextProvider>
    </main>
  );
}
