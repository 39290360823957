import {
  Dialog,
  DialogActions,
  DialogHeader,
  ActionStyledAsButton,
  SaveButton,
} from '@ftrprf/tailwind-components';
import CodeMirror from '@uiw/react-codemirror';
import { html } from '@codemirror/lang-html';
import { useState } from 'react';
import useFormatMessage from 'hooks/useFormatMessage';

export function HtmlInsertPlugin({ insert, onDismiss }) {
  const t = useFormatMessage();
  const [value, setValue] = useState();

  const onSubmit = (value) => {
    let html = `${value}`;

    if (html.includes('https://w.soundcloud.com')) {
      const soundCloudTitleRegex = /<div.*/gm;

      html = html.replace(soundCloudTitleRegex, '');
      html = html.replace('width="100%"', 'class="soundcloudPlayer"');
      html = html.replace('height="300"', '');
    }
    insert(html);
    onDismiss();
  };

  return (
    <Dialog className="w-3/4" onDismiss={onDismiss}>
      <DialogHeader>{t('html-insert-plugin.title')}</DialogHeader>
      <CodeMirror
        extensions={[html({})]}
        height="100%"
        minHeight="10rem"
        onChange={setValue}
        value={value || ''}
        width="100%"
      />
      <DialogActions>
        <ActionStyledAsButton onClick={onDismiss} variant="text">
          {t('global.cancel')}
        </ActionStyledAsButton>
        <SaveButton
          onClick={() => {
            onSubmit(value);
          }}
        />
      </DialogActions>
    </Dialog>
  );
}
