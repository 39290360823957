import { Pagination } from '@ftrprf/tailwind-components';
import ContentCardList from './ContentCardList';

const MAX_ITEMS = 12;

export default function PaginatedContentCardList({
  currentPage,
  itemRender,
  items,
  setCurrentPage,
  totalItems,
  totalPages,
}) {
  return (
    <>
      <ContentCardList items={items}>
        {(props) => itemRender(props)}
      </ContentCardList>

      <Pagination
        className="mt-12"
        currentPage={currentPage}
        itemsPerPage={MAX_ITEMS}
        setCurrentPage={(page) => setCurrentPage(page)}
        totalItems={totalItems}
        totalPages={totalPages}
      />
    </>
  );
}
