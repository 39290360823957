import { useContext, useState } from 'react';

import { Label } from '@ftrprf/tailwind-components';

import Editor from 'components/Editor/Editor';
import * as customPlugins from 'components/Editor/sideBarCustomPlugins';

import useFormatMessage from 'hooks/useFormatMessage';
import c from 'utils/functions/c';
import useSlide from '../../hooks/useSlide';

import SideBarContent from './SideBarContent';
// eslint-disable-next-line import/no-cycle
import { Ck5SlideEditorContainer } from '../Ck5Editor/Ck5SlideEditorContainer';
import { SideBarContext } from './SideBarContextProvider';

export default function TeacherInfoSideBar({ disabled, hide, slideId }) {
  const t = useFormatMessage();
  const { isCk5 } = useContext(SideBarContext);

  const [, setLocalInfo] = useState();

  const { slide, update } = useSlide(slideId);

  if (!slide) {
    return null;
  }

  const updateLocalInfo = (info) => {
    const infoValue = info || '';
    if (slide) update({ info: infoValue });
    setLocalInfo(info);
  };

  return (
    <SideBarContent
      className={c('overflow-auto teacher-sidebar', hide && 'hidden')}
      grow
      title={t('teacher-info-sidebar.title')}
    >
      <div className="p-3 flex flex-col flex-grow bg-gray-100">
        <Label className="text-sm text-gray-700">
          {t('teacher-info-sidebar.description')}
        </Label>

        {isCk5 ? (
          <Ck5SlideEditorContainer
            disabled={disabled}
            id={`teacherInfoSidebar${slideId}`}
            isInSideBar
            isLoaded
            onChange={updateLocalInfo}
            value={slide?.info}
          />
        ) : (
          <Editor
            className="border py-1"
            containerClass="TeacherInfoSidebar"
            disabled={disabled}
            id={slideId}
            isMainEditor={false}
            onChange={updateLocalInfo}
            plugins={customPlugins}
            value={slide?.info}
          />
        )}
      </div>
    </SideBarContent>
  );
}
