import { useQuery } from '@apollo/client';

import { FIND_ALL_TRANSLATION_GROUPNAMES } from '../../api/translations';

export default function useTranslationGroupNames() {
  const { data, error, loading, refetch } = useQuery(
    FIND_ALL_TRANSLATION_GROUPNAMES,
  );

  return {
    data: data?.findAllTranslationGroupNames,
    isLoading: loading,
    error,
    refetch,
  };
}
