import { useCallback, useRef } from 'react';
import { gql, useApolloClient } from '@apollo/client';
import { useRecoilState } from 'recoil';

import useFieldMutation from '../../../hooks/graphql/useFieldMutation';

import { UPDATE_HINT } from '../../../api/hint';

import { hintAtom } from '../utils/atom';

const read = (client, id) =>
  client.readFragment({
    id: `Hint:${id}`,
    fragment: gql`
      fragment MyHint on Hint {
        id
        title
        content
      }
    `,
  });

export default function useHint(id) {
  const client = useApolloClient();
  const ref = useRef();

  const [hint, setHint] = useRecoilState(hintAtom(id));

  const refetch = () => {
    setHint(read(client, id));
  };

  const [mutate] = useFieldMutation(UPDATE_HINT, 'Hint');

  const debounceMutation = useCallback(
    (options) => {
      if (ref.current) {
        clearTimeout(ref.current);
      }

      ref.current = setTimeout(() => {
        mutate(options);
      }, 250);
    },
    [ref, mutate],
  );

  if (hint === null) {
    refetch();
  }

  const update = useCallback(
    (variables) => {
      setHint((l) => {
        const newValue = { ...l, ...variables };

        debounceMutation(newValue);

        return newValue;
      });
    },
    [debounceMutation, setHint],
  );

  return {
    hint,
    setHint,
    update,
  };
}
