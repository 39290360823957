import { useContext } from 'react';

import { NotificationContext } from '@ftrprf/tailwind-components';

import { FILE_EXTENSIONS } from 'utils/constants/fileTypes';

import useFileNamesInPath from './graphql/useFileNamesInPath';

import useConfirmationDialog from './useConfirmationDialog';
import useFormatMessage from './useFormatMessage';

export default function useCheckUploadFiles({ accept, maxSize, path }) {
  const t = useFormatMessage();

  const { addNotification } = useContext(NotificationContext);

  const fileNames = useFileNamesInPath(path);

  const { getConfirmation, isDialogOpen, onConfirm, onDismiss } =
    useConfirmationDialog();

  const checkFiles = (files) =>
    Array.from(files).map((file) => {
      const [name] = file.name.split('.');

      if (accept) {
        if (
          !FILE_EXTENSIONS[accept.toUpperCase()].extensions.includes(
            /(?:\.([^.]+))?$/.exec(file.name)[1],
          )
        ) {
          addNotification({
            type: 'error',
            content: t(`content-manager.actions.error.only-${accept}`),
          });
          return null;
        }
      }

      if (maxSize && file.fileSize > maxSize) {
        addNotification({
          type: 'error',
          content: t('content-manager.actions.create-file.file-too-big'),
        });
        return null;
      }

      if (fileNames[`/${name}`]) {
        return { file, name, overwrite: true };
      }
      return { file, name };
    });

  const checkOverwriteConfirm = (files) =>
    new Promise((resolve) => {
      const checkedFiles = checkFiles(files).filter((file) => file !== null);
      const overwriteFiles = checkedFiles.filter((file) => file.overwrite);

      if (overwriteFiles.length > 0) {
        getConfirmation()
          .then((result) => {
            if (result) {
              resolve(checkedFiles);
            } else {
              resolve(checkedFiles.filter((file) => !file.overwrite));
            }
          })
          .catch(() => {
            resolve(checkedFiles.filter((file) => !file.overwrite));
          });
      } else {
        resolve(checkedFiles);
      }
    });

  return {
    checkFiles: checkOverwriteConfirm,
    isOverwriteDialogOpen: isDialogOpen,
    onOverwriteConfirm: onConfirm,
    onOverwriteDismiss: onDismiss,
  };
}
