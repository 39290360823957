import { useCallback, useContext, useLayoutEffect } from 'react';
import { FaRegWindowMaximize } from 'react-icons/fa';

// eslint-disable-next-line import/no-cycle
import {
  SIDE_BARS,
  SideBarContext,
} from '../../pages/ContentEditor/partials/SideBars/SideBarContextProvider';

import useFormatMessage from '../../hooks/useFormatMessage';

import StyledIcon from '../StyledIcon';

const modalHtml = ({ buttonText, modalContent, modalSize, modalTitle }) => {
  const quotsMap = {
    '"': '&quot;',
    "'": '&apos;',
    '`': '&#96;',
  };

  const replacer = (m) => quotsMap[m];

  return `<button label="${buttonText.replace(/"|'|`/g, replacer)}" 
      modalTitle="${modalTitle.replace(/"|'|`/g, replacer)}" 
      modalSize="${modalSize}"
      modalContent="${modalContent.replace(/"|'|`/g, replacer)}" 
      class="SlideViewer__StyledButton SlideViewer__ModalLink"
    >
      ${buttonText}
    </button>
    <br />`;
};

function Plugin({ editorAPI }) {
  const { setSideBar } = useContext(SideBarContext);

  const onAddModal = useCallback(
    ({ buttonText, modalContent, modalSize, modalTitle }) => {
      editorAPI.insertHtml(
        modalHtml({
          buttonText,
          modalTitle,
          modalSize,
          modalContent,
        }),
      );
      setSideBar({ type: SIDE_BARS.DEFAULT });
    },
    [editorAPI, setSideBar],
  );

  const onEditModal = useCallback(
    ({
      buttonText,
      initialModalElement,
      modalContent,
      modalSize,
      modalTitle,
    }) => {
      // eslint-disable-next-line no-param-reassign
      initialModalElement.$.outerHTML = modalHtml({
        buttonText,
        modalTitle,
        modalSize,
        modalContent,
      });
      setSideBar({ type: SIDE_BARS.DEFAULT });
      editorAPI.fire('change');
    },
    [editorAPI, setSideBar],
  );

  useLayoutEffect(() => {
    const openModalEditSideBar = (event) => {
      // process the event only if it was fired from the necessary editor
      if (editorAPI.name === event.detail.editorName) {
        setSideBar({
          type: SIDE_BARS.MODAL_SIDEBAR,
          onSubmit: (formData) =>
            onEditModal({
              ...formData,
              initialModalElement: event.detail.modalElement,
            }),
          isEdit: true,
          initialModal: {
            modalContent:
              event.detail.modalElement.getAttribute('modalcontent'),
            modalTitle: event.detail.modalElement.getAttribute('modaltitle'),
            modalSize: event.detail.modalElement.getAttribute('modalsize'),
            buttonText: event.detail.modalElement.$.outerText,
          },
        });
      }
    };
    window.addEventListener('showModalEdit', openModalEditSideBar);
    return () => {
      window.removeEventListener('showModalEdit', openModalEditSideBar);
    };
  }, [editorAPI, onEditModal, setSideBar]);

  return (
    <StyledIcon
      className="text-sm"
      icon={FaRegWindowMaximize}
      onClick={() =>
        setSideBar({
          type: SIDE_BARS.MODAL_SIDEBAR,
          onSubmit: onAddModal,
        })
      }
    />
  );
}

export function ModalPlugin({ ...props }) {
  const t = useFormatMessage();

  return <Plugin label={t('editor.modalplugin.label')} {...props} />;
}
