import { FaArrowLeft, FaPlus } from 'react-icons/fa';

import c from 'utils/functions/c';
import { baseName } from 'utils/functions/path';
import useFormatMessage from '../../hooks/useFormatMessage';

export default function ContentListSelectorTop({
  currentPath,
  onExternalClick,
  pathStack,
  setCurrentPath,
}) {
  const t = useFormatMessage();

  return (
    <>
      <span
        className={c('mr-2 cursor-pointer', pathStack.isEmpty() && 'hidden')}
      >
        <FaArrowLeft onClick={() => setCurrentPath(pathStack.pop())} />
      </span>
      <span className="font-bold flex-grow">
        {baseName(currentPath) || t('content-selector.title.default')}
      </span>
      <button onClick={onExternalClick} type="button">
        <FaPlus
          onClick={onExternalClick}
          title={t('content-selector.add_external')}
        />
      </button>
    </>
  );
}
