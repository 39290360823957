import {
  Dropdown,
  InputGroup,
  Label,
  VersionLabel,
} from '@ftrprf/tailwind-components';
import { Controller } from 'react-hook-form';
import useFormatMessage from 'hooks/useFormatMessage';
import CONTENT_TYPES from 'utils/constants/contentTypes';

export function ContentTypeAndVersion({ content, control, errors }) {
  const t = useFormatMessage();

  return (
    <InputGroup>
      <Label className="mt-3" htmlFor="contentMetaDialog_contentType">
        {t('content.type')}
      </Label>
      <Controller
        control={control}
        defaultValue={content.type}
        name="type"
        render={({ field: { onBlur, onChange, value } }) => (
          <Dropdown
            inputId="contentMetaDialog_contentType"
            onBlur={onBlur}
            onChange={onChange}
            options={[CONTENT_TYPES.EXAM, CONTENT_TYPES.LESSON]}
            value={value}
          />
        )}
        rules={{
          required: {
            value: true,
            message: t('validation.required'),
          },
        }}
        type="select"
      />
      {errors.type && (
        <span className="text-red-500 text-xs">{errors.type.message}</span>
      )}
      <InputGroup className="mt-3">
        <Label>{t('content.version')}</Label>
        <VersionLabel className="p-1" version={content.version} />
      </InputGroup>
    </InputGroup>
  );
}
