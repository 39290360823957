import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { NotificationContext } from '@ftrprf/tailwind-components';

import contentStates from 'utils/constants/contentStates';
import CONTENT_TYPES from 'utils/constants/contentTypes';
import URLS from 'utils/constants/urls';
import { CodeFeverContext } from '../../providers/CodeFeverProvider';

import useFieldMutation from '../../hooks/graphql/useFieldMutation';
import useDebounce from '../../hooks/useDebounce';
import useFormatMessage from '../../hooks/useFormatMessage';

import {
  CREATE_LESSON_CONTENT,
  CREATE_LESSON_CONTENT_FROM_TEMPLATE,
  FIND_ALL_LESSON_CONTENT,
} from '../../api/content';

import ContentOverview from './ContentOverview';

const isPublished = (state) => {
  if (state === contentStates.CONCEPT) {
    return false;
  }

  if (!state) {
    return null;
  }

  return state === contentStates.PUBLISHED;
};

const isArchived = (state) => {
  if (state === contentStates.CONCEPT) {
    return false;
  }

  if (!state) {
    return null;
  }

  return state === contentStates.ARCHIVED;
};

const getProgramsFromType = (programs, programType) =>
  programs
    .filter((program) => program.type === programType)
    .map((program) => program.id);

const determineContentType = (pathname) => {
  switch (pathname) {
    case URLS.LESSONS:
      return CONTENT_TYPES.LESSON;

    case URLS.LESSON_TEMPLATES:
      return CONTENT_TYPES.LESSON_TEMPLATE;

    case URLS.EXAMS:
      return CONTENT_TYPES.EXAM;

    default:
      return CONTENT_TYPES.LESSON;
  }
};

export default function ContentOverviewContainer({ createNewLesson = false }) {
  const t = useFormatMessage();

  const navigate = useNavigate();
  const { pathname } = useLocation();

  // remove the leading slash, uppercase and removes the trailing s
  const contentType = determineContentType(pathname);

  const [undebouncedFilters, setFilters] = useState({});
  const [currentPage, setCurrentPage] = useState(1);

  const filters = useDebounce(undebouncedFilters, 200);
  const { programs } = useContext(CodeFeverContext);
  const { addNotification } = useContext(NotificationContext);

  // pathname is included in this useEffect
  // because lessons and exams make use of the same component
  useEffect(() => {
    setCurrentPage(1);
  }, [filters, pathname]);

  const queryOptions = {
    variables: {
      page: currentPage - 1,
      tags: filters.tags,
      sort: { modifiedOn: 'DESC' },
      filter: [
        { key: 'title', value: filters.title?.trim(), operation: '~' },
        { key: 'type', value: contentType, operation: ':' },
        { key: 'published', value: isPublished(filters.state), operation: ':' },
        { key: 'archived', value: isArchived(filters.state), operation: ':' },
        { key: 'level', value: filters.level, operation: ':' },
        {
          key: 'programId',
          value:
            filters.programType &&
            getProgramsFromType(programs, filters.programType),
          operation: ':',
        },
        { key: 'clan', value: filters.clan, operation: ':' },
        { key: 'level', value: filters.level, operation: ':' },
        {
          key: 'programLessonId',
          value: filters.programLessonId,
          operation: ':',
        },
      ].filter((f) => f.value !== null && f.value !== undefined),
    },
  };

  const {
    data,
    error,
    loading,
    refetch: refetchContentList,
  } = useQuery(FIND_ALL_LESSON_CONTENT, queryOptions);

  const [addContentMutate] = useFieldMutation(CREATE_LESSON_CONTENT);
  const [addContentFromTemplateMutate] = useFieldMutation(
    CREATE_LESSON_CONTENT_FROM_TEMPLATE,
  );

  const contentList = data?.findAllLessonContent?.content;
  const totalPages = data?.findAllLessonContent?.pages;
  const totalItems = data?.findAllLessonContent?.total;

  const addContent = (variables) => {
    let promise;

    if (!variables.templateIdToCopy) {
      promise = addContentMutate(variables);
    } else {
      promise = addContentFromTemplateMutate(variables);
    }

    return promise.then(({ id, type }) => {
      refetchContentList();

      navigate(`${URLS[contentType]}/${id}/edit`);

      addNotification({
        type: 'success',
        content: t(`content-overview.${type}.new-content.success`),
      });
    });
  };

  return (
    <ContentOverview
      addContent={addContent}
      contentList={contentList}
      contentType={contentType}
      createNewLesson={createNewLesson}
      currentPage={currentPage}
      error={error}
      loading={loading}
      refetchContentList={refetchContentList}
      setCurrentPage={setCurrentPage}
      setFilters={setFilters}
      totalItems={totalItems}
      totalPages={totalPages}
    />
  );
}
