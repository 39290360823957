export default function updateSlideSequences(slides) {
  let i = 1;

  const slideSequences = [];

  const updatedSlides = slides.map((slide) => {
    if (!slide) {
      return false;
    }

    slideSequences.push({ id: slide.id, sequence: i });

    const mappedSlide = {
      ...slide,
      sequence: i,
    };

    i += 1;

    return mappedSlide;
  });

  return [slideSequences, updatedSlides];
}
