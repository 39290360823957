import { useContext, Fragment } from 'react';
import { Button } from '@ftrprf/tailwind-components';
import { ChevronRightIcon } from '@heroicons/react/24/solid';
import useFormatMessage from 'hooks/useFormatMessage';
import { FileManagerContext } from '../FileManagerContextProvider';

export default function CurrentPath() {
  const t = useFormatMessage();
  const { activePath, navigateToFolder } = useContext(FileManagerContext);

  const paths = activePath?.split('/') || [];
  const folders = ['/', ...paths.filter((path) => path !== '')];

  return (
    <div className="flex gap-2 h-10 w-full border-b-2 p-2 sticky top-0 bg-white z-10">
      {folders.map((folder, i) => (
        <Fragment key={`${folder + i}`}>
          <Button
            className="hover:text-accent-500"
            onClick={() => {
              const parentPath = i > 0 ? paths.slice(0, i + 1).join('/') : '/';
              navigateToFolder(null, parentPath);
            }}
          >
            {i === 0 ? t('header.files') : folder}
          </Button>
          {folders.length > 1 && i !== folders.length - 1 && (
            <ChevronRightIcon className="w-3" />
          )}
        </Fragment>
      ))}
    </div>
  );
}
