import useFormatMessage from 'hooks/useFormatMessage';
import { useQuery } from '@apollo/client';
import { FIND_ALL_LESSON_CONTENT } from 'api/content';
import CONTENT_TYPES from 'utils/constants/contentTypes';
import { useEffect, useMemo } from 'react';
import {
  Badge,
  Dropdown,
  InputGroup,
  Label,
  RadioButton,
  Toggle,
} from '@ftrprf/tailwind-components';
import { Controller } from 'react-hook-form';

export function TemplateAndGroupFields({
  content,
  control,
  filterInput,
  isCreatingLesson,
  isGroupLesson,
  selectedTemplateId,
  setIsGroupLesson,
  setSelectedTemplateId,
}) {
  const t = useFormatMessage();

  const templateQueryOptions = {
    variables: {
      page: 0,
      filter: [
        { key: 'type', value: CONTENT_TYPES.LESSON_TEMPLATE, operation: ':' },
        { key: 'published', value: true, operation: ':' },
      ],
      sort: {
        isDefault: 'DESC',
        title: 'ASC',
      },
    },
  };

  const {
    data: templatesData,
    error: templatesError,
    loading: templatesLoading,
  } = useQuery(FIND_ALL_LESSON_CONTENT, templateQueryOptions);

  const templates = templatesData?.findAllLessonContent.content ?? [];
  const showTemplatesField = isCreatingLesson && templates?.length > 0;
  const currentTemplate =
    templates?.[templates?.findIndex((obj) => obj.id === selectedTemplateId)];
  const templateIsAGroupLesson = currentTemplate?.groupLesson;
  const templateIsDefault = currentTemplate?.isDefault;

  const groupRoleOptions = useMemo(() => {
    if (content?.groupRoles)
      return content.groupRoles.map((groupRole) => ({
        key: groupRole.id,
        value: groupRole.id,
        label: groupRole.role,
      }));

    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content?.groupRoles]);

  useEffect(() => {
    if (templatesData?.findAllLessonContent?.content?.length > 0) {
      setSelectedTemplateId(templatesData.findAllLessonContent.content[0].id);
    }
  }, [setSelectedTemplateId, templatesData]);

  return (
    <div>
      {showTemplatesField && (
        <InputGroup className="">
          <Label className="font-semibold">{t('content.template')}</Label>
          {!templatesLoading &&
            !templatesError &&
            templates.map((template) => (
              <label key={template.id} className="flex flex-row ">
                <RadioButton
                  checked={selectedTemplateId === template.id}
                  label={template.title}
                  name={`radio_${template.id}`}
                  onChange={(e) => {
                    setSelectedTemplateId(e.target.value);
                  }}
                  value={template.id}
                />
                <div className="my-1">
                  <div className="flex items-center">
                    <span className="mr-3 mb-0">{template.title}</span>

                    {template.isDefault && (
                      <Badge className="py-px text-xs px-2" success>
                        Default
                      </Badge>
                    )}
                  </div>
                  {template.summary && (
                    <div className="text-xs text-gray-600 ">
                      {template.summary}
                    </div>
                  )}
                </div>
              </label>
            ))}
        </InputGroup>
      )}
      <InputGroup>
        <Toggle
          disabled={!templateIsDefault}
          label={t('content.group_lesson')}
          onChange={(e) => {
            setIsGroupLesson(e);
          }}
          value={templateIsAGroupLesson || isGroupLesson}
          visibleLabel
        />
      </InputGroup>

      {isGroupLesson && (
        <>
          <Label className="mt-3" htmlFor="contentMetaDataDialog_groupRoles">
            {t('content.groupRoles')}
          </Label>
          <Controller
            control={control}
            name="groupRoles"
            render={({ field: { onBlur, onChange, value } }) => (
              <Dropdown
                creatable
                inputId="contentMetaDataDialog_groupRoles"
                isMulti
                onBlur={onBlur}
                onChange={onChange}
                onKeyDown={filterInput}
                options={groupRoleOptions}
                value={value}
              />
            )}
            rules={{
              required: {
                value: false,
                message: t('validation.required'),
              },
            }}
            type="select"
          />
        </>
      )}
    </div>
  );
}
