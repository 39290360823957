import { useState } from 'react';

export default (initialStack) => {
  const [stack, setStack] = useState(initialStack || []);

  const pop = () => {
    setStack((prevStack) => prevStack.slice(0, -1));
    return stack[stack.length - 1];
  };

  const push = (item) => {
    setStack((prevStack) => [...prevStack, item]);
  };

  const isEmpty = () => !!stack && !stack.length;

  return { pop, push, isEmpty };
};
