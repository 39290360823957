/**
 * Use this function to stop event bubbling.
 * Useful when there's another event handler higher up that you don't want to see triggered.
 * This ensures the original event handler for arrow up/down does continue normally.
 */
export function stopEventBubblingOnArrowUpOrDown(event) {
  const { key } = event;
  if (key === 'ArrowDown' || key === 'ArrowUp') {
    event.stopPropagation();
  }
}
