import {
  FaArrowDown,
  FaArrowUp,
  FaCopy,
  FaPlus,
  FaTrash,
} from 'react-icons/fa';

import { MenuButton } from '@ftrprf/tailwind-components';
import { ListContextMenu } from '../../../../components/ContextMenu/ListContextMenu';

import Divider from '../../../../components/Divider';

import useFormatMessage from '../../../../hooks/useFormatMessage';

export default function SlideContextMenu({
  children,
  disabled,
  onDelete,
  onDuplicate,
  onInsert,
  onMoveDown,
  onMoveUp,
}) {
  const t = useFormatMessage();

  return (
    <ListContextMenu trigger={children}>
      <MenuButton disabled={disabled} iconBefore={FaPlus} onClick={onInsert}>
        {t('content-editor.context_menu.insert_slide')}
      </MenuButton>

      <MenuButton disabled={disabled} iconBefore={FaCopy} onClick={onDuplicate}>
        {t('global.duplicate')}
      </MenuButton>
      <Divider />
      <MenuButton disabled={disabled} iconBefore={FaArrowUp} onClick={onMoveUp}>
        {t('content-editor.context_menu.move_up')}
      </MenuButton>
      <MenuButton
        disabled={disabled}
        iconBefore={FaArrowDown}
        onClick={onMoveDown}
      >
        {t('content-editor.context_menu.move_down')}
      </MenuButton>
      <Divider />
      <MenuButton disabled={disabled} iconBefore={FaTrash} onClick={onDelete}>
        {t('global.delete')}
      </MenuButton>
    </ListContextMenu>
  );
}
