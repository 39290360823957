import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import useCopyContentMutation from 'hooks/graphql/useCopyContentMutation';
import { Loader } from '@ftrprf/tailwind-components';
import useFormatMessage from '../hooks/useFormatMessage';

export default function CopyLesson() {
  const navigate = useNavigate();
  const { id } = useParams();
  const t = useFormatMessage();
  const searchParams = new URLSearchParams(window.location.search);
  const url = searchParams.get('url');
  const copy = useCopyContentMutation();

  useEffect(() => {
    copy(id).then((response) => {
      const baseUrl = `/lesson/${response.id}/edit`;

      if (response && url) {
        navigate(`${baseUrl}?url=${url}`);
        return;
      }

      navigate(baseUrl);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="mt-24 flex flex-col items-center">
      <Loader blue className="w-16" />
      <p className="mt-8">{t('global.copying')}</p>
    </div>
  );
}
