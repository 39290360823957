import { gql, useMutation } from '@apollo/client';

const getData = (data) => {
  if (!data) {
    return [];
  }

  return Object.values(data)[0];
};

export default function useInsertMutation(
  mutation,
  query,
  queryOptions,
  // eslint-disable-next-line default-param-last
  key = '',
  options,
) {
  const update =
    (variables) =>
    (cache, { data: response }) => {
      const resultValue = Object.values(
        cache.readQuery({ query, ...queryOptions }),
      )[0];

      const responseKey = Object.keys(response)[0];
      const typename = responseKey.replace('create', '');
      const { id } = getData(response);

      const resource = {
        id,
        ...variables,
      };

      if (!key) {
        throw new Error('Not yet supported');
      }

      cache.modify({
        id: cache.identify(resultValue),
        fields: {
          [key](existingResourcesRef = []) {
            const newResourceRef = cache.writeFragment({
              data: {
                ...resource,
                __typename: typename,
              },
              fragment: gql`
              fragment NewResource on ${typename} {
                ${Object.keys(resource).join(',')}
              }
            `,
            });

            return [...existingResourcesRef, newResourceRef];
          },
        },
      });
    };

  const [mutate, result] = useMutation(mutation, options);

  return [
    async (variables) => {
      let result;

      try {
        result = await mutate({
          variables,
          update: update(variables),
        }).then(({ data }) => (data ? Object.values(data)[0] : null));
      } catch (error) {
        console.log({ error });
      } // todo: fix root cause of apollo error when creating a lesson

      return result;
    },
    result,
  ];
}
