import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import useArchiveContentMutation from '../hooks/graphql/useArchiveContentMutation';
import useFormatMessage from '../hooks/useFormatMessage';

export function ArchiveLesson() {
  const navigate = useNavigate();
  const { id } = useParams();
  const t = useFormatMessage();

  const archive = useArchiveContentMutation();

  useEffect(() => {
    archive(id).then(() => {
      navigate('/lessons/');
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div>{t('global.archiving')}</div>;
}
