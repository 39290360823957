/**
 * Enum to determine the type of link.
 */
export const styledLinks = {
  DOWNLOAD: 'DownloadLink',
  VIDEO: 'VideoLink',
  EXTERNAL: 'ExternalLink',
  SLIDE: 'SlideLink',
  OTHER: 'OtherLink',
  DEFAULT: 'styledLink',
};
