import { Form, Formik } from 'formik';
import { object, string } from 'yup';

import {
  ActionStyledAsButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogHeader,
} from '@ftrprf/tailwind-components';

import FormikInput from '../../../components/Form/FormikInput';

import useFormatMessage from '../../../hooks/useFormatMessage';

export default function ExerciseCreateDialog({ isOpen, onDismiss, onSubmit }) {
  const t = useFormatMessage();

  const title = t('exercise-create-dialog.title');

  return (
    <Dialog aria-label={title} isOpen={isOpen} onDismiss={onDismiss}>
      <Formik
        initialValues={{
          titleDe: '',
          titleEn: '',
          titleEs: '',
          titleFr: '',
          titleNl: '',
        }}
        onSubmit={onSubmit}
        validateOnBlur={false}
        validationSchema={object({
          titleDe: string().required('validation.required'),
          titleEn: string().required('validation.required'),
          titleEs: string().required('validation.required'),
          titleFr: string().required('validation.required'),
          titleNl: string().required('validation.required'),
        })}
      >
        {({ isSubmitting, isValid }) => (
          <Form>
            <DialogHeader>{title}</DialogHeader>
            <DialogContent>
              <FormikInput
                label={t('exercise-create-dialog.label.title.dutch')}
                name="titleNl"
                type="text"
              />
              <FormikInput
                label={t('exercise-create-dialog.label.title.english')}
                name="titleEn"
                type="text"
              />
              <FormikInput
                label={t('exercise-create-dialog.label.title.french')}
                name="titleFr"
                type="text"
              />
              <FormikInput
                label={t('exercise-create-dialog.label.title.german')}
                name="titleDe"
                type="text"
              />
              <FormikInput
                label={t('exercise-create-dialog.label.title.spanish')}
                name="titleEs"
                type="text"
              />
            </DialogContent>
            <DialogActions>
              <ActionStyledAsButton onClick={onDismiss} variant="text">
                {t('global.cancel')}
              </ActionStyledAsButton>
              <ActionStyledAsButton
                disabled={isSubmitting || !isValid}
                type="submit"
              >
                {t('global.add')}
              </ActionStyledAsButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}
