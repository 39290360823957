import { gql } from 'graphql-request';

// more can be retreived but is currently not necessary
export const USER_INFO = gql`
  query userInfo {
    userInfo {
      language
      uiSettings
    }
  }
`;
