import { Dropdown, InputGroup, Label } from '@ftrprf/tailwind-components';
import { Controller } from 'react-hook-form';
import useFormatMessage from 'hooks/useFormatMessage';

export function EditorChoice({ control }) {
  const t = useFormatMessage();

  return (
    <InputGroup className="mt-3">
      <Label className="mt-3" htmlFor="contentMetaDialog_editorversion">
        {t('content-editor.editor_version')}
      </Label>
      <Controller
        control={control}
        defaultValue={5}
        name="editorVersion"
        render={({ field: { onBlur, onChange, value } }) => (
          <Dropdown
            inputId="contentMetaDialog_editorversion"
            onBlur={onBlur}
            onChange={onChange}
            options={[
              { value: 5, label: 'CKE5' },
              { value: 4, label: 'CKE4 (Legacy)' },
            ]}
            value={value}
          />
        )}
        rules={{
          required: {
            value: true,
            message: t('validation.required'),
          },
        }}
        type="select"
      />
    </InputGroup>
  );
}
