import { FIND_LESSON_CONTENT } from 'api/studioService/common/queries/findLessonContent';
import { useQuery } from 'react-query';
import { studioServiceQueryClient } from 'utils/graphqlQueryClient';

export default function useLessonContent(id) {
  const response = useQuery(['findLessonContent', id], () =>
    studioServiceQueryClient.request(FIND_LESSON_CONTENT, {
      id,
    }),
  );

  return {
    ...response,
    data: response.data,
  };
}
