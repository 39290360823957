import { createContext, useEffect, useMemo, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import * as Sentry from '@sentry/react';

import useuserInfo from 'hooks/api/plannerService/common/queries/useUserInfo';
import roles from 'utils/constants/roles';
import useOrganizationInfo from 'hooks/api/plannerService/common/queries/useOrganizationInfo';
import { VIEW_MODES } from '@ftrprf/tailwind-components';
import { getToken } from 'api';
import msalInstance from '../msalInstance';
import determineUserRoles from '../utils/functions/determineUserRoles';
import { determineOrgansationRoles } from '../utils/functions/determineOrganisationRoles';

export const UserContext = createContext({});

export default function UserProvider({ children }) {
  const [initialToken, setInitialToken] = useState();
  const { instance } = useMsal();

  const account = instance?.getActiveAccount();
  const user = account?.idTokenClaims;

  useEffect(() => {
    if (account) {
      getToken(msalInstance).then((token) => setInitialToken(token));
    }
  }, [account]);

  useEffect(() => {
    if (user) {
      Sentry.setUser(user);
    }
  }, [user]);

  const { data: userInfo } = useuserInfo(initialToken);
  const { data: organizationInfo } = useOrganizationInfo(
    account,
    account?.idTokenClaims.roles?.includes(roles.ADMIN) || !initialToken,
  );

  const context = useMemo(() => {
    const roleConditions = determineUserRoles(user);

    let parsedUiSettings = false;
    if (userInfo?.uiSettings) {
      parsedUiSettings = JSON.parse(
        String(
          userInfo.uiSettings
            // TODO: the replaces are temporary must be removed when all users have updated their uiSettings
            .replace('calendar=', '"calendar":')
            .replace('extendedHours=', '"extendedHours":')
            .replace('fullWeek=', '"fullWeek":')
            .replace('viewMode=', '"viewMode":'),
        ),
      );
    }

    const settings =
      userInfo &&
      (parsedUiSettings?.uiSettings || {
        calendar: {
          extendedHours: false,
          fullWeek: false,
        },
        slideViewer: {
          viewMode: VIEW_MODES.PROJECTION,
        },
      });

    const organisationRoleConditions =
      determineOrgansationRoles(organizationInfo);

    const organization = {
      ...organizationInfo,
      ...organisationRoleConditions,
    };

    return {
      initialToken,
      token: initialToken,
      ...user,
      ...roleConditions,
      id: user?.sub,
      email: user?.strongAuthenticationEmailAddress,
      userInfo,
      organization,
      settings,
      signedIn: Boolean(user) && Boolean(initialToken),
      avatar: userInfo?.avatar,
    };
  }, [user, initialToken, organizationInfo, userInfo]);

  return (
    <UserContext.Provider value={context}>{children}</UserContext.Provider>
  );
}
