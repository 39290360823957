import {
  SLIDEVIEWER_PREFIX,
  STYLED_BUTTON_CLASS,
} from '@ftrprf/tailwind-components';
import { styledLinks } from './styledLinks';

/**
 * Function to determine the classes for a link.
 *
 * @param {styledLinks} type
 * @returns {String} The classes for the link.
 */
export function determineClasses(type) {
  if (!Boolean(type)) {
    return styledLinks.DEFAULT;
  }

  const classes = [
    styledLinks.DEFAULT,
    STYLED_BUTTON_CLASS,
    `${SLIDEVIEWER_PREFIX}${type}`,
  ];

  return classes.join(' ');
}
