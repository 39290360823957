import { Helmet } from 'react-helmet';

/*
    To dynamically render the styles from the parent component,
    react-helmet is used. This package works similar as next/head
    which provides the functionality of dynamically adding tags to the
    head component in React. In this case, dynamic styles are rendered.
*/
export default function DynamicStyles({
  accentColor,
  dialogAccentColor,
  dropDownStyle,
  fontFamily,
  secondaryColor,
}) {
  const accentColorStyles = `
        #cke_1_bottom  {
            background-color: ${accentColor} !important;
        }
        
        .cke_button_icon {
            color: ${accentColor} !important;
        }

        .cke_button svg {
          fill: ${accentColor} !important;
        }
    `;

  const dropdownStyles = `
        .cke_combo_button  {
            border: ${dropDownStyle.border} ${dropDownStyle.borderColor} !important;
            border-radius: ${dropDownStyle.borderRadius};
            display: ${dropDownStyle.display} !important;
            overflow: ${dropDownStyle.overflow} !important;
        }

        .cke_panel {
          border: ${dropDownStyle.border} ${dropDownStyle.borderColor} !important;
          border-radius: ${dropDownStyle.borderRadius};
          overflow: ${dropDownStyle.overflow} !important;
        }
    `;

  const secondaryStyles = `
        .cke_top {
          border-bottom: 1px solid ${secondaryColor} !important;
          border-radius: 0px !important;
        }
  `;

  const borderRadiusStyles = `
        .cke_dialog_body {
          border-radius: 0.5rem !important;
        }

        .cke_dialog_contents {
          border-radius: 0.5rem !important;
        }

        .cke_resizer {
          display: none !important;
        }
  `;

  const fontFamilyStyles = `
        .cke_contents * {
          font-family: ${fontFamily} !important
        }
  `;

  const buttonStyles = `
  .cke_dialog_ui_button_cancel {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
    border-color: ${dialogAccentColor} !important;
    border-radius: 0.25rem !important;
    color: ${dialogAccentColor} !important;
  }
  
  .cke_dialog_ui_button_ok {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
    border-color: ${dialogAccentColor} !important;
    background-color: ${dialogAccentColor} !important;
    border-radius: 0.25rem !important;
    color: white !important;
  }
  `;

  return (
    <Helmet
      style={[
        {
          cssText: `
            ${accentColorStyles}
            ${dropdownStyles}
            ${secondaryStyles}
            ${borderRadiusStyles}
            ${fontFamilyStyles}
            ${buttonStyles}
          `,
        },
      ]}
    />
  );
}
