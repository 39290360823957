/* eslint-disable no-unused-vars */
import { useCallback, useState } from 'react';
import { RiVideoFill } from 'react-icons/ri';
import { Form, Formik } from 'formik';
import { object, string } from 'yup';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogHeader,
  Label,
  ActionStyledAsButton,
} from '@ftrprf/tailwind-components';

import { embedVideoFeaturePolicy } from 'utils/constants';
import { FILE_EXTENSIONS } from 'utils/constants/fileTypes';
import c from 'utils/functions/c';
import { ContextMenu } from '../ContextMenu/ContextMenu';
import useFormatMessage from '../../hooks/useFormatMessage';

import ContentListSelector from '../ContentListSelector/ContentListSelector';
import FormikUrl from '../Form/FormikUrl';
import StyledIcon from '../StyledIcon';
import FileUploadContainer from './FileUpload';

function Plugin({ editor, editorAPI }) {
  const t = useFormatMessage();

  const [isExternalShown, setIsExternalShown] = useState(false);
  const [currentPath, setCurrentPath] = useState('/');

  const insertVideoHtml = useCallback(
    (url) => {
      editorAPI.insertHtml(
        c(
          `<table style="border: solid 3px transparent; margin-left: auto; margin-right:auto; width:100%">`,
          `<tr>`,
          `<td style="border: solid 10px transparent;`,
          isExternalShown
            ? `position:relative; padding-top:56.25%; overflow:hidden;">`
            : `">`,
          isExternalShown
            ? `<iframe style="left: 0; top: 0; right:0; bottom:0; position: absolute" height="100%" width="100%" src="${url}" frameborder="0">`
            : `<video src="${url}" alt="" width="100%" controls controlsList="nodownload"/>`,
          `</td></tr></table><br/>`,
        ),
      );
    },
    [editorAPI, isExternalShown],
  );

  const getVideoURL = (url) => {
    if (url.includes('youtube')) {
      return `https://www.youtube.com/embed/${url.substring(
        url.lastIndexOf('=') + 1,
        url.length,
      )}?autohide=1&controls=1&showinfo=0`;
    }

    if (url.includes('youtu')) {
      return `https://www.youtube.com/embed/${url.substring(
        url.lastIndexOf('/') + 1,
        url.length,
      )}?autohide=1&controls=1&showinfo=0`;
    }

    if (url.includes('dailymotion')) {
      return `https://www.dailymotion.com/embed/video/${url.substring(
        url.lastIndexOf('/') + 1,
        url.lastIndexOf('?playlist') || url.length,
      )}`;
    }

    if (url.includes('vimeo')) {
      return `https://player.vimeo.com/video/${url.substring(
        url.lastIndexOf('/') + 1,
        url.length,
      )}`;
    }

    if (url.includes(process.env.REACT_APP_CODEFEVER_BLOB_DOMAIN)) {
      return url;
    }

    return null;
  };

  const menuTrigger = ({ _, toggle }) => (
    <StyledIcon className="text-base" icon={RiVideoFill} onClick={toggle} />
  );

  return (
    <>
      {isExternalShown && (
        <Dialog>
          <Formik
            initialValues={{
              url: '',
            }}
            onSubmit={({ url }, { setErrors }) => {
              const retrievedUrl = getVideoURL(url);

              if (!retrievedUrl) {
                setErrors({ url: 'validation.external_video_url' });
                return;
              }

              insertVideoHtml(retrievedUrl);
              setIsExternalShown(false);
            }}
            validateOnBlur={false}
            validateOnChange
            validationSchema={object({
              url: string().url('validation.url').required('validation.empty'),
            })}
          >
            {({ errors, isSubmitting, isValid, setFieldValue, values }) => (
              <Form>
                <DialogHeader>{t('editor.videoplugin.label')}</DialogHeader>
                <DialogContent>
                  <FormikUrl label={t('global.URL')} name="url" />
                  <Label>{t('editor.videoplugin.label-upload')}</Label>
                  {!errors.url && values.url ? (
                    <div className="w-full h-56">
                      <iframe
                        allow={embedVideoFeaturePolicy}
                        height="200"
                        src={getVideoURL(values.url)}
                        width="300"
                      />
                    </div>
                  ) : (
                    <FileUploadContainer
                      accept="video"
                      maxSize={5242880}
                      name="file"
                      path={currentPath}
                      setFileUri={(uri) => {
                        setFieldValue('url', uri);
                      }}
                    />
                  )}
                </DialogContent>
                <DialogActions>
                  <ActionStyledAsButton
                    onClick={() => {
                      setIsExternalShown(false);
                      setFieldValue('url', null);
                    }}
                    variant="text"
                  >
                    {t('global.cancel')}
                  </ActionStyledAsButton>
                  <ActionStyledAsButton
                    disabled={isSubmitting || !isValid}
                    type="submit"
                  >
                    {t('editor.videoplugin.label')}
                  </ActionStyledAsButton>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </Dialog>
      )}
      <ContextMenu editor={editor} trigger={menuTrigger}>
        {({ close }) => (
          <div className="bg-white h-64 w-64 border border-gray">
            <ContentListSelector
              allowedTypes={FILE_EXTENSIONS.VIDEO.extensions}
              onExternalClick={() => {
                setIsExternalShown(true);
                close();
              }}
              onFileClick={(url) => {
                insertVideoHtml(url);
                close();
              }}
              setCurrentPath={setCurrentPath}
            />
          </div>
        )}
      </ContextMenu>
    </>
  );
}

export function VideoPlugin({ ...props }) {
  const t = useFormatMessage();

  // suppressed as the plugin is initialized by ckeditor
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Plugin label={t('editor.videoplugin.label')} {...props} />;
}
