/* eslint-disable no-unused-vars */
import { useCallback, useState } from 'react';
import { FaVolumeUp } from 'react-icons/fa';
import { Form, Formik } from 'formik';
import { object, string } from 'yup';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogHeader,
  Label,
  ActionStyledAsButton,
} from '@ftrprf/tailwind-components';

import { FILE_EXTENSIONS } from 'utils/constants/fileTypes';
import { ContextMenu } from '../ContextMenu/ContextMenu';
import useFormatMessage from '../../hooks/useFormatMessage';

import ContentListSelector from '../ContentListSelector/ContentListSelector';
import FormikUrl from '../Form/FormikUrl';

import FileUpload from './FileUpload';

const audioUrlRegex = new RegExp(
  `(${FILE_EXTENSIONS.AUDIO.extensions.join('|')})`,
  'i',
);

function Plugin({ editor, editorAPI }) {
  const t = useFormatMessage();

  const [isExternalShown, setIsExternalShown] = useState(false);
  const [currentPath, setCurrentPath] = useState('/');

  const insertAudioHtml = useCallback(
    (url) => {
      editorAPI.insertHtml(
        `<br/>
        <div style="display: grid; width: 100%; place-items: center; ">
          <audio controls src="${url}" controlsList="nodownload"></audio>
        </div>
        <br/>`,
      );
    },
    [editorAPI],
  );

  const menuTrigger = ({ _, toggle }) => (
    <FaVolumeUp className="text-base" onClick={toggle} />
  );

  return (
    <>
      {isExternalShown && (
        <Dialog>
          <Formik
            initialValues={{
              url: '',
            }}
            onSubmit={({ url }) => {
              insertAudioHtml(url);
              setIsExternalShown(false);
            }}
            validateOnBlur={false}
            validateOnChange
            validationSchema={object({
              url: string()
                .url('validation.url')
                .required('validation.empty')
                .matches(audioUrlRegex, 'validation.external_audio_url'),
            })}
          >
            {({ errors, isValid, setFieldValue, values }) => (
              <Form>
                <DialogHeader>{t('editor.audioplugin.label')}</DialogHeader>
                <DialogContent>
                  <FormikUrl label={t('global.URL')} name="url" />
                  <div>
                    <Label>{t('editor.audioplugin.label-upload')}</Label>
                    {!errors.url && values.url ? (
                      <div className="w-full h-40 grid place-items-center">
                        <audio
                          alt="upload"
                          controls
                          controlsList="nodownload"
                          src={values.url}
                        />
                      </div>
                    ) : (
                      <FileUpload
                        accept="audio"
                        name="file"
                        path={currentPath}
                        setFileUri={(uri) => {
                          setFieldValue('url', uri);
                        }}
                      />
                    )}
                  </div>
                </DialogContent>
                <DialogActions>
                  <ActionStyledAsButton
                    onClick={() => {
                      setIsExternalShown(false);
                      setFieldValue('url', null);
                    }}
                    variant="text"
                  >
                    {t('global.cancel')}
                  </ActionStyledAsButton>
                  <ActionStyledAsButton disabled={!isValid} type="submit">
                    {t('editor.audioplugin.label')}
                  </ActionStyledAsButton>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </Dialog>
      )}
      <ContextMenu editor={editor} trigger={menuTrigger}>
        {({ close }) => (
          <div className="bg-white h-64 w-64 border border-gray">
            <ContentListSelector
              allowedTypes={FILE_EXTENSIONS.AUDIO.extensions}
              onExternalClick={() => {
                setIsExternalShown(true);
                close();
              }}
              onFileClick={(url) => {
                insertAudioHtml(url);
                close();
              }}
              setCurrentPath={setCurrentPath}
            />
          </div>
        )}
      </ContextMenu>
    </>
  );
}

export function AudioPlugin({ ...props }) {
  const t = useFormatMessage();

  // suppressed as the plugin is initialized by ckeditor
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Plugin label={t('editor.audioplugin.label')} {...props} />;
}
