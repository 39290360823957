import { useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { Editable } from '@ftrprf/tailwind-components';

import c from 'utils/functions/c';

import Editor from 'components/Editor/Editor';
import useFormatMessage from 'hooks/useFormatMessage';
import useSlide from '../../hooks/useSlide';

import { isDraggingAtom, isHoveredAtom } from '../../utils/atom';

import { Question } from '../Question/Question';
import { Ck5SlideEditorContainer } from '../Ck5Editor/Ck5SlideEditorContainer';
import { stopEventBubblingOnArrowUpOrDown } from '../../utils/stopEventBubblingOnArrowUpOrDown';

export function ContentEditorDetail({
  content,
  disabled,
  isCk5,
  onDuplicateSlide,
  setShowCreateNewVersionDialog,
  slideId,
}) {
  const t = useFormatMessage();
  const isDragging = useRecoilValue(isDraggingAtom);
  const [isHovered, setIsHovered] = useRecoilState(isHoveredAtom);

  const DEFAULT_SCALE = 1;
  const [scale, setScale] = useState(DEFAULT_SCALE);

  const { slide, update } = useSlide(slideId);

  const setValue = (value) => {
    update({ content: value });
  };

  if (!slide) {
    return null;
  }

  const slideContainsQuestions = slide.questions?.length > 0;

  return (
    // given that this is just to stop an event bubbling this warning makes no sense
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className="flex relative flex-col flex-grow bg-gray-50 overflow-y-none"
      onKeyDown={stopEventBubblingOnArrowUpOrDown}
      tabIndex={-1}
    >
      <div className="h-16 px-4 flex items-center border-b border-gray-300 flex-shrink-0">
        <Editable
          className={c(
            'text-bold text-xl p-1 rounded',
            !slide.title && 'text-gray-500 cursor-default',
          )}
          contentEditable={!disabled}
          defaultValue={t('content-editor.title.placeholder')}
          maxChars={99}
          onBlur={(title) => {
            update({ title });
          }}
          singleLine
          test="slideTitle"
        >
          {slide.title || t('content-editor.title.placeholder')}
        </Editable>
      </div>
      <div className="bg-white flex-grow flex flex-col overflow-y-hidden">
        {/* given there is a new version button, there is no problem with having something else there for those who click on the div */}
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
        <div
          className={c(
            'flex flex-col flex-grow',
            slideContainsQuestions && 'h-112 flex-shrink-0',
          )}
          onClick={
            content.published && !content.archived
              ? () => setShowCreateNewVersionDialog(true)
              : undefined
          }
        >
          {isCk5 ? (
            <Ck5SlideEditorContainer
              background={slide.background}
              disabled={disabled}
              id={slideId}
              isInSideBar={false}
              onChange={setValue}
              scale={scale}
              setScale={setScale}
              slide={slide}
              value={slide.content}
            />
          ) : (
            <Editor
              className="Editor__Main pb-3"
              disabled={disabled}
              id={slideId}
              isMainEditor
              onChange={setValue}
              value={slide.content}
              withBorder={false}
            />
          )}
        </div>
        {slideContainsQuestions &&
          slide.questions.map((question) => (
            <Question
              key={question.id}
              disabled={disabled}
              onDuplicateSlide={() => onDuplicateSlide(slide)}
              question={question}
            />
          ))}
      </div>

      <div
        className={c(
          'h-5/6 w-full bottom-0 absolute bg-transparent z-100',
          isHovered && 'border-blue-400',
          isDragging ? 'visible' : 'invisible',
        )}
        onMouseEnter={() => {
          if (isDragging) {
            setIsHovered(true);
          }
        }}
        onMouseLeave={() => {
          if (isDragging) {
            setIsHovered(false);
          }
        }}
      />
    </div>
  );
}
