import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { EXERCISE } from 'utils/constants/urls';
import { FIND_SCRATCH_EXERCISE } from '../../api/exercise';

const STATUS = {
  LOADING: 'LOADING',
  ERROR: 'ERROR',
};

// Right now only fetch one version of a Scratch Exercise
export default function useExerciseQuery(id, defaultVersionId) {
  const [blob, setBlob] = useState();
  const [titleEn, setTitleEn] = useState('');
  const [titleNl, setTitleNl] = useState('');
  const [titleFr, setTitleFr] = useState('');
  const [titleDe, setTitleDe] = useState('');
  const [titleEs, setTitleEs] = useState('');
  const [versionId, setVersionId] = useState(defaultVersionId);
  const [testPlanId, setTestPlanId] = useState();
  const [status, setStatus] = useState();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { data, error, loading, refetch } = useQuery(FIND_SCRATCH_EXERCISE, {
    variables: {
      id,
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    setStatus(STATUS.LOADING);

    if (!loading) {
      setTitleNl(data?.findExercise.titleNl);
      setTitleEn(data?.findExercise.titleEn);
      setTitleFr(data?.findExercise.titleFr);
      setTitleDe(data?.findExercise.titleDe);
      setTitleEs(data?.findExercise.titleEs);

      const defaultVersion =
        data?.findExercise.versions.find(
          (version) => version.id === Number(defaultVersionId),
        ) || data?.findExercise.versions[0];

      if (!defaultVersion) {
        return;
      }

      const { blobUri, id: versionId } = defaultVersion;
      if (pathname !== `${EXERCISE}/${id}/version/${versionId}`) {
        navigate(`${EXERCISE}/${id}/version/${versionId}`, {
          replace: true,
        });
      }

      fetch(blobUri)
        .then((response) => {
          if (response.status === 403) {
            refetch();
          } else {
            response.arrayBuffer().then((buffer) => {
              setVersionId(versionId);
              setTestPlanId(
                data.findExercise.versions.find((v) => v.id === versionId)
                  .testPlanId,
              );
              setBlob(new Blob([buffer]));
              setStatus();
            });
          }
        })
        .catch(() => setStatus(STATUS.ERROR));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    loading,
    data,
    setBlob,
    setStatus,
    defaultVersionId,
    refetch,
    navigate,
    id,
  ]);

  return {
    loading: loading || status === STATUS.LOADING,
    error: error || status === STATUS.ERROR,
    setBlob,
    setTestPlanId,
    exercise: {
      ...(data?.findExercise || {}),
      blob,
      titleEn,
      titleNl,
      titleFr,
      titleDe,
      titleEs,
      versionId,
      testPlanId,
      setTitleEn,
      setTitleNl,
      setTitleFr,
      setTitleDe,
      setTitleEs,
      setVersionId,
      refetch,
    },
  };
}
