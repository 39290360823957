import c from '../../../../utils/functions/c';

import SideBarGoBack from './SideBarGoBack';

export default function SideBarContent({
  children,
  className,
  dataTest,
  goBackFunction,
  grow = false,
  hFull = false,
  title,
  withGoBack = true,
}) {
  return (
    <div
      className={c(className, 'flex flex-col flex-start flex-grow')}
      data-test={dataTest}
    >
      {withGoBack && (
        <SideBarGoBack goBackFunction={goBackFunction} title={title} />
      )}
      <div
        className={c(
          'flex flex-col flex-start',
          grow && 'grow',
          hFull && 'h-full',
        )}
      >
        {children}
      </div>
    </div>
  );
}
