import { Link, useNavigate } from 'react-router-dom';

import { ReactComponent as EmptyImage } from 'assets/vectors/empty-image.svg';
import { ContentCard } from 'pages/ContentOverview/partials/ContentCard/ContentCard';

import ContentCardContextMenu from './ContentCardContextMenu';

export default function ExerciseContentCard({
  duplicateLoading,
  item,
  onDelete,
  onDuplicate,
}) {
  const navigate = useNavigate();

  const editURL = `/exercise/${item.id}`;

  return (
    <ContentCardContextMenu
      duplicateLoading={duplicateLoading}
      editURL={editURL}
      onDelete={() => onDelete(item.id)}
      onDuplicate={() => onDuplicate(item.id)}
    >
      {({ toggle }) => (
        <Link target="_blank" to={editURL}>
          {' '}
          <ContentCard
            key={item.id}
            defaultImage={EmptyImage}
            image={item.image}
            onClick={() => navigate(editURL)}
            title={item.title}
            toggleContextMenu={toggle}
          />
        </Link>
      )}
    </ContentCardContextMenu>
  );
}
