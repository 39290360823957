import { useEffect, useState } from 'react';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogHeader,
  ActionStyledAsButton,
} from '@ftrprf/tailwind-components';

import useFormatMessage from '../../hooks/useFormatMessage';

export default function WarningPublishedEditDialog({
  isOpen,
  onDismiss,
  onDuplicate,
  onSmallChange,
}) {
  const t = useFormatMessage();

  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setDisabled(false);
    }
  }, [isOpen]);

  return (
    <Dialog isOpen={isOpen} onDismiss={onDismiss}>
      <DialogHeader>
        {t('question-answer-multiple-choice-sidebar.warning.title')}
      </DialogHeader>
      <DialogContent>
        {t('question-answer-multiple-choice-sidebar.warning.content')}
      </DialogContent>
      <DialogActions>
        <ActionStyledAsButton
          disabled={disabled}
          onClick={onDismiss}
          variant="text"
        >
          {t('global.cancel')}
        </ActionStyledAsButton>
        <ActionStyledAsButton
          disabled={disabled}
          onClick={() => {
            setDisabled(true);
            onSmallChange();
          }}
        >
          {t('question-answer-multiple-choice-sidebar.warning.small')}
        </ActionStyledAsButton>
        <ActionStyledAsButton
          disabled={disabled}
          onClick={() => {
            setDisabled(true);
            onDuplicate();
          }}
        >
          {t('question-answer-multiple-choice-sidebar.warning.duplicate')}
        </ActionStyledAsButton>
      </DialogActions>
    </Dialog>
  );
}
