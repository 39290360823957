export function getRidOfNbspOverloadInLink(htmlString) {
  const endOfOpeningTagIndex = htmlString.indexOf('>');
  const closingTagIndex = htmlString.indexOf('</a>');
  const contentBeforeModification = htmlString.substring(
    endOfOpeningTagIndex,
    closingTagIndex,
  );
  const contentAfterModification = contentBeforeModification
    .slice()
    .replaceAll('&nbsp;', '')
    .replaceAll('&nbsp; ', '')
    .trim();

  return htmlString.replace(
    contentBeforeModification,
    contentAfterModification,
  );
}
