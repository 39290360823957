import { Children, cloneElement, isValidElement } from 'react';
import { createPortal } from 'react-dom';

import c from './utils/c';

export default function CustomButtons({ buttonLocation, children, editorAPI }) {
  if (buttonLocation) {
    return createPortal(
      Children.map(children, (child) => {
        if (!isValidElement(child)) {
          return null;
        }

        const { label, pluginName } = child.props;

        return (
          <button
            className={c(
              pluginName !== 'LinkPlugin' &&
                'cke_button cke_button__bgcolor cke_button_expandable cke_button_off',
            )}
            tabIndex="-1"
            title={label}
            type="button"
          >
            {cloneElement(child, {
              className: 'cke_button_icon',
              editorAPI,
            })}
          </button>
        );
      }),
      buttonLocation,
    );
  }
  return null;
}
