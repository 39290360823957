import { useContext } from 'react';
import { useMutation } from '@apollo/client';

import { NotificationContext } from '@ftrprf/tailwind-components';

import { CREATE_EXERCISE_TEST_PLAN } from '../../api/exercise';

import useFormatMessage from '../useFormatMessage';

export default function useExerciseTestPlanMutation() {
  const { addNotification } = useContext(NotificationContext);
  const t = useFormatMessage();

  const [createMutation, { error, loading }] = useMutation(
    CREATE_EXERCISE_TEST_PLAN,
    {},
  );

  const createExerciseTestPlan = ({
    exerciseVersionId,
    file,
    name,
    setTestPlanId,
  }) =>
    createMutation({ variables: { exerciseVersionId, name, file } })
      .then((data) => {
        setTestPlanId(data.data.createExerciseTestPlan.id);
        addNotification({
          type: 'success',
          content: t('scratch.upload-test-plan.success'),
        });
      })
      .catch(() =>
        addNotification({
          type: 'error',
          content: t('scratch.upload-test-plan.error'),
        }),
      );

  return {
    createExerciseTestPlan,
    createExerciseTestPlanLoading: loading,
    createExerciseTestPlanError: error,
  };
}
