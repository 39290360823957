import useFormatMessage from 'hooks/useFormatMessage';
import ErrorPage from 'pages/ErrorPage';
import { UserContext } from 'providers/UserProvider';
import { useContext, useEffect } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { IMPERSONATE_ATTEMPTS } from 'utils/constants/localStorage';
import { ROOT, IMPERSONATE } from 'utils/constants/urls';
import { Loader } from '@ftrprf/tailwind-components';

export default function Impersonate() {
  const navigate = useNavigate();
  const t = useFormatMessage();
  const { isAdmin, isImpersonating } = useContext(UserContext);

  const currentAttempts =
    parseInt(localStorage.getItem(IMPERSONATE_ATTEMPTS), 10) || 0;

  const canImpersonate = isAdmin;

  const isAttempting =
    canImpersonate && !isImpersonating && currentAttempts < 10;

  useEffect(() => {
    if (isAttempting) {
      const intervalId = setInterval(() => {
        localStorage.clear();
        localStorage.setItem(IMPERSONATE_ATTEMPTS, `${currentAttempts + 1}`);

        // Navigate to the same URL to refresh the page
        window.location = `${process.env.REACT_APP_AD_REDIRECT_LINK}${IMPERSONATE}`;
      }, 5000);

      return () => {
        // Clean up the interval when the component is unmounted
        clearInterval(intervalId);
      };
    }

    return () => {};
  }, [canImpersonate, currentAttempts, isAttempting, isImpersonating]);

  useEffect(() => {
    if (isImpersonating) {
      localStorage.setItem(IMPERSONATE_ATTEMPTS, undefined);

      // navigate to homepage when logged in as impersonated user
      navigate(generatePath(ROOT), { replace: true });
    }
  }, [isImpersonating, navigate]);

  if (canImpersonate) {
    return (
      <div className="w-full h-full flex justify-center items-center flex-col gap-6">
        <div className="max-w-lg">
          <h1 className="text-center px-3 text-2xl font-medium text-gray-900 mb-4">
            {isAttempting
              ? t('impersonate.header')
              : t('impersonate.header.failed')}
          </h1>
        </div>
        {isAttempting ? (
          <div className="overflow-hidden max-w-1/8">
            <Loader />
          </div>
        ) : (
          <p>{t('impersonate.description')}</p>
        )}
      </div>
    );
  }

  return <ErrorPage />;
}
