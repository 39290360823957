/* eslint consistent-return: 0 */
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import {
  ConfirmationDialog,
  NotificationContext,
} from '@ftrprf/tailwind-components';

import { COPY, CUT } from '../pages/ContentManager/utils/constants/clipboard';

import useContentMutation from '../hooks/graphql/useContentMutation';
import useFieldMutation from '../hooks/graphql/useFieldMutation';
import useCheckUploadFiles from '../hooks/useCheckUploadFiles';
import useFormatMessage from '../hooks/useFormatMessage';
import useMultiSelect from '../hooks/useMultiSelect';
import usePathStack from '../hooks/usePathStack';

import { UPDATE_CONTENT_FILE } from '../api/files';

export const ContentActionContext = createContext({});

export default function ContentActionProvider({
  accept,
  children,
  fetchAll = () => {},
  files = [],
  maxSize,
  path,
  root = '/',
}) {
  const t = useFormatMessage();

  const { addNotification } = useContext(NotificationContext);
  const { relativePath } = usePathStack(root);
  let modifiedPath = path;

  if (!path) modifiedPath = relativePath;

  const [selectedItems, selectFile, addToSelection, deselectFile, deselectAll] =
    useMultiSelect();

  const [clipBoard, setClipBoard] = useState([]);
  const [isUploading, setIsUploading] = useState(false);

  const [updateFileMutate] = useFieldMutation(
    UPDATE_CONTENT_FILE,
    'ContentFile',
  );

  const { createFolder, deleteFiles, moveFiles, renameFile, uploadFiles } =
    useContentMutation({ modifiedPath, fetchAll });

  const {
    checkFiles,
    isOverwriteDialogOpen,
    onOverwriteConfirm,
    onOverwriteDismiss,
  } = useCheckUploadFiles({ accept, maxSize, modifiedPath });

  const onUploadFiles = useCallback(
    () => (files) => {
      setIsUploading(true);

      return new Promise((resolve, reject) => {
        checkFiles(files)
          .then((result) => {
            const results = result.reduce(
              (prev, current) => ({
                files: [...prev.files, current.file],
                names: [...prev.names, current.name],
              }),
              { files: [], names: [] },
            );

            uploadFiles(results)
              .then((data) => {
                setIsUploading(false);
                resolve(data);
              })
              .catch((error) => {
                setIsUploading(false);
                reject(error);
              });
          })
          .catch((error) => {
            setIsUploading(false);
            reject(error);
          });
      });
    },
    [checkFiles, uploadFiles],
  );

  const copyFiles = (fileIds) => {
    // TODO: FINISH - COPY FUNCTIONALITY NOT ADDED TO BE YET
    setClipBoard(fileIds.map((fileId) => ({ [fileId]: COPY })));
  };

  const cutFiles = (files) => {
    setClipBoard(
      files.map(({ id, modifiedPath }) => ({
        [id]: { type: CUT, modifiedPath },
      })),
    );
  };

  const pasteFiles = useCallback(
    () => (destination) => {
      Promise.all(
        // eslint-disable-next-line array-callback-return
        clipBoard.map((file) => {
          const [id] = Object.keys(file);
          if (file[id].type === CUT) {
            return updateFileMutate({
              id,
              path: `${destination === '/' ? '' : destination}${file[
                id
              ].modifiedPath.substring(
                file[id].modifiedPath.lastIndexOf('/'),
              )}`,
            });
          }
          if (file[id].type === COPY) {
            // TODO: FINISH - COPY FUNCTIONALITY NOT ADDED IN BE
            return onUploadFiles(files.filter((file) => file.id === id));
          }
        }),
      ).then(() => {
        fetchAll();
        deselectAll();
        setClipBoard([]);
        addNotification({
          type: 'success',
          content: t('content-manager.actions.paste-file.success'),
        });
      });
    },
    [
      addNotification,
      clipBoard,
      deselectAll,
      fetchAll,
      files,
      onUploadFiles,
      t,
      updateFileMutate,
    ],
  );

  const copyFilesTo = () => {
    // TODO: FINISH - COPY FILE FUNCTIONALITY NOT ADDED IN BE
  };

  const context = useMemo(
    () => ({
      selectedItems: Object.keys(selectedItems),
      clipBoardCopy: clipBoard
        .filter((file) => file[Object.keys(file)[0]] === COPY)
        .flatMap((file) => Object.keys(file)),
      clipBoardCut: clipBoard
        .filter((file) => file[Object.keys(file)[0]].type === CUT)
        .flatMap((file) => Object.keys(file)),
      notInPathRegex:
        files
          ?.map(
            ({ path }) => `(^${path.substring(path.lastIndexOf('/') + 1)}$)`,
          )
          .join('|') || null,
      selectFile,
      deselectFile,
      deselectAll,
      addToSelection,
      path,
      isUploading,
      cutFiles,
      pasteFiles,
      renameFile,
      copyFiles,
      deleteFiles,
      copyFilesTo,
      moveFiles,
      createFolder,
      uploadFiles: onUploadFiles,
    }),
    [
      addToSelection,
      clipBoard,
      createFolder,
      deleteFiles,
      deselectAll,
      deselectFile,
      files,
      isUploading,
      moveFiles,
      onUploadFiles,
      pasteFiles,
      path,
      renameFile,
      selectFile,
      selectedItems,
    ],
  );

  return (
    <ContentActionContext.Provider value={context}>
      <ConfirmationDialog
        content={t('content-manager.actions.rename.path-exists')}
        isOpen={isOverwriteDialogOpen}
        onConfirm={onOverwriteConfirm}
        onDismiss={onOverwriteDismiss}
      />
      {children}
    </ContentActionContext.Provider>
  );
}
