import {
  Dialog,
  DialogActions,
  DialogHeader,
  ActionStyledAsButton,
  SaveButton,
} from '@ftrprf/tailwind-components';
import CodeMirror from '@uiw/react-codemirror';
import { html } from '@codemirror/lang-html';
import { useState } from 'react';
import useFormatMessage from 'hooks/useFormatMessage';

export function SourcePlugin({ editorContext, onDismiss }) {
  const t = useFormatMessage();
  const { editor, onChange, value: slideValue } = editorContext;
  const [value, setValue] = useState(
    // Add a new line after every html closing tag
    slideValue?.replace(/(<\/\w+>)/gm, '$1\n'),
  );

  return (
    <Dialog className="w-3/4" onDismiss={onDismiss}>
      <DialogHeader>{t('editor.source-plugin.title')}</DialogHeader>
      <CodeMirror
        extensions={[html({})]}
        height="100%"
        onChange={setValue}
        value={value || ''}
        width="100%"
      />
      <DialogActions>
        <ActionStyledAsButton onClick={onDismiss} variant="text">
          {t('global.cancel')}
        </ActionStyledAsButton>
        <SaveButton
          onClick={() => {
            onChange(value);
            editor.setData(value);
            onDismiss();
          }}
        />
      </DialogActions>
    </Dialog>
  );
}
