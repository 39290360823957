import { FTRPRF, SMARTSCHOOL, TEAMS } from 'utils/constants/idp';
import roles from 'utils/constants/roles';

export const STUDENT = 'STUDENT';
export const TEACHER = 'TEACHER';
export const SCHOOLADMIN = 'SCHOOLADMIN';
export const ADMIN = 'ADMIN';

/**
 * You can use this function to determine the roles of the user.
 * Add additional rolesConditions to this file.
 *
 * @param {userObject} user
 * @returns determined user roles based on user object
 */
export default function determineUserRoles(user) {
  const roleConditions = {
    ...user,

    hasRoles: false,

    isAdmin: false,
    isBetaOrganization: false,
    isFtrprfUser: false,
    isImpersonating: false,
    isSchoolAdmin: false,
    isSmartschoolUser: false,
    isStudent: false,
    isTeacher: false,
    isTeamsUser: false,

    role: null,
  };

  if (!user) {
    return roleConditions;
  }

  user.roles?.forEach((role) => {
    switch (role) {
      case roles.SCHOOLSTUDENT:
        roleConditions.role = STUDENT;
        roleConditions.isStudent = true;
        break;
      case roles.SCHOOLTEACHER:
        roleConditions.role = TEACHER;
        roleConditions.isTeacher = true;
        break;
      case roles.SCHOOLADMIN:
        roleConditions.role = SCHOOLADMIN;
        roleConditions.isSchoolAdmin = true;
        break;
      case roles.ADMIN:
        roleConditions.role = ADMIN;
        roleConditions.isAdmin = true;
        break;
      default:
        break;
    }
  });
  roleConditions.hasRoles = Boolean(roleConditions.role);

  const userIdp = user.idp?.toUpperCase();
  if (userIdp) {
    switch (userIdp) {
      case FTRPRF:
        roleConditions.isFtrprfUser = true;
        break;
      case SMARTSCHOOL:
        roleConditions.isSmartschoolUser = true;
        break;
      case TEAMS:
        roleConditions.isTeamsUser = true;
        break;
      default:
        break;
    }
  }

  roleConditions.isBetaOrganization = Boolean(user.organization?.beta);
  roleConditions.isImpersonating = Boolean(user.impersonated_objectId);

  return roleConditions;
}
