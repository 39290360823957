export function modalHtml({ buttonText, modalContent, modalSize, modalTitle }) {
  const quotsMap = {
    '"': '&quot;',
    "'": '&apos;',
    '`': '&#96;',
  };

  const replacer = (m) => quotsMap[m];
  const trimmedButtonText = buttonText
    .slice()
    .replaceAll('&nbsp;', '')
    .replaceAll('&nbsp; ', '')
    .trim();

  return `&nbsp;&nbsp;<a data-label="${buttonText.replace(/["'`]/g, replacer)}" 
      data-modal-title="${modalTitle.replace(/["'`]/g, replacer)}" 
      data-modal-size="${modalSize}"
      data-modal-content="${modalContent.replace(/["'`]/g, replacer)}" 
      class="SlideViewer__StyledButton SlideViewer__ModalLink"
    >
      ${trimmedButtonText}
    </a>&nbsp;`;
}
