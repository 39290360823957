import { useState } from 'react';
import { FaFilter, FaPlus } from 'react-icons/fa';

import { ActionStyledAsButton } from '@ftrprf/tailwind-components';

import PageActions from '../../../components/PageActions';

import useFormatMessage from '../../../hooks/useFormatMessage';

import ExerciseOverviewFilterBar from './ExerciseOverviewFilterBar';

export default function ExerciseOverviewHeader({
  onNewExerciseClick,
  setFilters,
}) {
  const t = useFormatMessage();

  const [isShowingFilter, setIsShowingFilter] = useState(false);

  return (
    <>
      <PageActions>
        <ActionStyledAsButton iconBefore={FaPlus} onClick={onNewExerciseClick}>
          {t(`exercise-overview.new_exercise`)}
        </ActionStyledAsButton>
        {!isShowingFilter ? (
          <ActionStyledAsButton
            iconBefore={FaFilter}
            onClick={() => setIsShowingFilter((s) => !s)}
            secondary
          >
            {t('content-overview.toggle_filter')}
          </ActionStyledAsButton>
        ) : (
          <ActionStyledAsButton
            iconBefore={FaFilter}
            onClick={() => setIsShowingFilter((s) => !s)}
            secondary
          >
            {t('content-overview.toggle_filter')}
          </ActionStyledAsButton>
        )}
      </PageActions>

      {isShowingFilter && (
        <div className="mt-6">
          <ExerciseOverviewFilterBar onChange={setFilters} />
        </div>
      )}
    </>
  );
}
