export const FTRPRF = 'FTRPRF';
export const TEAMS = 'TEAMS';
export const ILEARN = 'ILEARN';
export const SMARTSCHOOL = 'SMARTSCHOOL';
export const CODECOSMOS = 'CODECOSMOS';
export const CODEFEVER = 'CODEFEVER';

export const IDPS = {
  FTRPRF,
  TEAMS,
  ILEARN,
  SMARTSCHOOL,
  CODECOSMOS,
  CODEFEVER,
};
