import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';

import {
  CheckBox,
  Dropdown,
  Input,
  InputGroup,
  Label,
} from '@ftrprf/tailwind-components';

import useFormatMessage from '../../../../hooks/useFormatMessage';
import useSlide from '../../hooks/useSlide';

import { lessonContentAtom } from '../../utils/atom';

const subTitleStyle = 'opacity-70 text-sm py-2';
const subsubTitleStyle = 'opacity-50 text-xs py-1';

export default function SteamsSideBar({ dataTest, disabled, slideId }) {
  const t = useFormatMessage();

  const [currentLessonContent] = useRecoilState(lessonContentAtom);
  const groupRoleKeyToId = currentLessonContent?.groupRoles?.reduce(
    (prev, groupRole) => {
      const { id, role } = groupRole;
      return { ...prev, [role]: id };
    },
    {},
  );

  const { slide, update } = useSlide(slideId);
  const [selectedRoles, setSelectedRoles] = useState(
    slide.groupRoles?.map((gr) => gr.role) ?? [],
  );
  const [motivation, setMotivation] = useState(slide.motivation ?? null);
  const [rolesCheckboxChecked, setRolesCheckbox] = useState(
    slide.groupRoles !== null && slide.groupRoles.length > 0,
  );

  useEffect(() => {
    setSelectedRoles(slide.groupRoles?.map((gr) => gr.role) ?? []);
    setRolesCheckbox(slide.groupRoles !== null && slide.groupRoles.length > 0);
    setMotivation(slide.motivation ?? null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slide.id]);

  const updateRoles = (value, checked) => {
    update({
      groupRoles: !checked
        ? []
        : value.map((key) => ({
            id: groupRoleKeyToId[key],
            lessonId: currentLessonContent.id,
            role: key,
          })),
    });
  };
  const updateMotivation = (value) => {
    update({ motivation: value || null });
  };

  return (
    <div className="flex flex-col" data-test={dataTest}>
      <div className="">
        <Label className={subTitleStyle}>
          {t('slide-sidebar-steams.visibility')}
        </Label>
        <div className="flex flex-row flex-grow">
          <CheckBox
            checked={rolesCheckboxChecked}
            className="mr-2 "
            disabled={disabled}
            onChange={(c) => {
              updateRoles(selectedRoles, c);
              setRolesCheckbox(c);
            }}
          />
          <Label
            className={`text-sm ${rolesCheckboxChecked ? '' : 'opacity-50'}`}
          >
            {t('slide-sidebar-steams.visibility_text')}
          </Label>
        </div>
        <div className={rolesCheckboxChecked ? '' : 'opacity-50'}>
          <Label
            className={subsubTitleStyle}
            htmlFor="steamsSideBar_groupRoleKey"
          >
            {t('slide-sidebar-steams.visibility_choose')}
          </Label>
          <InputGroup>
            <Dropdown
              disabled={disabled || !rolesCheckboxChecked}
              inputId="steamsSideBar_groupRoleKey"
              isMulti
              onChange={(newval) => {
                updateRoles(newval, rolesCheckboxChecked);
                setSelectedRoles(newval);
              }}
              options={groupRoleKeyToId ? Object.keys(groupRoleKeyToId) : []}
              value={selectedRoles}
            />
          </InputGroup>
        </div>
        <Label className={subsubTitleStyle}>
          {t('slide-sidebar-steams.motivation')}
        </Label>
        <InputGroup>
          <Input
            disabled={disabled}
            onChange={(e) => {
              updateMotivation(e.target.value);
              setMotivation(e.target.value);
            }}
            placeholder={t('slide-sidebar-steams.motivation.placeholder')}
            value={motivation || ''}
          />
        </InputGroup>
      </div>
    </div>
  );
}
