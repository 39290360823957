import { useContext } from 'react';
import { useMutation } from '@apollo/client';

import { NotificationContext } from '@ftrprf/tailwind-components';

import useFormatMessage from 'hooks/useFormatMessage';

import { RENAME_CONTENT_FILE } from 'api/filemanager';

export default function useContentRename() {
  const t = useFormatMessage();
  const { addNotification } = useContext(NotificationContext);

  const [renameContent, { error, loading }] = useMutation(RENAME_CONTENT_FILE, {
    onCompleted: () => {
      addNotification({
        type: 'success',
        content: t('file.manager.content-renamed'),
      });
    },
  });

  return { renameContent, loading, error };
}
