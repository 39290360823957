const colors = [
  {
    color: 'hsl(0, 0%, 0%)',
    label: 'Black',
  },
  {
    color: 'hsl(0, 0%, 30%)',
    label: 'Dim grey',
  },
  {
    color: 'hsl(0, 0%, 60%)',
    label: 'Grey',
  },
  {
    color: 'hsl(0, 0%, 90%)',
    label: 'Light grey',
  },
  {
    color: 'hsl(0, 0%, 100%)',
    label: 'White',
    hasBorder: true,
  },
  {
    color: 'rgb(76, 151, 255)',
    label: 'Scratch Motion Blue',
  },
  {
    color: 'rgb(153, 102, 255)',
    label: 'Scratch Looks Purple',
  },
  {
    color: 'rgb(207, 99, 207)',
    label: 'Scratch Sound Fucia',
  },
  {
    color: 'rgb(255, 191, 0)',
    label: 'Scratch Events Yellow',
  },
  {
    color: 'rgb(255, 171, 25)',
    label: 'Scratch Control Orange',
  },
  {
    color: 'rgb(92, 177, 214)',
    label: 'Scratch Sensing Light Blue',
  },
  {
    color: 'rgb(89, 192, 89)',
    label: 'Scratch Operators Green',
  },
  {
    color: 'rgb(255, 140, 26)',
    label: 'Scratch Variables Orange',
  },
  {
    color: 'rgb(255, 102, 128)',
    label: 'Scratch My Blocks Red',
  },
  {
    color: 'rgb(232, 144, 5)',
    label: 'Minecraft Orange',
  },
  {
    color: 'rgb(0, 120, 215)',
    label: 'Minecraft Blue',
  },
  {
    color: 'rgb(216, 59, 1)',
    label: 'Minecraft Reed',
  },
  {
    color: 'rgb(91, 153, 165)',
    label: 'Minecraft Grey Blue',
  },
  {
    color: 'rgb(122, 187, 85)',
    label: 'Minecraft Light Green',
  },
  {
    color: 'rgb(118, 75, 204)',
    label: 'Minecraft Purple',
  },
  {
    color: 'rgb(143, 109, 64)',
    label: 'Minecraft Brown',
  },
  {
    color: 'rgb(105, 176, 144)',
    label: 'Minecraft Teal',
  },
  {
    color: 'rgb(86, 145, 56)',
    label: 'Minecraft Green',
  },
  {
    color: 'rgb(108, 110, 160)',
    label: 'Minecraft Grey Purple',
  },
];

export default colors;
