import { useMemo } from 'react';
import { useApolloClient, useMutation } from '@apollo/client';

import isArray from 'utils/functions/isArray';

/**
 * This hook will update the cache when a resource is removed.
 * However, when the list is a paginated list, the current page will be refetched.
 * This is because otherwise the pagination links will have wrong values.
 */
export default function useRemoveMutation(mutation, query, queryOptions, key) {
  const client = useApolloClient();

  const resultValue = useMemo(() => {
    let queryResults = {};

    try {
      queryResults = client.cache.readQuery({ query, ...queryOptions }) || {};
      // eslint-disable-next-line no-empty
    } catch {}

    return Object.values(queryResults)[0] || [];
  }, [client.cache, query, queryOptions]);

  const isQueryPaginated = !key && !isArray(resultValue);

  const [mutate, mutateResult] = useMutation(mutation, {
    refetchQueries: isQueryPaginated ? [{ query, ...queryOptions }] : undefined,
    awaitRefetchQueries: isQueryPaginated,
    update(cache, { data: removedResult }) {
      if (isQueryPaginated) {
        return;
      }

      if (!key) {
        throw new Error('Not yet supported');
      }

      const removedResource = Object.values(removedResult)[0];

      cache.modify({
        id: cache.identify(resultValue),
        fields: {
          // eslint-disable-next-line default-param-last
          [key](existingResourcesRef = [], { readField }) {
            return existingResourcesRef.filter(
              (ref) => readField('id', ref) !== removedResource.id,
            );
          },
        },
      });
    },
  });

  return [(variables) => mutate({ variables }), mutateResult];
}
