import { ActionStyledAsButton } from '@ftrprf/tailwind-components';

import { ROOT } from 'utils/constants/urls';
import useFormatMessage from '../../hooks/useFormatMessage';
import useTitle from '../../hooks/useTitle';

import { ReactComponent as Background } from '../../assets/vectors/abstract-shapes.svg';
import { ReactComponent as Unicorn } from '../../assets/vectors/unicorn.svg';

export default function ErrorPage() {
  const t = useFormatMessage();
  useTitle(t('errorpage.title'));

  return (
    <div className="w-full max-w-5/6 mt-8 mb-4 flex flex-1 justify-start sm:justify-around items-center flex-col sm:flex-row">
      <div className="flex-auto max-w-xs sm:max-w-md text-gray-800 relative">
        <Background className="h-full w-full text-green-100 transform scale-150" />
        <Unicorn className="h-full w-full absolute inset-0 p-5" />
      </div>
      <div className="flex-auto mt-5 sm:ml-5 sm:mt-0 max-w-md z-10">
        <h1 className="text-6xl font-bold text-green-600 mb-10">
          {t('errorpage.header')}
        </h1>
        <p className="text-xl font-bold">{t('errorpage.subheader')}</p>
        <p className="text-sm text-gray-700 mb-10">{t('errorpage.message')}</p>
        <ActionStyledAsButton className="mb-2" to={ROOT}>
          {t('errorpage.to_home')}
        </ActionStyledAsButton>
        <ActionStyledAsButton
          className="text-sm underline"
          onClick={() => window.location.reload(false)}
          type="button"
          variant="text"
        >
          {t('errorpage.refresh')}
        </ActionStyledAsButton>
      </div>
    </div>
  );
}
