// the below is suppressed because formik as used requires spreading
/* eslint-disable no-unused-vars,react/jsx-props-no-spreading */
import { useCallback, useContext, useState } from 'react';
import { useForm } from 'react-hook-form';

import {
  ActionStyledAsButton,
  DEFAULT_ASSISTANT,
  Dialog,
  DialogActions,
  DialogContent,
  DialogHeader,
} from '@ftrprf/tailwind-components';

import { UserContext } from 'providers/UserProvider';
import useFormatMessage from 'hooks/useFormatMessage';

import CONTENT_TYPES from 'utils/constants/contentTypes';
import LANGUAGES from 'utils/constants/languages';

import { cloneDeep } from 'lodash-es';
import { UrlDialog } from './UrlDialog';
import { Title } from './ContentMetaDataDialog/Title';
import { SteamsOnlyFields } from './ContentMetaDataDialog/SteamsOnlyFields';
import { LanguageDropdown } from './ContentMetaDataDialog/LanguageDropdown';
import { CoachDropdown } from './ContentMetaDataDialog/CoachDropdown';
import { EditorChoice } from './ContentMetaDataDialog/EditorChoice';
import { ContentTypeAndVersion } from './ContentMetaDataDialog/ContentTypeAndVersion';
import { BetaUserOnlyFields } from './ContentMetaDataDialog/BetaUserOnlyFields';
import { ImageFields } from './ContentMetaDataDialog/ImageFields';
import { TemplateAndGroupFields } from './ContentMetaDataDialog/TemplateAndGroupFields';

export default function ContentMetaDataDialog({
  content = {
    clan: null,
    endGoals: [],
    groupLesson: false,
    image: '',
    language: LANGUAGES.NL,
    level: null,
    programId: undefined,
    tags: [],
    title: '',
    coach: DEFAULT_ASSISTANT,
  },
  error,
  initialType,
  onDismiss,
  onSubmit,
  ...props
}) {
  const isCreating = !content.id;
  const isCreatingLesson = isCreating && initialType === CONTENT_TYPES.LESSON;

  const t = useFormatMessage();
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: {
      ...content,
      coach: content.coach || DEFAULT_ASSISTANT,
    },
  });

  const [imageUrl, setImageUrl] = useState(content.image);
  const [isGroupLesson, setIsGroupLesson] = useState(content.groupLesson);
  const [openExternalUrlDialog, setOpenExternalUrlDialog] = useState(false);
  const [selectedTemplateId, setSelectedTemplateId] = useState();

  const filterInput = (e) => {
    if (/[^A-Za-z0-9]+/g.test(e.key)) {
      e.preventDefault();
    }
  };

  const onDismissEnchancer = useCallback(() => {
    onDismiss();
    reset();
  }, [onDismiss, reset]);

  const onSubmitEnhancer = useCallback(
    (data) => {
      const clone = cloneDeep(data);
      if (clone.tags[0] instanceof Object) {
        clone.tags = clone.tags.map((el) => el.name);
      }
      onSubmit({
        ...clone,
        endGoals: clone.endGoals.map((el) => el.id),
        groupLesson: isGroupLesson,
        groupRoles: clone.groupRoles
          ? clone.groupRoles.map((role) => {
              if (typeof role === 'string' || role instanceof String) {
                return { role };
              }

              // eslint-disable-next-line no-param-reassign
              delete role.__typename;

              return role;
            })
          : [],
        image: imageUrl,
        templateIdToCopy: selectedTemplateId,
        type: isCreating ? initialType : clone.type,
      });
    },
    [
      imageUrl,
      initialType,
      isCreating,
      isGroupLesson,
      onSubmit,
      selectedTemplateId,
    ],
  );

  const title = isCreating
    ? t(`content-meta-data-dialog.${initialType}.new_title`)
    : t(`content-meta-data-dialog.${content.type}.update_title`);

  const { organization } = useContext(UserContext);

  return (
    <>
      {openExternalUrlDialog && (
        <UrlDialog
          setImageUrl={setImageUrl}
          setOpenExternalUrlDialog={setOpenExternalUrlDialog}
        />
      )}

      <Dialog onDismiss={onDismissEnchancer} {...props} aria-label={title}>
        <form className="w-full" onSubmit={handleSubmit(onSubmitEnhancer)}>
          <DialogHeader>{title}</DialogHeader>
          <DialogContent>
            <Title content={content} control={control} errors={errors} />

            {String(initialType) === String(CONTENT_TYPES.LESSON_TEMPLATE) && (
              <SteamsOnlyFields content={content} control={control} />
            )}

            <LanguageDropdown
              content={content}
              control={control}
              errors={errors}
            />
            <CoachDropdown content={content} control={control} />
            <EditorChoice control={control} />

            {!isCreating && (
              <ContentTypeAndVersion
                content={content}
                control={control}
                errors={errors}
              />
            )}

            {organization.isBeta && (
              <BetaUserOnlyFields
                content={content}
                control={control}
                filterInput={filterInput}
              />
            )}

            <ImageFields
              imageUrl={imageUrl}
              setImageUrl={setImageUrl}
              setOpenExternalUrlDialog={setOpenExternalUrlDialog}
            />

            <TemplateAndGroupFields
              content={content}
              control={control}
              filterInput={filterInput}
              isCreatingLesson={isCreatingLesson}
              isGroupLesson={isGroupLesson}
              selectedTemplateId={selectedTemplateId}
              setIsGroupLesson={setIsGroupLesson}
              setSelectedTemplateId={setSelectedTemplateId}
            />
          </DialogContent>
          <DialogActions>
            <ActionStyledAsButton onClick={onDismissEnchancer} variant="text">
              {t('global.cancel')}
            </ActionStyledAsButton>
            <ActionStyledAsButton
              disabled={Object.keys(errors).length > 0}
              type="submit"
            >
              {isCreating ? t('global.add') : t('global.save')}
            </ActionStyledAsButton>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
