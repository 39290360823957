import { ActionStyledAsButton } from '@ftrprf/tailwind-components';
import useFormatMessage from '../hooks/useFormatMessage';

import { ReactComponent as ClearIcon } from '../assets/vectors/clear-filter.svg';

export default function Filterbar({ children, className, onClear }) {
  const t = useFormatMessage();

  return (
    <div className={className}>
      {children}
      <ActionStyledAsButton
        onClick={onClear}
        secondary
        title={t('filterbar.clear')}
        variant="white"
      >
        <ClearIcon className="h-4" />
      </ActionStyledAsButton>
    </div>
  );
}
