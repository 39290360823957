import { useContext, useEffect, useState } from 'react';
import { FaChevronRight } from 'react-icons/fa';
import { FolderIcon } from '@heroicons/react/24/solid';

import { Loader } from '@ftrprf/tailwind-components';

import useContentNavigationTree from 'hooks/graphql/file-manager/queries/useContentNavigationTree';

import c from 'utils/functions/c';

import { FileManagerContext } from '../FileManagerContextProvider';

export default function TreeFolderCollapsable({
  folder,
  isOpen: defaultIsOpen,
}) {
  const {
    children: subFolders,
    hasChildren: hasSubFolders,
    id,
    name,
    path,
  } = folder;

  const [isOpen, setIsOpen] = useState(defaultIsOpen);

  const { activeFolder, activePath, navigateToFolder, setIsSearching } =
    useContext(FileManagerContext);

  const {
    data: additionalSubFolders,
    fetch: fetchAdditionalFolders,
    loading: isLoadingAdditionalSubFolders,
  } = useContentNavigationTree(false);

  useEffect(() => {
    if (activePath === path && activeFolder?.path !== path) {
      navigateToFolder(folder);
    }
  }, [activeFolder?.path, activePath, folder, navigateToFolder, path]);

  useEffect(() => {
    if (`${activePath}/`?.includes(`${path}/`)) {
      setIsOpen(true);
    }
  }, [
    activeFolder?.id,
    activeFolder?.path,
    activePath,
    folder,
    folder.id,
    id,
    path,
    navigateToFolder,
  ]);

  useEffect(() => {
    if (isOpen && hasSubFolders && subFolders?.length === 0) {
      void fetchAdditionalFolders({
        variables: {
          startFolderId: id,
          levels: 1,
          onlyFolders: true,
        },
      });
    }
  }, [fetchAdditionalFolders, hasSubFolders, id, isOpen, subFolders?.length]);

  return (
    <>
      <div
        className={c(
          'flex gap-2 px-2 max-w-full hover:bg-gray-100 rounded',
          activeFolder?.id === id && 'bg-gray-200 bg-opacity-70',
        )}
      >
        <button
          className={c('', hasSubFolders || 'invisible')}
          onClick={() => {
            setIsOpen((prev) => !prev);
          }}
          type="button"
        >
          <FaChevronRight
            className={c(
              'w-2 text-gray-400 cursor-pointer',
              isOpen
                ? 'transform rotate-90 transition duration-150'
                : 'transition duration-150',
            )}
          />
        </button>
        <button
          className="flex gap-2 min-w-0"
          onClick={() => {
            navigateToFolder(folder);
            setIsSearching(false);
          }}
          title={name}
          type="button"
        >
          <FolderIcon className="w-5 text-gray-400 shrink-0" />
          <span className="truncate max-w-full py-1">{name}</span>
        </button>
      </div>
      {isOpen && (
        <div className="ml-2">
          {isLoadingAdditionalSubFolders && (
            <div className="w-5 ml-6">
              <Loader className="w-5" />
            </div>
          )}
          {[...(subFolders || []), ...(additionalSubFolders || [])].map(
            (subFolder) => (
              <TreeFolderCollapsable key={subFolder.id} folder={subFolder} />
            ),
          )}
        </div>
      )}
    </>
  );
}
