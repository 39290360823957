import { generatePrefixedType } from './generatePrefixedType';
import { styledLinks } from './styledLinks';

/**
 * @param {String[]} linkArray - Array of strings containing a link type.
 * @returns {styledLinks} The type of link, see styledLinks.
 */
export const determineType = (linkArray) => {
  const linkTypeLookupObject = {
    [generatePrefixedType(styledLinks.SLIDE)]: styledLinks.SLIDE,
    [generatePrefixedType(styledLinks.VIDEO)]: styledLinks.VIDEO,
    [generatePrefixedType(styledLinks.DOWNLOAD)]: styledLinks.DOWNLOAD,
    [generatePrefixedType(styledLinks.OTHER)]: styledLinks.OTHER,
    [generatePrefixedType(styledLinks.EXTERNAL)]: styledLinks.EXTERNAL,
  };

  const linkType = Object.keys(linkTypeLookupObject).find((key) =>
    linkArray.includes(key),
  );

  return linkType ? linkTypeLookupObject[linkType] : null;
};
