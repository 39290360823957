import { useQuery } from '@apollo/client';

import { FIND_CONTENT_NAMES_BY_PATH } from '../../api/files';

export default function useFileNamesInPath(path) {
  const { data } = useQuery(FIND_CONTENT_NAMES_BY_PATH, {
    variables: { path },
  });

  const fileNames = data?.findAllContentFilesByPath.reduce(
    (res, { path }) => ({
      ...res,
      [path.substring(path.lastIndexOf('/'))]: true,
    }),
    {},
  );

  return fileNames;
}
