import { useState } from 'react';
import { FormattedDate } from 'react-intl';
import { Link } from 'react-router-dom';

import { ConfirmationDialog } from '@ftrprf/tailwind-components';

import useFormatMessage from '../../../../hooks/useFormatMessage';

import * as urls from '../../../../utils/constants/urls';

import { ReactComponent as EmptyImage } from '../../../../assets/vectors/empty-image.svg';

import { ContentCard } from './ContentCard';
import ContentCardContextMenu from './ContentCardContextMenu';
import ContentCardStatus from './ContentCardStatus';

export default function StudioContentCard({
  archive,
  content,
  contentType,
  copy,
  remove,
  unarchive,
}) {
  const t = useFormatMessage();

  const [showRemoveContentDialog, setShowRemoveContentDialog] = useState(false);
  const [showCopyContentDialog, setShowCopyContentDialog] = useState(false);
  const [showArchiveContentDialog, setShowArchiveContentDialog] =
    useState(false);
  const [showUnarchiveContentDialog, setShowUnarchiveContentDialog] =
    useState(false);

  const Parent = content.archived ? 'div' : Link;

  return (
    <>
      <ConfirmationDialog
        isOpen={showRemoveContentDialog}
        onConfirm={() => {
          setShowRemoveContentDialog(false);
          remove();
        }}
        onDismiss={() => setShowRemoveContentDialog(false)}
      />
      <ConfirmationDialog
        isOpen={showCopyContentDialog}
        onConfirm={() => {
          setShowCopyContentDialog(false);
          copy();
        }}
        onDismiss={() => setShowCopyContentDialog(false)}
      />
      <ConfirmationDialog
        isOpen={showArchiveContentDialog}
        onConfirm={() => {
          setShowArchiveContentDialog(false);
          archive();
        }}
        onDismiss={() => setShowArchiveContentDialog(false)}
      />
      <ConfirmationDialog
        isOpen={showUnarchiveContentDialog}
        onConfirm={() => {
          setShowUnarchiveContentDialog(false);
          unarchive();
        }}
        onDismiss={() => setShowUnarchiveContentDialog(false)}
      />
      <ContentCardContextMenu
        content={content}
        contentType={contentType}
        onArchive={() => setShowArchiveContentDialog(true)}
        onCopy={() => setShowCopyContentDialog(true)}
        onRemove={() => setShowRemoveContentDialog(true)}
        onUnarchive={() => setShowUnarchiveContentDialog(true)}
      >
        {({ toggle }) => (
          <Parent
            target="_blank"
            to={
              content.archived
                ? undefined
                : `${urls[contentType]}/${content.id}/edit`
            }
          >
            <ContentCard
              dataTest="studio-lesson"
              defaultImage={EmptyImage}
              disabled={content.archived}
              image={content.image}
              statusLabel={ContentCardStatus({ content })}
              subTitle={
                <>
                  {t('content.last_modified_by')}{' '}
                  <span className="font-semibold">{content.modifiedBy}</span>{' '}
                  {t('global.on')}{' '}
                  <span className="font-semibold">
                    <FormattedDate
                      day="2-digit"
                      month="long"
                      value={new Date(content.modifiedOn)}
                      year="numeric"
                    />
                  </span>
                </>
              }
              title={content.title}
              toggleContextMenu={toggle}
            />
          </Parent>
        )}
      </ContentCardContextMenu>
    </>
  );
}
