import { useCallback, useContext, useRef, useState } from 'react';
import {
  ArrowUpTrayIcon,
  ClipboardDocumentIcon,
  PlusIcon,
  ScissorsIcon,
  TrashIcon,
  XMarkIcon,
} from '@heroicons/react/24/solid';

import {
  CheckBox,
  ConfirmationDialog,
  FileInput,
  Input,
  Loader,
  ActionStyledAsButton,
} from '@ftrprf/tailwind-components';

import { useContentDelete } from 'hooks/graphql/file-manager/mutations/useContentDelete';
import useCreateFolder from 'hooks/graphql/file-manager/mutations/useCreateFolder';
import useFileMove from 'hooks/graphql/file-manager/mutations/useFileMove';
import useFormatMessage from 'hooks/useFormatMessage';

import { FileManagerContext } from '../FileManagerContextProvider';
import { SearchInput } from './SearchInput';

export default function FileManagerButtons() {
  const t = useFormatMessage();

  const [copiedContent, setCopiedContent] = useState([]);
  const [oldFolderId, setOldFolderId] = useState();
  const { dialog, setDialog } = useContext(FileManagerContext);
  const newFolderNameRef = useRef();
  const searchRef = useRef();

  const { deleteContent } = useContentDelete();
  const { moveFiles } = useFileMove();
  const { createFolder } = useCreateFolder();

  const {
    activeFolder,
    currentFolderContent,
    isFileUploadLoading,
    isSearching,
    refetchCurrentFolderContent,
    reloadFileTree,
    searchResults,
    selectedContent,
    setIsSearching,
    setSelectedContent,
    uploadFiles,
  } = useContext(FileManagerContext);

  const onCut = useCallback(() => {
    setOldFolderId(activeFolder?.id);
    setCopiedContent(selectedContent);
  }, [activeFolder?.id, selectedContent]);

  const onDelete = useCallback(async () => {
    await deleteContent({
      variables: { contentIds: selectedContent.map((content) => content.id) },
    });
    setSelectedContent([]);
    reloadFileTree();
    setDialog();
    refetchCurrentFolderContent();
  }, [
    deleteContent,
    refetchCurrentFolderContent,
    reloadFileTree,
    selectedContent,
    setDialog,
    setSelectedContent,
  ]);

  const onMove = useCallback(async () => {
    await moveFiles({
      variables: {
        contentIds: copiedContent.map((content) => content.id),
        newFolderId: activeFolder?.id || null,
        oldFolderId,
      },
    });

    reloadFileTree();
    refetchCurrentFolderContent();
    setDialog();
    setOldFolderId(undefined);
    setCopiedContent([]);
  }, [
    activeFolder?.id,
    copiedContent,
    moveFiles,
    oldFolderId,
    refetchCurrentFolderContent,
    reloadFileTree,
    setDialog,
  ]);

  const onNewFolder = useCallback(async () => {
    const name = newFolderNameRef.current.value;
    if (!name) {
      return;
    }
    await createFolder({
      variables: {
        folderName: name,
        parentFolderId: activeFolder?.id || null,
      },
    });
    reloadFileTree();
    refetchCurrentFolderContent();
    setDialog();
  }, [
    activeFolder?.id,
    createFolder,
    refetchCurrentFolderContent,
    reloadFileTree,
    setDialog,
  ]);

  return (
    <>
      <div className="w-full flex flex-wrap items-center justify-between p-5 gap-2 min-h-5 z-20 border-b border-gray-200 mb-2 bg-white text-gray-700 text-sm">
        <div className="flex gap-5 items-center">
          <h1 className="font-bold h-fit">File manager</h1>
          <h2 className="sr-only">{t('file.manager.buttons.title')}</h2>
          <div className="relative w-64">
            <SearchInput />
            {isSearching && (
              <button
                aria-label={t('file.manager.clear-search')}
                className="absolute h-full top-0 right-3 hover:cursor-pointer"
                onClick={() => {
                  searchRef.current.value = '';
                  setIsSearching(false);
                }}
                type="button"
              >
                <XMarkIcon className="w-3 text-gray-400" />
              </button>
            )}
          </div>
        </div>
        <div className="flex gap-2 items-center">
          <div className="flex h-fit shrink-0">
            <CheckBox
              checked={
                selectedContent?.length &&
                selectedContent?.length === currentFolderContent?.length
              }
              disabled={!currentFolderContent?.length}
              label={t('file.manager.select-all')}
              onChange={(value) => {
                if (value) {
                  setSelectedContent(
                    isSearching ? searchResults : currentFolderContent,
                  );
                  return;
                }
                setSelectedContent([]);
              }}
            />
          </div>
          <ActionStyledAsButton
            disabled={
              selectedContent.length === 0 ||
              selectedContent.filter((content) => content.slides?.length).length
            }
            iconBefore={TrashIcon}
            onClick={() => setDialog('delete')}
            secondary
            size="small"
          >
            {t('global.delete')}
          </ActionStyledAsButton>
          {isSearching || (
            <ActionStyledAsButton
              disabled={
                selectedContent.length === 0 || copiedContent?.length > 0
              }
              iconBefore={ScissorsIcon}
              onClick={onCut}
              secondary
              size="small"
            >
              {t('file.manager.cut')}
            </ActionStyledAsButton>
          )}

          {isSearching || (
            <>
              {copiedContent.length > 0 && (
                <ActionStyledAsButton
                  disabled={
                    activeFolder?.id === oldFolderId ||
                    activeFolder === oldFolderId
                  }
                  iconBefore={ClipboardDocumentIcon}
                  onClick={() => setDialog('move')}
                  secondary
                  size="small"
                >
                  {t('file.manager.paste')}
                </ActionStyledAsButton>
              )}
              <ActionStyledAsButton
                className="shrink-0"
                iconBefore={PlusIcon}
                onClick={() => setDialog('newFolder')}
                secondary
                size="small"
              >
                {t('file.manager.create-folder')}
              </ActionStyledAsButton>
              {!isFileUploadLoading ? (
                <FileInput multiple onUpload={uploadFiles} test="file-upload">
                  {(onOpenFileDialog) => (
                    <ActionStyledAsButton
                      className="shrink-0"
                      iconBefore={ArrowUpTrayIcon}
                      onClick={onOpenFileDialog}
                      size="small"
                    >
                      {t('file.manager.upload')}
                    </ActionStyledAsButton>
                  )}
                </FileInput>
              ) : (
                <div className="h-fit w-5">
                  <Loader />
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <ConfirmationDialog
        content={<p>{t('file.manager.delete.confirmation')}</p>}
        isOpen={dialog === 'delete'}
        onConfirm={onDelete}
        onDismiss={() => setDialog()}
      />
      <ConfirmationDialog
        content={<p>{t('file.manager.move-confirmation')}</p>}
        isOpen={dialog === 'move'}
        onConfirm={onMove}
        onDismiss={() => setDialog()}
      />
      <ConfirmationDialog
        content={<p>{t('file.manager.overwrite.confirmation')}</p>}
        isOpen={dialog?.name === 'overwrite'}
        onConfirm={() => uploadFiles(dialog.data, true)}
        onDismiss={() => setDialog()}
      />
      <ConfirmationDialog
        confirmText={t('file.manager.new-folder-confirm')}
        content={
          <Input
            ref={newFolderNameRef}
            onKeyDown={(e) => e.key === 'Enter' && onNewFolder()}
            placeholder={t('file.manager.folder-name')}
          />
        }
        isOpen={dialog === 'newFolder'}
        onConfirm={onNewFolder}
        onDismiss={() => setDialog()}
        title={t('file.manager.new-folder')}
      />
    </>
  );
}
