import { useMutation } from 'react-query';

import { IMPERSONATE_USER } from 'api/plannerService/admin/mutations/impersonateUser';
import { plannerServiceQueryClient } from 'utils/graphqlQueryClient';

export default function useImpersonateUser() {
  const response = useMutation(
    (id) =>
      plannerServiceQueryClient.request(IMPERSONATE_USER, {
        id,
      }),
    {
      mutationKey: 'impersonateUser',
      onError: () => {},
    },
  );

  return {
    ...response,
    data: response?.data?.impersonateUser,
  };
}
