/* eslint-disable react/jsx-props-no-spreading */
import { useCallback, useContext, useEffect } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import { NotificationContext } from '@ftrprf/tailwind-components';

import useFormatMessage from 'hooks/useFormatMessage';

import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import updateSlideSequences from '../../utils/updateSlideSequences';
import { multiSelectTo, reorderItems, swap } from './utils';

import SlideOverviewItem from './SlideOverviewItem';

const getItemStyle = (draggableStyle, virtualizedStyle) => ({
  ...draggableStyle,
  ...virtualizedStyle,
  margin: 0,
  outline: 'none',
});

function Row({ data, index, style }) {
  const {
    disabled,
    hasViewModeSelector,
    isCk5,
    onDelete,
    onDuplicate,
    onInsert,
    onMoveDown,
    onMoveUp,
    selectedSlideIds,
    slides,
    toggleSelection,
    toggleSelectionGroupKey,
    toggleSelectionShiftKey,
  } = data;
  return (
    <Draggable
      key={slides[index].id}
      draggableId={slides[index].id}
      index={index}
    >
      {(draggableProvided, draggableSnapshot) => (
        <div
          ref={draggableProvided.innerRef}
          className="w-full slideOverviewItem"
          {...draggableProvided.draggableProps}
          {...draggableProvided.dragHandleProps}
          style={getItemStyle(draggableProvided.draggableProps.style, style)}
        >
          <div className="pt-2 px-2">
            <div className="w-full">
              <SlideOverviewItem
                disabled={disabled}
                draggableSnapshot={draggableSnapshot}
                hasViewModeSelector={hasViewModeSelector}
                index={index}
                isCk5={isCk5}
                isSelected={selectedSlideIds.includes(slides[index].id)}
                onDelete={() => onDelete(slides[index])}
                onDuplicate={() => onDuplicate(slides[index])}
                onInsert={() => onInsert(slides[index])}
                onMoveDown={() => onMoveDown(slides[index])}
                onMoveUp={() => onMoveUp(slides[index])}
                slideId={slides[index].id}
                toggleSelection={toggleSelection}
                toggleSelectionShiftKey={toggleSelectionShiftKey}
                toggsleSelectionGroupKey={toggleSelectionGroupKey}
              />
            </div>
          </div>
        </div>
      )}
    </Draggable>
  );
}

/**
 * This component manages a copy of the slides.
 */
export default function SlideOverview({
  canSwitchSlides,
  currentSlide,
  disabled,
  hasViewModeSelector,
  isCk5,
  onDuplicateSlide,
  onInsertSlide,
  onRemoveSlides,
  selectedSlideIds,
  setCurrentSlide,
  setSelectedSlideIds,
  setShowCreateNewVersionDialog,
  setSlideSequences,
  slides = [],
}) {
  const t = useFormatMessage();
  const { addNotification } = useContext(NotificationContext);

  const onMoveUp = useCallback(
    (slide) => {
      const index = slides.findIndex((s) => s.id === slide.id);

      if (index === 0) {
        return;
      }

      const [slideSequences] = updateSlideSequences(
        swap(slides, index, index - 1),
      );

      setSlideSequences(slideSequences);
    },
    [slides, setSlideSequences],
  );

  const onMoveDown = useCallback(
    (slide) => {
      const index = slides.findIndex((s) => s.id === slide.id);

      if (index === slides.length - 1) {
        return;
      }

      const [slideSequences] = updateSlideSequences(
        swap(slides, index, index + 1),
      );
      setSlideSequences(slideSequences);
    },
    [slides, setSlideSequences],
  );

  const onInsert = useCallback(
    (slide) => {
      onInsertSlide(slide);
    },
    [onInsertSlide],
  );

  const onDelete = useCallback(
    (slide) => {
      if (selectedSlideIds.length === slides.length) {
        addNotification({
          type: 'warning',
          content: t('content-editor.errors.attempting_to_delete_all'),
        });

        return;
      }

      // Delete one slide that was not selected
      if (!selectedSlideIds.includes(slide.id)) {
        const result = slides.filter((s) => s.id !== slide.id);
        const [slideSequences] = updateSlideSequences(result);

        onRemoveSlides([slide], slideSequences);

        return;
      }

      // Delete all slides that were selected.
      const slidesToRemove = slides.filter((s) =>
        selectedSlideIds.includes(s.id),
      );

      const slidesToKeep = slides.filter(
        (s) => !selectedSlideIds.includes(s.id),
      );

      const [slideSequences] = updateSlideSequences(slidesToKeep);

      // Calculate the closest slide to set as the new current slide
      const nextSlide =
        slidesToKeep.find(
          (s) => s.sequence === slidesToRemove[0].sequence - 1,
        ) || slidesToKeep[0];

      setCurrentSlide(nextSlide);
      onRemoveSlides(slidesToRemove, slideSequences);
      setSelectedSlideIds(nextSlide ? [nextSlide.id] : []);
    },
    [
      selectedSlideIds,
      slides,
      setCurrentSlide,
      onRemoveSlides,
      setSelectedSlideIds,
      addNotification,
      t,
    ],
  );

  const onDuplicate = useCallback(
    (slide) => {
      onDuplicateSlide(slide);
    },
    [onDuplicateSlide],
  );

  const onDragStart = (result) => {
    const slideId = result.draggableId;

    if (!selectedSlideIds.includes(slideId)) {
      setSelectedSlideIds([result.draggableId]);
    }

    setCurrentSlide(slides.find((o) => o.id === slideId));
  };

  const onDragEnd = (result) => {
    // dropped outside the Droppable or published
    if (disabled) {
      setShowCreateNewVersionDialog(true);
      return;
    }

    if (!result.destination) {
      return;
    }

    if (result.source.index === result.destination.index) {
      return;
    }

    const newlyOrderedSlides = reorderItems(slides, selectedSlideIds, result);

    const [slideSequences] = updateSlideSequences(newlyOrderedSlides);
    setSlideSequences(slideSequences);
  };

  const toggleSelection = useCallback(
    (slide) => {
      setSelectedSlideIds([slide.id]);
      setCurrentSlide(slide);
    },
    [setSelectedSlideIds, setCurrentSlide],
  );

  const toggleSelectionGroupKey = useCallback(
    (slide) => {
      if (!selectedSlideIds.includes(slide.id)) {
        setSelectedSlideIds((s) => [...s, slide.id]);
      } else {
        setSelectedSlideIds((s) => s.filter((id) => id !== slide.id));
      }
    },
    [selectedSlideIds, setSelectedSlideIds],
  );

  const toggleSelectionShiftKey = useCallback(
    (slide) => {
      setSelectedSlideIds(multiSelectTo(slide, slides, selectedSlideIds));
    },
    [slides, setSelectedSlideIds, selectedSlideIds],
  );

  const downHandler = useCallback(
    ({ key }) => {
      if (canSwitchSlides) {
        const index = slides.findIndex((s) => s.id === currentSlide?.id);

        if (key === 'ArrowDown' && index < slides.length - 1) {
          toggleSelection(slides[index + 1]);
        }

        if (key === 'ArrowUp' && index > 0) {
          toggleSelection(slides[index - 1]);
        }
      }
    },
    [currentSlide?.id, canSwitchSlides, slides, toggleSelection],
  );

  useEffect(() => {
    window.addEventListener('keydown', downHandler);

    return () => {
      window.removeEventListener('keydown', downHandler);
    };
  }, [currentSlide, downHandler, slides, selectedSlideIds]);

  return (
    <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
      <Droppable
        droppableId="droppable-overview"
        mode="virtual"
        renderClone={(provided, snapshot, rubric) => {
          const slide = slides[rubric.source.index];

          return (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              style={getItemStyle(provided.draggableProps.style)}
            >
              <div className="w-full h-full">
                <SlideOverviewItem
                  draggableSnapshot={snapshot}
                  index={rubric.source.index}
                  isSelected
                  slideId={slide.id}
                />
              </div>
            </div>
          );
        }}
      >
        {(droppableProvided) => (
          <AutoSizer defaultHeight={1} defaultWidth={1}>
            {({ height, width }) => (
              <List
                height={height}
                itemCount={slides.length}
                itemData={{
                  slides,
                  selectedSlideIds,
                  isCk5,
                  toggleSelection,
                  toggleSelectionGroupKey,
                  toggleSelectionShiftKey,
                  onInsert,
                  onDelete,
                  onMoveDown,
                  onMoveUp,
                  onDuplicate,
                  disabled,
                  hasViewModeSelector,
                }}
                itemSize={185}
                outerRef={droppableProvided.innerRef}
                width={width}
              >
                {Row}
              </List>
            )}
          </AutoSizer>
        )}
      </Droppable>
    </DragDropContext>
  );
}
