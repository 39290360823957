export function getRidOfNbspOverloadOutsideOfLink(htmlString) {
  return (
    htmlString
      // replace overload of nbsp before a link (twice to get rid of ancient stuff)
      .replaceAll('&nbsp; &nbsp; &nbsp; &nbsp;<a', '&nbsp;&nbsp;<a')
      .replaceAll('&nbsp;&nbsp;&nbsp;&nbsp;<a', '&nbsp;&nbsp;<a')
      .replaceAll('&nbsp;&nbsp;&nbsp;&nbsp;<a', '&nbsp;&nbsp;<a')
      // replace overload of nbsp after a link (twice to get rid of ancient stuff)
      .replaceAll('</a>&nbsp;&nbsp;', '</a>&nbsp;')
      .replaceAll('</a>&nbsp;&nbsp;', '</a>&nbsp;')
      .replaceAll('</a> &nbsp; &nbsp;', '</a>&nbsp;')
      .replaceAll('</a> &nbsp; &nbsp;', '</a>&nbsp;')
  );
}
