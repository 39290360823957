const roles = {
  SCHOOLTEACHER: 'FTRPRF_SCHOOLTEACHER',
  ADMIN: 'FTRPRF_ADMIN',
  SCHOOLSTUDENT: 'FTRPRF_SCHOOLSTUDENT',
  SCHOOLADMIN: 'FTRPRF_SCHOOLADMIN',
};

roles.FTRPRF_SCHOOLTEACHER = roles.SCHOOLTEACHER;
roles.FTRPRF_ADMIN = roles.ADMIN;
roles.FTRPRF_SCHOOLSTUDENT = roles.SCHOOLSTUDENT;
roles.FTRPRF_SCHOOLADMIN = roles.SCHOOLADMIN;

export default roles;
