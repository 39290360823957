import { useContext } from 'react';

import { CheckBox } from '@ftrprf/tailwind-components';

import useFormatMessage from 'hooks/useFormatMessage';

import c from 'utils/functions/c';
import { fileDateFormatter } from '../utils/fileDateFormatter';
import { fileNameWithExtension } from '../utils/fileNameWithExtension';

import { FileManagerContext } from '../FileManagerContextProvider';
import ThumbnailSmall from './ThumbnailSmall';

export default function FolderDetailItem({ content, isEmbedded = false }) {
  const t = useFormatMessage();

  const {
    blobUri,
    extension,
    id,
    isFile,
    modifiedBy,
    modifiedOn,
    name,
    path,
    slides,
    thumbnailSmall,
  } = content;

  const {
    isSearching,
    navigateToFolder,
    selectedContent,
    setIsSearching,
    setSelectedContent,
  } = useContext(FileManagerContext);

  const onCheckboxChange = (value) => {
    if (value) {
      setSelectedContent((prev) => [...prev, content]);
      return;
    }
    setSelectedContent((prev) => prev.filter((content) => content.id !== id));
  };

  const onRowClick = () => {
    if (isFile) {
      setSelectedContent([content]);
      return;
    }
    navigateToFolder(content);
    setSelectedContent([]);
    setIsSearching(false);
  };

  return (
    <tr
      className={c(
        'hover:bg-accent-50 group hover:cursor-pointer border-b-[1px] h-9 content-center',
        selectedContent.find((content) => content.id === id) && 'bg-accent-100',
      )}
    >
      <td className={c('w-10 z-10 pl-2', isEmbedded && 'invisible w-5')}>
        <CheckBox
          checked={
            selectedContent.find((content) => content.id === id) || false
          }
          data-test={`checkbox-${name}`}
          onChange={onCheckboxChange}
        />
      </td>

      <td className="w-10" onClick={onRowClick}>
        <ThumbnailSmall
          blobUri={blobUri}
          extension={extension}
          isFile={isFile}
          thumbnail={thumbnailSmall}
        />
      </td>
      <td className={c(isEmbedded ? '' : 'w-1/2')} onClick={onRowClick}>
        {fileNameWithExtension(content)}
      </td>
      {!isEmbedded && (
        <>
          <td className="pl-4" onClick={onRowClick}>
            {!isSearching ? fileDateFormatter(modifiedOn) : path}
          </td>
          <td className="pl-4" onClick={onRowClick}>
            {modifiedBy}
          </td>
          <td className="pl-4">
            {!isFile && (
              <abbr title={t('file.manager.not-applicable')}>
                {t('file.manager.abbr.not-applicable')}
              </abbr>
            )}
            {isFile &&
              slides?.length &&
              `${slides.length} ${t(
                slides.length === 1
                  ? 'file.manager.slide'
                  : 'file.manager.slides',
              )}`}
            {isFile && !slides?.length}
          </td>
        </>
      )}
    </tr>
  );
}
