import { Loader } from '@ftrprf/tailwind-components';
import useImpersonateUser from 'hooks/api/plannerService/admin/mutations/useImpersonateUser';
import ErrorPage from 'pages/ErrorPage';
import { UserContext } from 'providers/UserProvider';
import { useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ROOT, IMPERSONATE } from 'utils/constants/urls';

export default function ImpersonateRedirect() {
  const { id } = useParams();
  const navigate = useNavigate();

  const { mutateAsync: impersonateUser } = useImpersonateUser();
  const { isAdmin, isImpersonating } = useContext(UserContext);

  const ableToImpersonate = Boolean(isAdmin && id && !isImpersonating);

  useEffect(() => {
    if (ableToImpersonate) {
      impersonateUser(id).then(() => {
        localStorage.clear();
        window.location = `${process.env.REACT_APP_AD_REDIRECT_LINK}${IMPERSONATE}`;
      });
    } else if (isImpersonating) {
      navigate(ROOT, { replace: true });
    }
  }, [
    ableToImpersonate,
    id,
    impersonateUser,
    isAdmin,
    isImpersonating,
    navigate,
  ]);

  if (ableToImpersonate) {
    return (
      <div className="w-full h-full flex justify-center items-center">
        <div className="overflow-hidden max-w-1/8">
          <Loader />
        </div>
      </div>
    );
  }

  return <ErrorPage />;
}
