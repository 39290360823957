import c from 'utils/functions/c';

export function BackgroundPreview({ className = 'h-20', url }) {
  if (!url) {
    return (
      <div
        className={c(
          'bg-white border border-gray-400 drop-shadow-lg rounded',
          className,
        )}
        style={{ aspectRatio: '1366/768' }}
      />
    );
  }

  return (
    <img
      alt=""
      className={c(
        'object-cover border border-gray-400 drop-shadow-lg rounded',
        className,
      )}
      src={url}
      style={{ aspectRatio: '1366/768' }}
    />
  );
}
