import { FolderIcon } from '@heroicons/react/24/solid';
import c from 'utils/functions/c';
import FileIcon from './FileIcon';

const dimensions = 'w-7 h-7';
const color = 'text-gray-500';

export default function ThumbnailSmall({
  blobUri,
  extension,
  isFile,
  thumbnail,
}) {
  if (thumbnail) {
    return (
      <div
        className={c(
          'overflow-hidden grid place-items-center rounded border thumb-png-background group-hover:bg-accent-300 group-hover:border-accent-500',
          dimensions,
        )}
      >
        <img alt="" className="object-cover" src={thumbnail} />
      </div>
    );
  }

  if (extension === 'svg') {
    return (
      <div
        className={c(
          'overflow-hidden grid place-items-center rounded border thumb-png-background group-hover:bg-accent-300 group-hover:border-accent-500',
          dimensions,
        )}
      >
        <img alt="" className="object-cover" src={blobUri} />
      </div>
    );
  }

  if (isFile) {
    return (
      <FileIcon color={color} dimensions={dimensions} extension={extension} />
    );
  }

  return <FolderIcon className={c('w-5', color)} />;
}
