import { gql } from '@apollo/client';

export const FIND_SCRATCH_EXERCISE = gql`
  query ScratchExercise($id: Int!) {
    findExercise(id: $id) {
      id
      title
      titleNl
      titleEn
      titleDe
      titleEs
      titleFr
      type
      image
      versions {
        versionType
        ... on ScratchExerciseVersion {
          id
          blobUri
          name
          testPlanId
        }
      }
    }
  }
`;

export const FIND_SCRATCH_EXERCISE_VERSION = gql`
  query findScratchExerciseVersion($versionId: Int!, $language: Language!) {
    findScratchExerciseVersion(versionId: $versionId, language: $language) {
      id
      name
      exerciseId
      title
      versionType
      blobUri
      testPlanId
    }
  }
`;

export const CREATE_SCRATCH_EXERCISE_VERSION = gql`
  mutation CreateScratchExerciseVersion(
    $exerciseId: Int!
    $name: String
    $versionType: VersionType!
    $exerciseVersionIdToCopy: Int!
  ) {
    createScratchExerciseVersion(
      exerciseId: $exerciseId
      input: {
        versionType: $versionType
        exerciseVersionIdToCopy: $exerciseVersionIdToCopy
        name: $name
      }
    ) {
      id
      name
      exerciseId
      title
      versionType
    }
  }
`;

export const UPDATE_SCRATCH_EXERCISE_VERSION = gql`
  mutation UpdateScratchExerciseVersion(
    $versionId: Int!
    $name: String!
    $versionType: VersionType!
  ) {
    updateScratchExerciseVersion(
      versionId: $versionId
      input: { name: $name, versionType: $versionType }
    ) {
      id
      name
      exerciseId
      title
      versionType
    }
  }
`;

export const REMOVE_SCRATCH_EXERCISE_VERSION = gql`
  mutation RemoveScratchExerciseVersion($id: Int!) {
    removeScratchExerciseVersion(id: $id) {
      id
    }
  }
`;

export const FIND_ALL_EXERCISES = gql`
  query FindAllExercises($page: Int!, $filter: [JSON]) {
    findAllExercises(page: $page, size: 12, filter: $filter) {
      total
      pages
      currentPage
      content {
        id
        title
        type
        image
        versions {
          id
          name
          versionType
        }
      }
    }
  }
`;

// TEMP: TC-673 will implement the create exercise.
export const CREATE_SCRATCH_EXERCISE = gql`
  mutation CreateScratchExercise(
    $titleNl: String!
    $titleEn: String!
    $titleFr: String!
    $titleDe: String!
    $titleEs: String!
  ) {
    createScratchExercise(
      input: {
        titleNl: $titleNl
        titleEn: $titleEn
        titleFr: $titleFr
        titleDe: $titleDe
        titleEs: $titleEs
        versionType: STARTER
      }
    ) {
      id
      type
    }
  }
`;

export const UPDATE_SCRATCH_EXERCISE = gql`
  mutation UpdateScratchExercise(
    $id: Int!
    $titleNl: String!
    $titleEn: String!
    $titleFr: String!
    $titleDe: String!
    $titleEs: String!
    $image: String
  ) {
    updateScratchExercise(
      id: $id
      input: {
        titleNl: $titleNl
        titleEn: $titleEn
        titleFr: $titleFr
        titleDe: $titleDe
        titleEs: $titleEs
        versionType: STARTER
        image: $image
      }
    ) {
      id
    }
  }
`;

export const REMOVE_SCRATCH_EXERCISE = gql`
  mutation RemoveScratchExercise($id: Int!) {
    removeScratchExercise(id: $id) {
      statuscode
      id
      message
    }
  }
`;

export const UPDATE_SCRATCH_EXERCISE_BLOB = gql`
  mutation UpdateScratchExerciseBlob($versionId: Int!, $file: Upload!) {
    updateScratchExerciseBlob(versionId: $versionId, file: $file) {
      id
    }
  }
`;

export const CREATE_EXERCISE_VERSION_LINK = gql`
  mutation CreateExerciseVersionLink($exerciseVersionId: Int!) {
    createExerciseVersionLink(
      input: { exerciseVersionId: $exerciseVersionId }
    ) {
      id
    }
  }
`;

export const CREATE_EXERCISE_TEST_PLAN = gql`
  mutation CreateExerciseTestPlan(
    $exerciseVersionId: Int!
    $name: String!
    $file: Upload
  ) {
    createExerciseTestPlan(
      exerciseVersionId: $exerciseVersionId
      name: $name
      file: $file
    ) {
      id
    }
  }
`;

export const FIND_EXERCISE_TEST_PLAN = gql`
  query ExerciseTestPlan($exerciseVersionId: Int!) {
    findExerciseTestPlanByExerciseVersionId(
      exerciseVersionId: $exerciseVersionId
    ) {
      id
      name
      blobUri
    }
  }
`;

export const RUN_SCRATCH_JUDGE = gql`
  mutation RunScratchJudge($exerciseVersionId: Int!, $file: Upload) {
    runScratchJudge(exerciseVersionId: $exerciseVersionId, file: $file) {
      id
      message
      statuscode
    }
  }
`;

export const FIND_EXERCISE_TEST_RESULT = gql`
  query FindScratchJudgeResult($sessionId: String!) {
    findScratchJudgeResult(sessionId: $sessionId) {
      result
    }
  }
`;

export const DUPLICATE_EXERCISE = gql`
  mutation DuplicateScratchExercise($id: Int!) {
    duplicateScratchExercise(id: $id) {
      id
      title
      image
      type
    }
  }
`;
