import { Input } from '@ftrprf/tailwind-components';

import useFormatMessage from '../../../../../hooks/useFormatMessage';
import useOpenQuestion from '../../../hooks/useOpenQuestion';

import { SIDE_BARS } from '../../SideBars/SideBarContextProvider';
import QuestionBox from '../QuestionBox';

export function OpenQuestion({
  disabled,
  questionDisabled,
  questionId,
  setWarningDialogOpen,
}) {
  const t = useFormatMessage();

  const { question } = useOpenQuestion(questionId);

  if (!question) {
    return null;
  }

  return (
    <div className="border-t border-gray-300 p-2">
      <QuestionBox
        disabled={disabled}
        question={question}
        questionDisabled={questionDisabled}
        setWarningDialogOpen={setWarningDialogOpen}
        sideBarType={SIDE_BARS.OPEN_QUESTION_SIDEBAR}
        text={t('content-editor.question.open-question.info')}
        title={t('global.question')}
      >
        <Input
          className="bg-gray-200 p-8 border h-32"
          disabled
          placeholder={question.placeholder}
          type="multiline"
        />
      </QuestionBox>
    </div>
  );
}
