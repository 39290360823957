import { useContext } from 'react';

import { NotificationContext } from '@ftrprf/tailwind-components';

import { REMOVE_LESSON_CONTENT } from '../../api/content';

import useFormatMessage from '../useFormatMessage';

import useFieldMutation from './useFieldMutation';

export default function useRemoveContentMutation() {
  const t = useFormatMessage();
  const [removeLessonMutate] = useFieldMutation(
    REMOVE_LESSON_CONTENT,
    'LessonContent',
  );
  const { addNotification } = useContext(NotificationContext);

  const remove = ({ id, type }) =>
    removeLessonMutate({ id }).then(() => {
      addNotification({
        type: 'success',
        content: t(`content-overview.${type}.remove.success`),
      });
    });

  return remove;
}
