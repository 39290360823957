import titleSlidePreview from './images/title-slide.png';
import arrowList from './images/arrowlist.png';
import checkList from './images/checklist.png';
import iconHeader from './images/iconHeader.png';
import learnPage from './images/learnPage.png';

export const contentTemplates = (t) => [
  {
    title: t('content.templates.title-slide'),
    description: t('content.templates.title-slide-desc'),
    image: titleSlidePreview,
    html: `<div class='titleSlide'>
            <div>
              <h3 class='dotted-in-editor'></h3>
              <h2 class='dotted-in-editor'>
                ${t('content.templates.title-slide-title-placeholder')}
              </h2>
            </div>
            <div class="dotted-in-editor" style="text-align:center;">
              <img alt='' src="https://statstcodefeverpublic.blob.core.windows.net/public-content/images/773c76c54f6f498885e9177c4b664684.png">
            </div>
          </div>`,
  },
  {
    title: t('content.templates.icon-header'),
    description: t('content.templates.icon-header-desc'),
    image: iconHeader,
    html: `<div class='titleWithIconSlide'>
            <div class='dotted-in-editor imagePlaceholder float-right'>
              <img alt='' src="https://statstcodefeverpublic.blob.core.windows.net/public-content/images/773c76c54f6f498885e9177c4b664684.png" style="width:126px;">
            </div>
            <h2 class='dotted-in-editor'>
              ${t('content.templates.title-slide-title-placeholder')}
            </h2>
            <p>&nbsp;</p>
          </div>`,
  },
  {
    title: t('content.templates.learn-page'),
    description: t('content.templates.learn-page-desc'),
    image: learnPage,
    html: `
          <div>
            <h2 class='dotted-in-editor'>
              ${t('content.templates.title-slide-title-placeholder')}
            </h2>
            <div class='learnContainer'>
              <div class='mainContent dotted-in-editor'>
                <h3>
                  ${t('content.templates.title-slide-title-placeholder')}
                </h3>
                <div class='check-list'>
                  <ul>
                    <li>...</li>
                    <li>...</li>
                  </ul>
                </div>
                <p>&nbsp;</p>
                <div class='ftrprfLogo'/>
                </div>
              </div>
              <div class='dotted-in-editor imagePlaceholder' />
                <img alt='' src="https://statstcodefeverpublic.blob.core.windows.net/public-content/images/773c76c54f6f498885e9177c4b664684.png"> 
              </div>
            </div>
          `,
  },
  {
    title: t('content.templates.list-arrows.title'),
    description: t('content.templates.list-arrows.desc'),
    image: arrowList,
    html: `
      <div class='arrow-list'>
        <ul>
          <li>...</li>
          <li>...</li>
        </ul>
      </div>
      <p>&nbsp;</p>
    `,
  },
  {
    title: t('content.templates.list-checks.title'),
    description: t('content.templates.list-checks.desc'),
    image: checkList,
    html: `
      <div class='check-list'>
        <ul>
          <li>...</li>
          <li>...</li>
        </ul>
      </div>
      <p>&nbsp;</p>
    `,
  },
];
