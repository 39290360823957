import { useEffect, useState } from 'react';
import { FormattedRelativeTime } from 'react-intl';
import { selectUnit } from '@formatjs/intl-utils';

import useFormatMessage from '../hooks/useFormatMessage';

const shouldUpdateUnits = ['second', 'minute'];

export default function RelativeDate({ date }) {
  const t = useFormatMessage();
  const [, setTick] = useState(0);

  const { unit, value } = selectUnit(date);
  const shouldUpdate = shouldUpdateUnits.includes(unit);

  useEffect(() => {
    if (value !== 0) {
      return;
    }

    const interval = setInterval(() => {
      setTick((tick) => tick + 1);
    }, 5000);

    // eslint-disable-next-line consistent-return
    return () => {
      clearInterval(interval);
    };
  }, [value]);

  if (!date) {
    return null;
  }

  if (value === 0) {
    return <span>{t('last-saved-at.a_few_seconds_ago')}</span>;
  }

  return (
    <FormattedRelativeTime
      unit={unit}
      updateIntervalInSeconds={shouldUpdate ? 15 : undefined}
      value={value}
    />
  );
}
