const { useEffect, useState } = require('react');

export default function useDebounce(value, milliseconds) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const timeoutID = setTimeout(() => {
      setDebouncedValue(value);
    }, milliseconds);

    return () => {
      clearTimeout(timeoutID);
    };
  }, [value, milliseconds]);

  return debouncedValue;
}
