import { gql } from 'graphql-request';

export const FIND_LESSON_CONTENT = gql`
  query FindLessonContent($id: Int!) {
    findLessonContent(id: $id) {
      id
      title
      type
      language
      image
      programId
      clan
      level
      version
      published
      summary
      coach
      description
      archived
      modifiedOn
      groupLesson
      editorVersion
      groupRoles {
        id
        role
      }
      tags {
        id
        name
      }
      groupRoles {
        id
        role
      }
      endGoals {
        id
        key
        value
      }
      slides {
        id
        title
        content
        sequence
        viewModes
        motivation
        info
        background
        groupRoles {
          id
          role
        }
        questions {
          id
          type
          publishedAt
          ... on QuestionOpen {
            value
            scorePerCorrectAnswer
            placeholder
            solution
            questionAnswerOpen {
              id
              explanation
              optional
            }
          }
          ... on QuestionMultipleChoice {
            value
            scorePerCorrectAnswer
            subtractPerWrongAnswer
            maxSelectableAnswers
            shouldFindAllCorrect
            shuffleAnswers
            questionAnswersMultipleChoice {
              id
              value
              correct
              explanation
            }
          }
        }
        hints {
          id
          title
          content
        }
      }
    }
  }
`;
