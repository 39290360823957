import { VIEW_MODES } from '@ftrprf/tailwind-components';

import ContentEditorContainer from './ContentEditorContainer';

export default function ExamContainer(props) {
  const insertSlideBelow = ({
    content,
    insertSlideMutate,
    parameters = {},
    sequence,
  }) => {
    const newSlide = {
      lessonContentId: content.id,
      sequence,
      content: '',
      title: '',
      viewModes: [VIEW_MODES.CLASSICAL],
      groupRoles: [],
    };

    return insertSlideMutate({ ...newSlide, ...parameters });
  };

  return (
    <ContentEditorContainer
      hasViewModeSelector={false}
      insertSlideBelow={insertSlideBelow}
      {...props}
    />
  );
}
