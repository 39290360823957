import { FaQuestionCircle } from 'react-icons/fa';

import {
  SLIDE_BACKGROUNDS,
  SlideViewerSlideThumbnail,
  usePrismTheme,
} from '@ftrprf/tailwind-components';

import c from 'utils/functions/c';
import { useContext } from 'react';
import useFormatMessage from '../hooks/useFormatMessage';
import { UserContext } from '../providers/UserProvider';

export default function SlideThumbnail({
  className,
  fontSizeIndex = 0.2,
  isCk5,
  slide,
}) {
  const t = useFormatMessage();
  const { settings } = useContext(UserContext);
  usePrismTheme(settings?.slideViewer?.codeBlocksTheme);

  // Will be changed in backend, a slide should only have one question
  const containsQuestion = slide.questions?.length > 0;

  return (
    <div className={className}>
      {/* set height to 9/16 of the width (fix aspect ratio) */}
      <div
        className={c(
          'pb-9/16 w-full bg-white relative bg-cover bg-center slideThumbnail',
          slide.background &&
            `${
              SLIDE_BACKGROUNDS[slide.background]?.className || 'noBackground'
            }Thumbnail`,
        )}
      >
        <div className="absolute h-full w-full overflow-hidden p-1 pointer-events-none">
          {containsQuestion && (
            <FaQuestionCircle
              className="absolute text-accent-300 text-lg top-0 right-0 m-1 cursor-default"
              title={t('slide-thumbnail.contains-question')}
            />
          )}

          <SlideViewerSlideThumbnail
            currentSlide={slide}
            fontSizeIndex={fontSizeIndex}
            isCk5={isCk5}
          />
        </div>
      </div>
    </div>
  );
}
