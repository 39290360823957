import { Dropdown, InputGroup, Label } from '@ftrprf/tailwind-components';
import { Controller } from 'react-hook-form';
import useFormatMessage from 'hooks/useFormatMessage';
import LANGUAGES from 'utils/constants/languages';

export function LanguageDropdown({ content, control, errors }) {
  const t = useFormatMessage();

  return (
    <div>
      <InputGroup className="mt-3">
        <Label className="mt-3" htmlFor="contentMetaDialog_language">
          {t('content.language')}
        </Label>
        <Controller
          control={control}
          defaultValue={content.language}
          name="language"
          render={({ field: { onBlur, onChange, value } }) => (
            <Dropdown
              hasErrors={errors.language}
              inputId="contentMetaDialog_language"
              onBlur={onBlur}
              onChange={onChange}
              options={[
                LANGUAGES.EN,
                LANGUAGES.NL,
                LANGUAGES.FR,
                LANGUAGES.DE,
                LANGUAGES.ES,
              ]}
              value={value}
            />
          )}
          rules={{
            required: {
              value: true,
              message: t('validation.required'),
            },
          }}
          type="select"
        />
        {errors.language && (
          <span className="text-red-500 text-xs">
            {errors.language.message}
          </span>
        )}
      </InputGroup>
    </div>
  );
}
