/* eslint-disable no-unused-vars */
import { useCallback, useRef, useState } from 'react';
import { Form, Formik } from 'formik';
import { object, string } from 'yup';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogHeader,
  Label,
  ActionStyledAsButton,
} from '@ftrprf/tailwind-components';

import { FILE_EXTENSIONS } from 'utils/constants/fileTypes';
import { ContextMenu } from '../ContextMenu/ContextMenu';
import useFormatMessage from '../../hooks/useFormatMessage';

import { ReactComponent as ImageIcon } from '../../assets/vectors/image.svg';

import ContentListSelector from '../ContentListSelector/ContentListSelector';
import FormikUrl from '../Form/FormikUrl';
import FileUploadContainer from './FileUpload';

function Plugin({ editor, editorAPI }) {
  const t = useFormatMessage();

  const [isExternalShown, setIsExternalShown] = useState(false);
  const [currentPath, setCurrentPath] = useState('/');

  const insertImage = useCallback(
    (url) => {
      editorAPI.insertHtml(
        `<img 
          src="${url}" 
          width="100%" 
          alt="" 
          style="border-style: solid; border-width: 0;"
         />
        `,
      );
    },
    [editorAPI],
  );

  const imgRef = useRef(null);

  const menuTrigger = ({ _, toggle }) => <ImageIcon onClick={toggle} />;

  return (
    <>
      {isExternalShown && (
        <Dialog>
          <Formik
            initialValues={{
              url: '',
            }}
            onSubmit={({ url }, { setFieldError }) => {
              if (
                !imgRef.current?.naturalWidth ||
                !imgRef.current?.naturalHeight
              ) {
                setFieldError('url', 'validation.external_image_url');
              } else {
                setIsExternalShown(false);
                insertImage(url);
              }
            }}
            validateOnBlur={false}
            validateOnChange
            validationSchema={object({
              url: string().url('validation.url').required('validation.empty'),
            })}
          >
            {({
              errors,
              isSubmitting,
              isValid,
              setFieldError,
              setFieldValue,
              values,
            }) => (
              <Form>
                <DialogHeader>{t('editor.imageplugin.label')}</DialogHeader>
                <DialogContent>
                  <FormikUrl label={t('global.URL')} name="url" />
                  <Label>{t('editor.imageplugin.label-upload')}</Label>
                  {!errors.url && values.url ? (
                    <div className="w-full h-56">
                      <img
                        ref={imgRef}
                        alt="upload"
                        className="h-full m-auto max-w-full"
                        onError={() =>
                          setFieldError('url', 'validation.external_image_url')
                        }
                        src={values.url}
                      />
                    </div>
                  ) : (
                    <FileUploadContainer
                      accept="image"
                      maxSize={5242880}
                      name="file"
                      path={currentPath}
                      setFileUri={(uri) => {
                        setFieldValue('url', uri);
                      }}
                    />
                  )}
                </DialogContent>
                <DialogActions>
                  <ActionStyledAsButton
                    onClick={() => {
                      setIsExternalShown(false);
                      setFieldValue('url', null);
                    }}
                    variant="text"
                  >
                    {t('global.cancel')}
                  </ActionStyledAsButton>
                  <ActionStyledAsButton
                    disabled={isSubmitting || !isValid}
                    type="submit"
                  >
                    {t('editor.imageplugin.label')}
                  </ActionStyledAsButton>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </Dialog>
      )}
      <ContextMenu editor={editor} trigger={menuTrigger}>
        {({ close }) => (
          <div className="bg-white h-64 w-64 border-gray-300 border">
            <ContentListSelector
              allowedTypes={FILE_EXTENSIONS.IMAGE.extensions}
              onExternalClick={() => {
                close();
                setIsExternalShown(true);
              }}
              onFileClick={(url) => {
                insertImage(url);
                close();
              }}
              placeholder={t('content-selector.url')}
              setCurrentPath={setCurrentPath}
            />
          </div>
        )}
      </ContextMenu>
    </>
  );
}

export function ImagePlugin({ ...props }) {
  const t = useFormatMessage();

  // suppressed because the plugin is initiated by ckeditor
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Plugin label={t('editor.imageplugin.label')} {...props} />;
}
