import FileManagerContextProvider from './FileManagerContextProvider';
import EmbeddedFileManager from './EmbeddedFileManager';

export default function EmbeddedFileManagerContainer({
  contentFilter,
  onFileClick,
}) {
  return (
    <FileManagerContextProvider isEmbedded>
      <EmbeddedFileManager
        contentFilter={contentFilter}
        onFileClick={onFileClick}
      />
    </FileManagerContextProvider>
  );
}
