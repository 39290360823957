import { useContext } from 'react';

import { Loader, ActionStyledAsButton } from '@ftrprf/tailwind-components';

import useFormatMessage from 'hooks/useFormatMessage';

import c from 'utils/functions/c';
import { FileManagerContext } from '../FileManagerContextProvider';

import FolderDetailItem from './FolderDetailItem';

import NoResults from '../icons/NoResults';

export default function FolderDetail({ contentFilter = (c) => c, isEmbedded }) {
  const t = useFormatMessage();
  const {
    activeFolder,
    currentFolderContent,
    currentFolderContentLoading,
    isSearching,
    navigateToFolder,
    searchLoading,
    searchResults,
  } = useContext(FileManagerContext);

  if (currentFolderContentLoading || searchLoading) {
    return (
      <div className="w-5 m-2">
        <Loader />
      </div>
    );
  }

  if (currentFolderContent?.length === 0) {
    return (
      <p
        className={c(
          'm-2',
          activeFolder?.path !== '/' && !isSearching && 'mx-7',
        )}
      >
        {t('file.manager.empty-folder')}
      </p>
    );
  }

  if (isSearching && searchResults?.length === 0) {
    return (
      <div className="m-2 grow flex flex-col items-center justify-center">
        <p className="uppercase font-medium text-2xl text-gray-500 mb-4">
          {t('file.manager.search-no-results')}...
        </p>
        <NoResults className="w-56 mb-8" />
        <p>
          {isSearching && (
            <div className="m-2">
              <div className="flex flex-col w-fit justify-center items-center">
                {t('file.manager.search-no-results-in')}
                &quot;{activeFolder?.name || t('header.files')}&quot;.
                <br />
                {!activeFolder ||
                  (activeFolder?.id !== null && (
                    <ActionStyledAsButton
                      className="mx-auto my-4"
                      onClick={() => navigateToFolder(null, '/')}
                      variant="text"
                    >
                      {t('file.manager.search-in-root')}
                    </ActionStyledAsButton>
                  ))}
              </div>
            </div>
          )}
        </p>
      </div>
    );
  }

  return (
    <table className="w-full table-auto overflow-auto">
      <thead
        className={c(
          'sticky bg-gray-100 h-10',
          isEmbedded ? 'top-0' : 'top-10',
        )}
      >
        <tr>
          <th scope="row">
            <span className="sr-only">select content</span>
          </th>

          <th scope="row">
            <span className="sr-only">content icon</span>
          </th>
          <th className={c('text-start', !isEmbedded && 'sr-only')}>
            {t('global.name')}
          </th>
          {!isEmbedded && (
            <>
              <th className="text-start border-l-[1px] pl-4">
                {!isSearching
                  ? t('file.manager.modifiedOn')
                  : t('file.manager.path')}
              </th>
              <th className="text-start border-l-[1px] pl-4">
                {t('file.manager.modifiedBy')}
              </th>
              <th className="pl-4 border-l-[1px] text-start">
                {t('file.manager.usage')}
              </th>
            </>
          )}
        </tr>
      </thead>
      <tbody>
        {(isSearching ? searchResults : currentFolderContent)
          ?.filter(contentFilter)
          ?.map((content) => (
            <FolderDetailItem
              key={content.path + content.extension}
              content={content}
              isEmbedded={isEmbedded}
            />
          ))}
      </tbody>
    </table>
  );
}
