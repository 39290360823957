import { useLocation } from 'react-router-dom';

export default function usePathStack(root = null, rootDisplayName = null) {
  const location = useLocation();
  const [, ...path] = location.pathname
    .substring(location.pathname.indexOf(root))
    .replace('%20', ' ')
    .split('/');

  const relativePath = `${location.pathname.substring(
    location.pathname.indexOf(root) + (root ? root.length : 0),
  )}/`;

  if (rootDisplayName) {
    path.unshift(rootDisplayName);
  }

  const last = path.pop();

  return { path, last, relativePath: relativePath.replaceAll('%20', ' ') };
}
