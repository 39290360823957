import { createContext, useMemo } from 'react';
import { useRecoilState } from 'recoil';

import { sideBarAtom } from '../../utils/atom';

// eslint-disable-next-line import/no-cycle
import HintsSideBar from './HintsSideBar/HintsSideBar';

// eslint-disable-next-line import/no-cycle
import ModalSideBar from './ModalSideBar';
// eslint-disable-next-line import/no-cycle
import MultipleChoiceQuestionSideBar from './MultipleChoiceQuestionSideBar';
import OpenQuestionSideBar from './OpenQuestionSideBar';
// eslint-disable-next-line import/no-cycle
import QuestionAnswerMultipleChoiceSideBar from './QuestionAnswerMultipleChoiceSideBar';
// eslint-disable-next-line import/no-cycle
import SlideSideBar from './SlideSideBar';
// eslint-disable-next-line import/no-cycle
import TeacherInfoSideBar from './TeacherInfoSideBar';
import { useCk5Build } from '../../../../hooks/useCk5Build';

export const SideBarContext = createContext({});

export const sideBars = {
  DEFAULT: SlideSideBar,
  HINTS_SIDEBAR: HintsSideBar,
  OPEN_QUESTION_SIDEBAR: OpenQuestionSideBar,
  MULTIPLE_CHOICE_QUESTION_SIDEBAR: MultipleChoiceQuestionSideBar,
  QUESTION_ANSWER_MULTIPLE_CHOICE_SIDEBAR: QuestionAnswerMultipleChoiceSideBar,
  TEACHER_INFO_SIDEBAR: TeacherInfoSideBar,
  MODAL_SIDEBAR: ModalSideBar,
  EMPTY: () => <div />,
};

export const SIDE_BARS = Object.fromEntries(
  Object.keys(sideBars).map((s) => [s, s]),
);

export default function SideBarContextProvider({ children, isCk5 }) {
  const [currentSideBar, setSideBar] = useRecoilState(sideBarAtom);
  const isCk5Loaded = useCk5Build({ enabled: isCk5 });

  const context = useMemo(
    () => ({ isCk5, isCk5Loaded, currentSideBar, setSideBar }),
    [currentSideBar, isCk5, isCk5Loaded, setSideBar],
  );

  return (
    <SideBarContext.Provider value={context}>
      {children}
    </SideBarContext.Provider>
  );
}
