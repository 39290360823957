import { GraphQLClient } from 'graphql-request';

import { getToken } from 'api';

import msalInstance from '../msalInstance';

async function tokenMiddleware(request) {
  const token = await getToken(msalInstance);

  const headers = {
    ...request.headers,
    authorization: `Bearer ${token}`,
  };

  return {
    ...request,
    headers,
  };
}

export const plannerServiceQueryClient = new GraphQLClient(
  process.env.REACT_APP_PLANNER_SERVICE_URL,
  {
    requestMiddleware: tokenMiddleware,
  },
);

export const studioServiceQueryClient = new GraphQLClient(
  process.env.REACT_APP_STUDIO_SERVICE_URL,
  {
    requestMiddleware: tokenMiddleware,
  },
);
