import * as Sentry from '@sentry/react';

export default function initSentry() {
  if (!process.env.REACT_APP_SENTRY_DSN) {
    return;
  }

  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: `studio-frontend@${process.env.REACT_APP_VERSION}`,
    environment: window.location.hostname,
  });
}
