import {
  Children,
  cloneElement,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';

export function ContextMenu({
  children,
  className = '',
  trigger: triggerElements,
}) {
  const [node, setNode] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  const [virtualReference, setVirtualReference] = useState(null);
  const [popperElement, setPopperElement] = useState(null);

  const { attributes, styles } = usePopper(virtualReference, popperElement);
  const contextMenuRef = useCallback((newNode) => setNode(newNode), []);

  function handleEvent(event) {
    if (event.type === 'scroll' || !node.contains(event.target)) {
      setIsOpen(false);

      document.removeEventListener('mousedown', handleEvent);
      document.removeEventListener('scroll', handleEvent);
    }
  }

  useEffect(() => {
    if (node) {
      const { x, y } = mousePosition;

      setVirtualReference({
        getBoundingClientRect() {
          return {
            top: y,
            left: x,
            bottom: y,
            right: x,
            width: node.getBoundingClientRect().width,
            height: 0,
          };
        },
      });

      document.addEventListener('mousedown', handleEvent);
      document.addEventListener('scroll', handleEvent);
    }

    return () => {
      document.removeEventListener('mousedown', handleEvent);
      document.removeEventListener('scroll', handleEvent);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mousePosition, node]);

  const close = () => {
    setIsOpen(false);
    setNode(null);
  };

  const open = (event) => {
    event.preventDefault();
    event.stopPropagation();

    setIsOpen(true);
    setMousePosition({ x: event.clientX, y: event.clientY });
  };

  const toggle = (event) => {
    if (isOpen) {
      close(event);
    } else {
      open(event);
    }
  };

  let triggerElement =
    triggerElements instanceof Function
      ? triggerElements({ isOpen, toggle, close })
      : triggerElements;

  triggerElement = cloneElement(Children.only(triggerElement), {
    onContextMenu: open,
  });

  return (
    <>
      {triggerElement}

      {isOpen &&
        createPortal(
          <div
            style={{
              position: 'absolute',
              left: 0,
              top: 0,
              bottom: 0,
              right: 0,
              zIndex: 10010,
            }}
          >
            <div
              ref={setPopperElement}
              style={{
                ...styles.popper,
                zIndex: 10011,
                visibility: styles.popper.transform ? 'visible' : 'hidden',
              }}
              {...attributes.popper}
            >
              <div
                ref={contextMenuRef}
                className={className}
                onClick={(e) => e.stopPropagation()}
              >
                {children({ close, open })}
              </div>
            </div>
          </div>,
          document.body,
        )}
    </>
  );
}
