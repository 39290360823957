import { MinusIcon, PlusIcon } from '@heroicons/react/24/solid';

import { Dropdown } from '@ftrprf/tailwind-components';
import { useEffect } from 'react';
import c from 'utils/functions/c';

const scales = [
  { value: 0.25, label: '25%' },
  { value: 0.33, label: '33%' },
  { value: 0.5, label: '50%' },
  { value: 0.75, label: '75%' },
  { value: 1, label: '100%' },
  { value: 1.25, label: '125%' },
  { value: 1.5, label: '150%' },
];

export function ScaleButtons({
  isEditor,
  isFullScreen,
  scale,
  setScale,
  setScaleProperties,
}) {
  useEffect(() => {
    setScaleProperties();
  }, [scale, setScaleProperties]);

  return (
    <aside
      className={c(
        'isolate inline-flex rounded-md shadow-sm z-20',
        isFullScreen ? 'fixed' : 'absolute',
        isEditor ? 'bottom-14 right-4' : 'bottom-10 right-4',
      )}
    >
      <button
        className="relative inline-flex items-center rounded-l-md bg-white px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
        onClick={() => {
          setScale((prev) => {
            const prevIndex = scales.findIndex((scale) => scale.value === prev);
            const newIndex = prevIndex - 1;
            if (newIndex === -1) return prev;
            return scales[newIndex].value;
          });
        }}
        type="button"
      >
        <span className="sr-only">Scale down</span>
        <MinusIcon className="w-3" />
      </button>
      <Dropdown
        description="Scale"
        descriptionClassName="sr-only"
        inputId="ScaleButtons"
        menuPlacement="top"
        onChange={setScale}
        options={scales}
        styles={{
          control: (base) => ({
            ...base,
            borderLeft: 0,
            borderRight: 0,
            borderRadius: 0,
            boxShadow: 'none',
          }),
        }}
        value={scale}
      />
      <button
        className="relative -ml-px inline-flex items-center rounded-r-md bg-white px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 "
        onClick={() => {
          setScale((prev) => {
            const prevIndex = scales.findIndex((scale) => scale.value === prev);
            const newIndex = prevIndex + 1;
            if (newIndex === scales.length) return prev;
            return scales[newIndex].value;
          });
        }}
        type="button"
      >
        <span className="sr-only">Scale up</span>
        <PlusIcon className="w-3" />
      </button>
    </aside>
  );
}
