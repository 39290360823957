const headings = [
  { model: 'paragraph', title: 'Paragraph', class: 'ck5p' },
  {
    model: 'heading2',
    view: {
      name: 'h2',
      classes: 'ck5h2',
    },
    title: 'Heading 1',
    class: 'ck5h2',
  },
  {
    model: 'heading3',
    view: {
      name: 'h3',
      classes: 'ck5h3',
    },
    title: 'Heading 2',
    class: 'ck5h3',
  },
];

export default headings;
