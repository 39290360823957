import { useContext } from 'react';

import {
  ActionStyledAsButton,
  Input,
  InputGroup,
  Label,
} from '@ftrprf/tailwind-components';

import Editor from 'components/Editor/Editor';
import Collapsable from 'components/Collapsable';

import useFormatMessage from 'hooks/useFormatMessage';
import { TrashIcon } from '@heroicons/react/24/outline';
import useHint from '../../../hooks/useHint';
// eslint-disable-next-line import/no-cycle
import { SideBarContext } from '../SideBarContextProvider';
import { Ck5SlideEditorContainer } from '../../Ck5Editor/Ck5SlideEditorContainer';

export default function HintsSideBarItem({
  disabled,
  id,
  index,
  refetch,
  removeHint,
  showFirst,
}) {
  const t = useFormatMessage();
  const { isCk5 } = useContext(SideBarContext);

  const { hint, update } = useHint(id);

  const updateLocalContent = (content) => {
    update({ content: content || '' });
    refetch();
  };

  if (!hint) {
    return null;
  }

  return (
    <div className="flex flex-col border-b border-gray-300">
      <Collapsable
        className="pr-3"
        initialShown={showFirst && index === 0}
        trigger={
          <h3 className="px-3 py-3 flex-grow">
            {hint.title
              ? hint.title
              : `${t('hints-sidebar.item.hint_x')} ${index + 1}`}
          </h3>
        }
      >
        <div className="border-t border-gray-300 bg-gray-100 p-4">
          <InputGroup>
            <Label>{t('hints-sidebar.item.title')}</Label>
            <Input
              disabled={disabled}
              onChange={(e) => {
                update({ title: e.target.value });
                refetch();
              }}
              type="text"
              value={hint.title || ''}
            />
          </InputGroup>

          <InputGroup>
            <Label>{t('hints-sidebar.item.content')}</Label>

            {isCk5 ? (
              <Ck5SlideEditorContainer
                disabled={disabled}
                id={`sidebar-hints-content-${id}`}
                isInSideBar
                isLoaded
                onChange={updateLocalContent}
                value={hint.content || ''}
              />
            ) : (
              <Editor
                disabled={disabled}
                onChange={updateLocalContent}
                value={hint.content || ''}
              />
            )}
          </InputGroup>
          <div className="flex justify-end">
            <ActionStyledAsButton
              disabled={disabled}
              iconBefore={TrashIcon}
              onClick={() => removeHint(hint.id)}
              secondary
            >
              {t('global.delete')}
            </ActionStyledAsButton>
          </div>
        </div>
      </Collapsable>
    </div>
  );
}
