import { useContext } from 'react';

import useContentNavigationTree from 'hooks/graphql/file-manager/queries/useContentNavigationTree';
import useFormatMessage from 'hooks/useFormatMessage';

import useContentNavigationTreeFromPath from 'hooks/graphql/file-manager/queries/useContentNavigationTreeFromPath';
import c from 'utils/functions/c';
import { FileManagerContext } from '../FileManagerContextProvider';

import TreeFolderCollapsable from './TreeFolderCollapsable';

export default function FileManagerTree({ className }) {
  const t = useFormatMessage();
  const { activePath } = useContext(FileManagerContext);

  const { data: foldersFromPath } = useContentNavigationTreeFromPath(
    activePath,
    activePath?.length > 2,
  );
  const { data: rootFolders } = useContentNavigationTree(activePath === '/');

  return (
    <section
      className={c(
        'min-w-[8rem] w-1/5 pt-2 bg-white border-r border-t border-gray-200 pr-2 overflow-auto mr-2 scrollbar-hide',
        className,
      )}
    >
      <h2 className="sr-only">{t('file.manager.tree-title')}</h2>
      <TreeFolderCollapsable
        folder={{
          id: null,
          path: '/',
          name: t('header.files'),
          children: foldersFromPath || rootFolders,
        }}
        isOpen
      />
    </section>
  );
}
