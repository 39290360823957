import Collapsable from '../../../../components/Collapsable';

import useFormatMessage from '../../../../hooks/useFormatMessage';

// eslint-disable-next-line import/no-cycle
import { GeneralSideBar } from './GeneralSideBar';
import SideBarContent from './SideBarContent';
import SteamsSideBar from './SteamsSideBar';

function Divider() {
  return <hr className="w-11/12 opacity-30 self-center mt-3" />;
}

function SlideBarCollapsable({ children, initialShown, title }) {
  return (
    <Collapsable
      className="pr-3 pt-3 flex-start"
      initialShown={initialShown}
      trigger={<div className=" flex-grow font-medium">{title}</div>}
    >
      {children}
    </Collapsable>
  );
}

export default function SlideSideBar({ disabled, slideId }) {
  const t = useFormatMessage();

  return (
    <SideBarContent className="p-3 " withGoBack={false}>
      <SlideBarCollapsable initialShown title={t('slide-sidebar.general')}>
        <GeneralSideBar disabled={disabled} slideId={slideId} />
      </SlideBarCollapsable>
      <Divider />
      <SlideBarCollapsable initialShown title={t('slide-sidebar.steams')}>
        <SteamsSideBar
          dataTest="studio-steams"
          disabled={disabled}
          slideId={slideId}
        />
      </SlideBarCollapsable>
    </SideBarContent>
  );
}
