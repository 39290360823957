import { useCallback, useContext } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { PresentationChartBarIcon } from '@heroicons/react/24/outline';

import {
  Header as TWHeader,
  HIDE_IN_STUDIO,
} from '@ftrprf/tailwind-components';

import URLS, { LOGOUT } from 'utils/constants/urls';
import { UserContext } from '../providers/UserProvider';

import useFormatMessage from '../hooks/useFormatMessage';

export function FTRPRFLink({ children, className, to }) {
  const href = `${process.env.REACT_APP_NEXT_URL}${to}`;

  return (
    <a className={className} href={href} onClick={(e) => e.stopPropagation()}>
      {children}
    </a>
  );
}

export default function Header() {
  const t = useFormatMessage();
  const user = useContext(UserContext);
  const { isImpersonating } = user;

  const { pathname } = useLocation();

  const profileDropdownLinks = useCallback(() => {
    const linksArray = [];

    if (isImpersonating) {
      linksArray.push(
        {
          label: t('header.back_to_FTRPRF'),
          to: process.env.REACT_APP_NEXT_URL,
        },
        {
          label: t('global.unimpersonate'),
          to: process.env.REACT_APP_NEXT_URL + LOGOUT,
        },
      );
    } else {
      linksArray.push({
        label: t('logout'),
        to: LOGOUT,
      });
    }

    return linksArray;
  }, [isImpersonating, t]);

  return (
    <TWHeader
      choseActivePeriod
      hasActivePeriod
      icons={[
        {
          icon: <PresentationChartBarIcon />,
          label: t('header.back_to_FTRPRF'),
          showLabel: true,
          to: process.env.REACT_APP_NEXT_URL,
        },
      ]}
      organizationName="STUDIO"
      profileLinks={profileDropdownLinks()}
      user={{
        ...user,
        organization: { name: 'FTRPRF' },
        avatar: HIDE_IN_STUDIO,
      }}
    >
      <NavLink pathname={pathname} to={URLS.LESSONS}>
        {t('header.lessons')}
      </NavLink>

      <NavLink pathname={pathname} to={URLS.LESSON_TEMPLATES}>
        {t('header.templates')}
      </NavLink>

      <NavLink pathname={pathname} to={URLS.EXAMS}>
        {t('header.exams')}
      </NavLink>

      <NavLink pathname={pathname} to={URLS.EXERCISES}>
        {t('header.exercises')}
      </NavLink>

      <NavLink pathname={pathname} to={URLS.FILES}>
        {t('header.files')}
      </NavLink>
    </TWHeader>
  );
}
