// import * as Sentry from '@sentry/react';
import { loginRequest } from 'authConfig';

import { AUTHORIZATION_TOKEN } from 'utils/constants/localStorage';

import msalInstance from '../msalInstance';
import { clearCacheAndCookies } from '../utils/functions/clearCacheAndCookies';

const statusCodes = {
  FORBIDDEN: 403,
  NO_CONTENT: 204,
  UNAUTHORIZED: 401,
};

export const getToken = () => {
  const account = msalInstance.getActiveAccount();

  if (!account) {
    return new Promise((resolve) => {
      resolve(null);
    });
  }

  return new Promise((resolve) => {
    msalInstance
      // When this method is called, the library first checks the cache in browser storage to see
      // if a valid token exists and returns it. When no valid token is in the cache, it attempts
      // to use its refresh token to get the token. If the refresh token's 24-hour lifetime has expired,
      // MSAL.js will open a hidden iframe to silently request a new authorization code,
      // which it will exchange for a new, valid refresh token.
      .acquireTokenSilent({ account, ...loginRequest })
      .then(({ idToken }) => {
        resolve(idToken);
      })
      .catch(() => {
        // The silent token requests to Azure AD might fail for reasons like a password change or updated
        // conditional access policies. More often, failures are due to the refresh token's 24-hour lifetime
        // expiring and the browser blocking third party cookies, which prevents the use of hidden
        // iframes to continue authenticating the user. Our in our case Smartschool access token expired.
        // Sentry.captureMessage('Failed acquireTokenSilent', {
        //   extra: { account, error },
        // });

        try {
          msalInstance.logoutRedirect().catch(() => {
            // Sentry.captureMessage('Manual Logout', { extra: { error } });

            clearCacheAndCookies();

            // Reload the page
            window.location.reload();
          });
        } catch (error) {
          // Sentry.captureMessage('Manual Logout', { extra: { error } });

          clearCacheAndCookies();

          // Reload the page
          window.location.reload();
        }

        return undefined;
      });
  });
};

const authorizedRequest = (endpoint, options = {}) => {
  const token = window.localStorage[AUTHORIZATION_TOKEN];
  const authorizationHeader = token ? { Authorization: `Bearer ${token}` } : {};
  const hasContentTypeHeader = options.headers?.['Content-Type'] !== null;
  const hasAcceptHeader = options.headers?.accept !== null;

  const {
    'Content-Type': contentType,
    accept,
    ...otherHeaders
  } = options.headers || {};

  const contentTypeHeader = hasContentTypeHeader
    ? {
        'Content-Type': contentType || 'application/json;charset=UTF-8',
      }
    : {};

  const acceptHeader = hasAcceptHeader
    ? {
        accept: accept || 'application/json, text/plain, */*',
      }
    : {};

  return fetch(endpoint, {
    ...options,
    headers: {
      ...otherHeaders,
      ...contentTypeHeader,
      ...acceptHeader,
      ...authorizationHeader,
    },
  }).then((res) => {
    if (res.status === statusCodes.UNAUTHORIZED) {
      window.location.assign(`${process.env.REACT_APP_EDU_URL}`);
    }

    if (
      res.status === statusCodes.FORBIDDEN &&
      endpoint.endsWith('api/users/me')
    ) {
      window.location.assign(`${process.env.REACT_APP_EDU_URL}`);
    }

    if (!res.ok) {
      throw new Error(res);
    }

    const responseToken = res.headers.get('authorization');
    const contentType = res.headers.get('content-type');

    if (responseToken) {
      localStorage.setItem(AUTHORIZATION_TOKEN, responseToken);
    }

    if (
      res.status === statusCodes.NO_CONTENT ||
      res.headers.get('content-length') === '0'
    ) {
      return null;
    }

    if (contentType.startsWith('text/plain')) {
      return res;
    }

    return res.json();
  });
};

export const get = (endpoint, options) =>
  authorizedRequest(endpoint, {
    method: 'GET',
    ...options,
  });

export const post = (endpoint, body) =>
  authorizedRequest(endpoint, {
    method: 'POST',
    headers: body instanceof FormData ? { 'Content-Type': null } : {},
    body: body instanceof FormData ? body : JSON.stringify(body),
  });

export const put = (endpoint, body) =>
  authorizedRequest(endpoint, {
    method: 'PUT',
    headers: body instanceof FormData ? { 'Content-Type': null } : {},
    body: body instanceof FormData ? body : JSON.stringify(body),
  });

export const remove = (endpoint) =>
  authorizedRequest(endpoint, {
    method: 'DELETE',
  });
