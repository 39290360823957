import {externalDe} from './external';
import {generate} from './generate';
import {german} from './internal.de';


const generated = {
  ...generate(
    [
      {key: 'EXAM', name: 'exam', plural: 'exams'},
      {key: 'LESSON', name: 'lesson', plural: 'lessons'},
      {
        key: 'LESSON_TEMPLATE',
        name: 'template',
        plural: 'templates'
      },
    ],
    {
      'content-overview.{{ key }}.title': '{{ name | ucase }} overview',
      'content-overview.{{ key }}.new_content': 'New {{ name }}',
      'content-overview.{{ key }}.empty': 'As of yet, no {{ plural }} can be found',
      'content-overview.{{ key }}.copy.success': 'The {{ name }} has been successfully copied',
      'content-overview.{{ key }}.new-content.success': 'A new {{ name }} has been successfully added',
      'content-overview.{{ key }}.publish.success': 'The {{ name }} has been successfully published',
      'content-overview.{{ key }}.archive.success': 'The {{ name }} is being archived. This may take a few seconds.',
      'content-overview.{{ key }}.unarchive.success': 'The {{ name }} is being unarchived. This may take a few seconds.',
      'content-overview.{{ key }}.remove.success': 'The {{ name }} has been successfully removed',
      'content-overview.{{ key }}.meta.success': 'The metadata of the {{ name }} is updated.',
      'content-meta-data-dialog.{{ key }}.new_title': 'New {{ name }}',
      'content-meta-data-dialog.{{ key }}.update_title': 'Edit {{ name }}',
    },
  ),
  ...generate(
    [
      {key: 'EXAM', name: 'exam'},
      {key: 'LESSON', name: 'lesson'},
      {key: 'LESSON_TEMPLATE', name: 'template'},
    ],
    {
      'content-editor.{{ key }}.publish-confirm.title': 'Publish {{ name }}',
      'content-editor.{{ key }}.publish-confirm.text1': 'Are you sure you want to publish this {{ name }}?',
      'content-editor.{{ key }}.publish-confirm.text2': `You won't be able to change this lesson unless you create a new version.`,
    },
  ),
}

export const appDe = {
  ...german,
  ...generated,
  ...externalDe,
}
