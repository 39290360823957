import { Children, cloneElement, useMemo } from 'react';
import { IconContext } from 'react-icons';

import throwIfDevelopment from 'utils/functions/throwIfDevelopment';

export default function StyledIcon({
  children,
  className,
  icon: Icon,
  style,
  ...props
}) {
  throwIfDevelopment(
    children && Icon,
    `StyledIcon cannot render both an icon passed in props and children. 
  Choose one of the two options to render an icon`,
  );

  const classNameObject = useMemo(() => ({ className }), [className]);

  return (
    <IconContext.Provider value={classNameObject}>
      {children ? (
        Children.map(children, (child) =>
          cloneElement(child, {
            ...props,
          }),
        )
      ) : (
        <Icon style={style} {...props} />
      )}
    </IconContext.Provider>
  );
}
