import { Children, cloneElement } from 'react';

import { ContextMenu } from './ContextMenu';
import c from '../../utils/functions/c';

export function ListContextMenu({ children, isWide = false, ...props }) {
  return (
    <ContextMenu
      {...props}
      className={c('ListContextMenu', isWide ? 'w-56' : 'w-48')}
    >
      {({ close }) =>
        Children.map(
          children,
          (child) =>
            child &&
            cloneElement(child, {
              onClick: () => {
                if (!child.props.preventClose) {
                  close();
                }
                if (child.props.onClick) {
                  child.props.onClick();
                }
              },
            }),
        )
      }
    </ContextMenu>
  );
}
