export const ROOT = '/';
export const MANUAL_LOGIN = '/manual-login';
export const CALLBACK = '/callback';
export const LOGOUT = '/logout';
export const CONTENT = '/content';
export const CONTENT_PARAMS = `/content/:lessonContentId/:viewMode/:slideId?`;
export const EXAM = '/exam';
export const LESSON = '/lesson';
export const LESSONS = '/lessons';
export const LESSONS_NEW = '/lessons/new';
export const LESSON_TEMPLATES = '/templates';
export const LESSON_TEMPLATE = '/template';
export const EXAMS = '/exams';
export const FILES = '/files';
export const FILE_MANAGER = '/file-manager';
export const ADMIN = '/admin';
export const EXERCISES = '/exercises';
export const EXERCISES_NEW = '/exercises/new';
export const EXERCISE = '/exercise';
export const EXERCISE_ID = '/exercise/:id';
export const EXERCISE_ID_VERSION_ID = '/exercise/:id/version/:versionId';
export const EXERCISE_VERSION_ID = '/exercise/version/:versionId';
export const COPY = '/copy/:id';
export const ARCHIVE = '/archive/:id';
export const UNARCHIVE = '/unarchive/:id';
export const IMPERSONATE = '/impersonate';
export const IMPERSONATE_ID = '/impersonate/:id';

const URLS = {
  ROOT,
  MANUAL_LOGIN,
  CALLBACK,
  LOGOUT,
  CONTENT,
  CONTENT_PARAMS,
  EXAM,
  LESSON,
  LESSONS,
  LESSONS_NEW,
  LESSON_TEMPLATES,
  LESSON_TEMPLATE,
  EXAMS,
  FILES,
  FILE_MANAGER,
  ADMIN,
  EXERCISES,
  EXERCISES_NEW,
  EXERCISE,
  EXERCISE_ID,
  EXERCISE_ID_VERSION_ID,
  EXERCISE_VERSION_ID,
  COPY,
  ARCHIVE,
  UNARCHIVE,
  IMPERSONATE,
  IMPERSONATE_ID,
};

export default URLS;
