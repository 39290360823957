import {
  ActionStyledAsButton,
  InputGroup,
  Label,
} from '@ftrprf/tailwind-components';
import { ContextMenu } from 'components/ContextMenu/ContextMenu';
import ContentListSelector from 'components/ContentListSelector/ContentListSelector';
import useFormatMessage from 'hooks/useFormatMessage';
import { FILE_EXTENSIONS } from 'utils/constants/fileTypes';
import { FaEdit, FaPlus, FaTimes } from 'react-icons/fa';
import { useState } from 'react';

export function ImageFields({
  imageUrl,
  setImageUrl,
  setOpenExternalUrlDialog,
}) {
  const t = useFormatMessage();
  const [currentContentPath, setCurrentContentPath] = useState('/');

  // eslint-disable-next-line no-unused-vars
  const menuTrigger = ({ _, toggle }) =>
    imageUrl ? (
      <div className="relative w-full mt-2">
        <div className="flex mt-2 absolute top-0 right-0 mr-2 gap-2">
          <ActionStyledAsButton
            iconBefore={FaEdit}
            iconMargin="mr-0"
            onClick={toggle}
          />
          <ActionStyledAsButton
            iconBefore={FaTimes}
            iconMargin="mr-0"
            onClick={() => setImageUrl(null)}
            variant="white"
          />
        </div>
        <img
          alt={t('content-card.image_alt')}
          className="w-full"
          src={imageUrl}
        />
      </div>
    ) : (
      <ActionStyledAsButton iconBefore={FaPlus} onClick={toggle} secondary>
        {t('content-selector.image')}
      </ActionStyledAsButton>
    );

  return (
    <InputGroup className="mt-3">
      <Label>{t('content.image')}</Label>
      <ContextMenu trigger={menuTrigger}>
        {({ close }) => (
          <div className="w-64 h-64 bg-white border border-gray">
            <ContentListSelector
              allowedTypes={FILE_EXTENSIONS.IMAGE.extensions}
              currentPath={currentContentPath}
              onExternalClick={() => {
                setOpenExternalUrlDialog(true);
                close();
              }}
              onFileClick={(url) => {
                setImageUrl(url);
                close();
              }}
              placeholder={t('content-selector.url')}
              setCurrentPath={setCurrentContentPath}
            />
          </div>
        )}
      </ContextMenu>
    </InputGroup>
  );
}
