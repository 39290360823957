import { USER_INFO } from 'api/plannerService/common/queries/userInfo';
import { useQuery } from 'react-query';
import { plannerServiceQueryClient } from '../../../../../utils/graphqlQueryClient';

export default function useUserInfo(initialToken) {
  const response = useQuery(
    'userInfoPlanner',
    () => plannerServiceQueryClient.request(USER_INFO, {}),
    {
      enabled: !!initialToken,
    },
  );

  return {
    ...response,
    data: response?.data?.userInfo,
  };
}
