import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import useFileUpload from 'hooks/graphql/file-manager/mutations/useFileUpload';
import useContentNavigationTree from 'hooks/graphql/file-manager/queries/useContentNavigationTree';
import useFileSearch from 'hooks/graphql/file-manager/queries/useFileSearch';
import usePathStack from 'hooks/usePathStack';
import { FILES } from 'utils/constants/urls';
import { fileNameWithExtension } from './utils/fileNameWithExtension';

export const FileManagerContext = createContext({});

export default function FileManagerContextProvider({ children, isEmbedded }) {
  const { relativePath } = usePathStack(FILES.substring(1));

  const [activeFolder, setActiveFolder] = useState(
    isEmbedded ? '/' : undefined,
  );
  const [isSearching, setIsSearching] = useState(false);
  const [activePath, setActivePath] = useState();
  const [selectedContent, setSelectedContent] = useState([]);
  const [fileTreeKey, setFileTreeKey] = useState(new Date());
  const [dialog, setDialog] = useState();

  const {
    data: currentFolderContent,
    fetch: refetchFolderContent,
    loading: currentFolderContentLoading,
  } = useContentNavigationTree(!!activeFolder, activeFolder?.id, 1, false);

  const {
    data: searchResults,
    loading: searchLoading,
    search: searchContent,
  } = useFileSearch();

  const { loading: isFileUploadLoading, uploadFiles: upload } = useFileUpload();

  const refetchCurrentFolderContent = useCallback(
    (folderId = activeFolder?.id) => {
      refetchFolderContent({
        variables: {
          startFolderId: folderId || null,
          levels: 1,
          onlyFolders: false,
        },
        fetchPolicy: 'network-only',
      });
    },
    [activeFolder?.id, refetchFolderContent],
  );

  const uploadFiles = useCallback(
    async (files, overwrite = false) => {
      const currentFileNames = currentFolderContent
        .filter((content) => !!content.isFile)
        .map((file) => fileNameWithExtension(file));
      const newFileNames = Array.from(files).map((file) => file.name);

      const hasFilesWithSameName = newFileNames.some((newFileName) =>
        currentFileNames.includes(newFileName),
      );

      if (!overwrite && hasFilesWithSameName) {
        setDialog({ name: 'overwrite', data: files });
        return;
      }

      await upload({
        variables: {
          parentId: activeFolder?.id || null,
          files,
        },
      });
      setDialog();
      refetchCurrentFolderContent();
    },
    [
      activeFolder?.id,
      currentFolderContent,
      refetchCurrentFolderContent,
      upload,
    ],
  );

  const reloadFileTree = () => {
    setFileTreeKey(new Date());
  };

  const navigateToFolder = useCallback((folder, path) => {
    setSelectedContent([]);
    setActiveFolder(folder);
    setActivePath(folder?.path || path);
    if (!isEmbedded) {
      history.pushState(null, '', FILES + (folder?.path || path));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Replace last slash of current path if there is one
    setActivePath(isEmbedded ? '/' : relativePath.replace(/\/$/, '') || '/');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const context = useMemo(
    () => ({
      activeFolder,
      activePath,
      selectedContent,
      currentFolderContent,
      currentFolderContentLoading,
      searchResults,
      searchLoading,
      isSearching,
      fileTreeKey,
      isFileUploadLoading,
      dialog,
      setDialog,
      reloadFileTree,
      setIsSearching,
      searchContent,
      setActiveFolder,
      setActivePath,
      setSelectedContent,
      refetchCurrentFolderContent,
      uploadFiles,
      navigateToFolder,
    }),
    [
      activeFolder,
      activePath,
      currentFolderContent,
      currentFolderContentLoading,
      dialog,
      fileTreeKey,
      isFileUploadLoading,
      isSearching,
      navigateToFolder,
      refetchCurrentFolderContent,
      searchContent,
      searchLoading,
      searchResults,
      selectedContent,
      uploadFiles,
    ],
  );

  return (
    <FileManagerContext.Provider value={context}>
      {children}
    </FileManagerContext.Provider>
  );
}
