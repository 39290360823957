import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';

import { Content } from '@ftrprf/tailwind-components';

import { EXERCISE, EXERCISES } from 'utils/constants/urls';
import ErrorMessage from '../../components/ErrorMessage';

import useDuplicateExercise from '../../hooks/graphql/useDuplicateExercise';
import useFindAllExercises from '../../hooks/graphql/useFindAllExercises';
import useRemoveExerciseMutation from '../../hooks/graphql/useRemoveExerciseMutation';
import useDebounce from '../../hooks/useDebounce';
import useParams from '../../hooks/useParams';

import { CREATE_SCRATCH_EXERCISE } from '../../api/exercise';

import ExerciseOverview from './ExerciseOverview';

export default function ExerciseOverviewContainer({
  createNewExercise = false,
}) {
  const params = useParams();
  const navigate = useNavigate();

  const [undebouncedFilters, setFilters] = useState({});

  const filters = useDebounce(undebouncedFilters, 200);

  const currentPage = Number(params.page) || 1;
  const { data, error, loading } = useFindAllExercises({
    currentPage,
    filters,
  });

  const { duplicateExercise: duplicate, loading: duplicateLoading } =
    useDuplicateExercise();

  const setCurrentPage = useCallback(
    (page) => {
      navigate({
        pathname: EXERCISES,
        search: `?page=${page}`,
      });
    },
    [navigate],
  );

  useEffect(() => {
    setCurrentPage(1);
  }, [filters, setCurrentPage]);

  const removeExercise = useRemoveExerciseMutation();
  const [createExercise] = useMutation(CREATE_SCRATCH_EXERCISE);

  const duplicateExercise = useCallback(
    (exerciseId) =>
      duplicate({
        exerciseId,
        onSuccess: (response) =>
          navigate(`${EXERCISE}/${response.data.duplicateScratchExercise.id}`),
      }),
    [duplicate, navigate],
  );

  if (error) {
    return (
      <Content>
        <ErrorMessage error={error} />
      </Content>
    );
  }

  const paginatedExercises = data?.findAllExercises;

  return (
    <ExerciseOverview
      createExercise={createExercise}
      createNewExercise={createNewExercise}
      currentPage={currentPage}
      duplicateExercise={duplicateExercise}
      duplicateLoading={duplicateLoading}
      loading={loading}
      paginatedExercises={paginatedExercises}
      removeExercise={removeExercise}
      setCurrentPage={setCurrentPage}
      setFilters={setFilters}
    />
  );
}
