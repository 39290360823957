import c from 'utils/functions/c';

export default function Overlay({ children, className, onClick, showOverlay }) {
  if (!showOverlay) {
    return children;
  }

  return (
    <div className={c('flex opacity-50 h-full', className)} onClick={onClick}>
      {children}
    </div>
  );
}
