import {
  coaches,
  CoachImage,
  DEFAULT_ASSISTANT,
  Dropdown,
  InputGroup,
  Label,
} from '@ftrprf/tailwind-components';
import { Controller } from 'react-hook-form';
import useFormatMessage from 'hooks/useFormatMessage';

export function CoachDropdown({ content, control }) {
  const t = useFormatMessage();

  return (
    <InputGroup className="mt-3">
      <Label className="mt-3" htmlFor="contentMetaDialog_coach">
        {t('content.coach')}
      </Label>
      <Controller
        control={control}
        defaultValue={content?.coach}
        name="coach"
        render={({ field: { onBlur, onChange, value } }) => (
          <>
            <Dropdown
              inputId="contentMetaDialog_coach"
              onBlur={onBlur}
              onChange={onChange}
              options={Object.keys(coaches)}
              value={value || DEFAULT_ASSISTANT}
            />

            <div className="max-h-40 max-w-[10rem] my-4">
              <CoachImage coach={value} />
            </div>
          </>
        )}
        rules={{
          required: {
            value: true,
            message: t('validation.required'),
          },
        }}
        type="select"
      />
    </InputGroup>
  );
}
