import { FaPlus } from 'react-icons/fa';

import c from 'utils/functions/c';
import getTextSize from 'utils/functions/getComponentStyle';
import { ActionStyledAsButton } from '@ftrprf/tailwind-components';
import useFormatMessage from '../hooks/useFormatMessage';

import { ReactComponent as Empty } from '../assets/vectors/empty-content.svg';

const styles = {
  FULLSCREEN: 'sm:text-5xl',
  TABLE: 'sm:text-3xl',
};

function EmptyExtraInfo({ children }) {
  return <span className="mt-2 text-center">{children}</span>;
}

function EmptyButton({ children, icon, onClick }) {
  return (
    <ActionStyledAsButton
      iconBefore={icon || FaPlus}
      onClick={onClick}
      size="large"
    >
      {children}
    </ActionStyledAsButton>
  );
}

export default function OverviewEmpty({
  BackgroundImage,
  children,
  emptyText,
  fullscreen,
  table,
}) {
  const t = useFormatMessage();

  const size = styles[getTextSize(styles, { fullscreen, table }, 'FULLSCREEN')];

  return (
    <div className="flex flex-col items-center justify-center flex-grow mb-16">
      <div className="w-full max-w-3xl relative my-8 flex-grow flex-shrink flex  items-center justify-center">
        {BackgroundImage && (
          <BackgroundImage className="h-full flex-shrink absolute" />
        )}
        {table && !BackgroundImage && (
          <Empty className="h-full flex-shrink absolute" />
        )}
        <div className="top-0 left-0 h-full flex items-center justify-center">
          <span className={c('uppercase font-bold text-3xl text-center', size)}>
            {emptyText || t('global.empty')}
          </span>
        </div>
      </div>
      {children}
    </div>
  );
}

export { EmptyExtraInfo };
export { EmptyButton };
