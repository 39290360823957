import { useEffect, useState } from 'react';
import { ShowHideTransition } from '@ftrprf/tailwind-components';
import WarningPublishedEditDialog from '../../../../components/Dialog/WarningPublishedEditDialog';

import { MultipleChoice } from './MultipleChoice/MultipleChoice';
import { OpenQuestion } from './OpenQuestion/OpenQuestion';
import useFormatMessage from '../../../../hooks/useFormatMessage';

const questionElements = {
  MULTIPLE_CHOICE: MultipleChoice,
  OPEN: OpenQuestion,
};

export function Question({ disabled, onDuplicateSlide, question }) {
  const t = useFormatMessage();
  const [warningDialogOpen, setWarningDialogOpen] = useState(false);
  const [questionDisabled, setQuestionDisabled] = useState(false);

  useEffect(() => {
    setQuestionDisabled(!!question?.publishedAt);
  }, [question]);

  if (!question) {
    return null;
  }

  const QuestionElement = questionElements[question.type];

  return (
    <ShowHideTransition
      buttonTextOnHide={t('question.show.answers')}
      buttonTextOnShow={t('question.hide.answers')}
    >
      <WarningPublishedEditDialog
        isOpen={warningDialogOpen}
        onDismiss={() => setWarningDialogOpen(false)}
        onDuplicate={() => {
          onDuplicateSlide().then(() => {
            setWarningDialogOpen(false);
          });
        }}
        onSmallChange={() => {
          setQuestionDisabled(false);
          setWarningDialogOpen(false);
        }}
      />
      <QuestionElement
        disabled={disabled}
        questionDisabled={questionDisabled}
        questionId={question.id}
        setWarningDialogOpen={setWarningDialogOpen}
      />
    </ShowHideTransition>
  );
}
