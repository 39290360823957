import { useState } from 'react';

export default function useMultiSelect() {
  const [selectedItems, setSelectedItems] = useState({});

  const selectFile = (item) => {
    setSelectedItems({ [item]: true });
  };

  const addToSelection = (item) => {
    setSelectedItems((prevItems) => ({ ...prevItems, [item]: 1 }));
  };

  const deselectFile = (item) => {
    setSelectedItems((prevItems) => {
      const newItems = { ...prevItems };
      delete newItems[item];
      return newItems;
    });
  };

  const deselectAll = () => {
    setSelectedItems({});
  };

  return [selectedItems, selectFile, addToSelection, deselectFile, deselectAll];
}
