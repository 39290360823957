import {
  FaArchive,
  FaArrowLeft,
  FaCog,
  FaCopy,
  FaDesktop,
  FaEdit,
  FaEye,
  FaHome,
  FaPaperPlane,
  FaTrash,
  FaUsers,
} from 'react-icons/fa';
import { useRecoilState } from 'recoil';

import {
  DropdownButton,
  DropdownMenu,
  Editable,
  MenuButton,
  ActionStyledAsButton,
} from '@ftrprf/tailwind-components';

import { CONTENT } from 'utils/constants/urls';
import RelativeDate from '../../../components/RelativeDate';

import useFormatDate from '../../../hooks/useFormatDate';
import useFormatMessage from '../../../hooks/useFormatMessage';

import { lastSavedAtAtom } from '../utils/atom';

export default function ContentEditorHeader({
  content,
  currentSlideId,
  disabled,
  hasViewModeSelector = true,
  onSaveTitle,
  overviewRoute,
  setShowArchiveConfirmDialog,
  setShowCopyConfirmDialog,
  setShowCreateNewVersionDialog,
  setShowMetaDataDialog,
  setShowPublishConfirmDialog,
  setShowRemoveConfirmDialog,
}) {
  const t = useFormatMessage();
  const formatDate = useFormatDate();
  const searchParams = new URLSearchParams(window.location.search);
  const url = searchParams.get('url');

  const [lastSavedAt] = useRecoilState(lastSavedAtAtom);

  const dropdownButton = ({ isOpen }) => (
    <DropdownButton
      className="font-semibold"
      disabled={content.archived}
      iconBefore={FaCog}
      isOpen={isOpen}
    >
      {t('content-editor.header.settings')}
    </DropdownButton>
  );

  const viewModeButton = ({ isOpen }) => (
    <DropdownButton
      className="font-semibold"
      iconBefore={FaEye}
      isOpen={isOpen}
    >
      {t('content-editor.header.preview')}
    </DropdownButton>
  );

  return (
    <div className="w-full h-16 py-2 mx-auto flex items-center justify-between relative px-4 text-sm border-b border-gray-300">
      <div>
        {url ? (
          <ActionStyledAsButton href={url} iconBefore={FaArrowLeft} secondary>
            <span className="hidden xl:inline xl:mr-2">
              {t('global.go_back')}
            </span>
          </ActionStyledAsButton>
        ) : (
          <ActionStyledAsButton
            iconBefore={FaArrowLeft}
            secondary
            to={overviewRoute}
          >
            <span className="hidden xl:inline xl:mr-2">
              {t('global.go_back')}
            </span>
          </ActionStyledAsButton>
        )}
      </div>
      <div className="flex">
        <Editable
          className="text-lg font-semibold relative p-1 rounded"
          contentEditable={!disabled}
          maxChars={99}
          onBlur={onSaveTitle}
          singleLine
        >
          {content.title}
        </Editable>
      </div>
      <div className="flex-grow-0 flex self-end items-center h-full">
        <span
          className="text-gray-700 text-sm"
          style={{ minWidth: '4rem' }}
          title={formatDate(lastSavedAt)}
        >
          {t('content-editor.header.last_edit_was')}{' '}
          <RelativeDate date={lastSavedAt} />
        </span>
        <DropdownMenu trigger={dropdownButton}>
          <li>
            <MenuButton
              disabled={content.published || content.archived}
              iconBefore={FaEdit}
              onClick={() => setShowMetaDataDialog(true)}
            >
              {t('content-editor.header.metadata')}
            </MenuButton>
          </li>
          <li>
            <MenuButton
              disabled={content.archived}
              iconBefore={FaCopy}
              onClick={() => setShowCopyConfirmDialog(true)}
            >
              {t('content-editor.header.copy')}
            </MenuButton>
          </li>
          <li>
            <MenuButton
              disabled={content.archived}
              iconBefore={FaArchive}
              onClick={() => setShowArchiveConfirmDialog(true)}
            >
              {t('content-editor.header.archive')}
            </MenuButton>
          </li>
          <li>
            <MenuButton
              disabled={content.archived}
              iconBefore={FaTrash}
              onClick={() => setShowRemoveConfirmDialog(true)}
            >
              {t('global.delete')}
            </MenuButton>
          </li>
        </DropdownMenu>

        {hasViewModeSelector ? (
          <DropdownMenu trigger={viewModeButton}>
            <li>
              <ActionStyledAsButton
                key="viewmodeClassical"
                className="w-full py-1 px-4 text-left rounded-lg"
                iconBefore={FaUsers}
                size="medium"
                target="_blank"
                to={`${CONTENT}/${content.id}/classical/${currentSlideId}`}
                variant="black"
              >
                {t('view-modes.classical')}
              </ActionStyledAsButton>
            </li>
            <li>
              <ActionStyledAsButton
                key="viewmodeSelfstudy"
                className="w-full py-1 px-4 text-left rounded-lg"
                iconBefore={FaHome}
                size="medium"
                target="_blank"
                to={`${CONTENT}/${content.id}/selfstudy/${currentSlideId}`}
                variant="black"
              >
                {t('view-modes.selfstudy')}
              </ActionStyledAsButton>
            </li>
            <li>
              <ActionStyledAsButton
                key="viewmodeProjection"
                className="w-full py-1 px-4 text-left rounded-lg"
                iconBefore={FaDesktop}
                size="medium"
                target="_blank"
                to={`${CONTENT}/${content.id}/projection/${currentSlideId}`}
                variant="black"
              >
                {t('view-modes.projection')}
              </ActionStyledAsButton>
            </li>
          </DropdownMenu>
        ) : (
          <ActionStyledAsButton
            iconBefore={FaEye}
            target="_blank"
            to={`${CONTENT}/${content.id}/classical/${currentSlideId}`}
          >
            {t('content-editor.header.preview')}
          </ActionStyledAsButton>
        )}

        <div className="ml-4">
          {content.published ? (
            <ActionStyledAsButton
              disabled={content.archived}
              onClick={() => setShowCreateNewVersionDialog(true)}
            >
              {t('content-editor.header.new_version')}
            </ActionStyledAsButton>
          ) : (
            <ActionStyledAsButton
              disabled={content.archived}
              iconBefore={FaPaperPlane}
              onClick={() => setShowPublishConfirmDialog(true)}
            >
              {t('content-editor.header.publish')}
            </ActionStyledAsButton>
          )}
        </div>
      </div>
    </div>
  );
}
