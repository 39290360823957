import { gql } from '@apollo/client';

export const INITIALIZE_CONTENT_NAVIGATION_TREE = gql`
  query InitializeContentNavigationTree(
    $startFolderId: Int
    $levels: Int!
    $onlyFolders: Boolean
  ) {
    initializeContentNavigationTree(
      startFolderId: $startFolderId
      levels: $levels
      onlyFolders: $onlyFolders
    ) {
      content
    }
  }
`;

export const INITIALIZE_CONTENT_NAVIGATION_TREE_FROM_PATH = gql`
  query InitializeContentNavigationTreeFromPath($path: String) {
    initializeContentNavigationTreeFromPath(path: $path) {
      content
    }
  }
`;

export const RENAME_CONTENT_FILE = gql`
  mutation RenameContentFile($contentFileId: Int!, $newName: String!) {
    renameContentFile(contentFileId: $contentFileId, newName: $newName) {
      id
      message
    }
  }
`;

export const DELETE_CONTENT_FILES = gql`
  mutation DeleteContentFiles($contentIds: [Int]!) {
    deleteContentFiles(contentIds: $contentIds) {
      id
      message
    }
  }
`;

export const UPLOAD_FILES = gql`
  mutation UploadFiles($parentId: Int, $files: [Upload]) {
    uploadFiles(parentId: $parentId, files: $files) {
      id
      message
    }
  }
`;

export const MOVE_FOLDER_CONTENT = gql`
  mutation MoveFolderContent(
    $contentIds: [Int]!
    $oldFolderId: Int
    $newFolderId: Int
  ) {
    moveFolderContent(
      contentIds: $contentIds
      oldFolderId: $oldFolderId
      newFolderId: $newFolderId
    ) {
      id
      message
    }
  }
`;

export const CREATE_FOLDER = gql`
  mutation CreateFolder($folderName: String!, $parentFolderId: Int) {
    createFolder(folderName: $folderName, parentFolderId: $parentFolderId) {
      id
      message
    }
  }
`;

export const SEARCH_CONTENT_BY_NAME = gql`
  query searchContentByName($input: SearchInput!) {
    searchContentByName(input: $input) {
      id
      name
      path
      isFile
      extension
      fileSize
      hasChildren
      parentId
      organizationId
      blobUri
      isDeleted
      createdOn
      createdBy
      modifiedOn
      modifiedBy
      thumbnailSmall
      thumbnailLarge
    }
  }
`;
