import { useContext } from 'react';
import { useMutation } from '@apollo/client';

import { NotificationContext } from '@ftrprf/tailwind-components';

import {
  CREATE_CONTENT_FILES,
  FIND_ALL_CONTENT_FILES_BY_PATH,
  REMOVE_CONTENT_FILE,
  UPDATE_CONTENT_FILE,
} from '../../api/files';

import useFormatMessage from '../useFormatMessage';

import useFieldMutation from './useFieldMutation';
import useRemoveMutation from './useRemoveMutation';

export default function useContentMutation({ fetchAll = () => {}, path }) {
  const t = useFormatMessage();

  const { addNotification } = useContext(NotificationContext);

  const [createMutate, { loading: uploading }] =
    useMutation(CREATE_CONTENT_FILES);
  const [updateFileMutate] = useFieldMutation(
    UPDATE_CONTENT_FILE,
    'ContentFile',
  );
  const [removeFileMutate] = useRemoveMutation(
    REMOVE_CONTENT_FILE,
    FIND_ALL_CONTENT_FILES_BY_PATH,
    { variables: { path } },
    'content',
  );

  const uploadFiles = ({ files, names, showNotification = true }) =>
    new Promise((resolve, reject) => {
      createMutate({
        variables: {
          files,
          paths: names.map((name) => ({
            path: `${path === '/' ? '/' : path}${name}`,
          })),
        },
      })
        .then((data) => {
          if (showNotification) {
            addNotification({
              type: 'success',
              content: t('content-manager.actions.create-file.success'),
            });
          }

          fetchAll();

          resolve(data);
        })
        .catch(() => {
          if (showNotification) {
            addNotification({
              type: 'error',
              content: t('content-manager.actions.create-file.file-too-big'),
            });
          }
          reject();
        });
    });

  const moveFiles = (id, destination, childPath) => {
    const variables = {
      id,
      path: `${destination === '/' ? '' : destination}${childPath.substring(
        childPath.lastIndexOf('/'),
      )}`,
    };

    return updateFileMutate(variables).then(() => {
      addNotification({
        type: 'success',
        content: t('content-manager.actions.move-file.success'),
      });
      fetchAll();
    });
  };

  const renameFile = (id, path) =>
    updateFileMutate({ id, path }).then(() => {
      addNotification({
        type: 'success',
        content: t('content-manager.actions.rename-title.success'),
      });
      fetchAll();
    });

  const deleteFiles = (fileIds) => {
    Promise.all(fileIds.map((fileId) => removeFileMutate({ id: fileId }))).then(
      () => {
        addNotification({
          type: 'success',
          content: t('content-manager.actions.remove-file.success'),
        });
        fetchAll();
      },
    );
  };

  const createFolder = (path, name) =>
    createMutate({
      variables: {
        files: [null],
        paths: [{ path: `${path === '/' ? '' : path}/${name}` }],
      },
    }).then(() => {
      addNotification({
        type: 'success',
        content: t('content-manager.actions.create-folder.success'),
      });
      fetchAll();
    });

  return {
    uploadFiles,
    moveFiles,
    renameFile,
    deleteFiles,
    createFolder,
    uploading,
  };
}
