import { externalNl } from './external';
import { generate } from './generate';
import { dutch } from './internal.nl';

const generated = {
  ...generate(
    [
      {
        key: 'EXAM',
        name: 'test',
        plural: 'testen',
        new: 'nieuwe',
        article: 'Het',
      },
      {
        key: 'LESSON',
        name: 'les',
        plural: 'lessen',
        new: 'nieuwe',
        article: 'De',
      },
      {
        key: 'LESSON_TEMPLATE',
        name: 'sjabloon',
        plural: 'sjablonen',
        new: 'nieuw',
        article: 'Het',
      },
    ],
    {
      'content-overview.{{ key }}.title': '{{ plural | ucase }}',
      'content-overview.{{ key }}.new_content': '{{ new | ucase }} {{ name }}',
      'content-overview.{{ key }}.empty': 'Er zijn nog geen {{ plural }}',
      'content-overview.{{ key }}.copy.success':
        '{{ article }} {{ name }} is succesvol gekopieerd.',
      'content-overview.{{ key }}.new-content.success':
        'Een {{ new }} {{ name }} is succesvol toegevoegd.',
      'content-overview.{{ key }}.publish.success':
        '{{ article }} {{ name }} is succesvol gepubliceerd.',
      'content-overview.{{ key }}.archive.success':
        '{{ article }} {{ name }} wordt gearchiveerd. Dit kan enkele seconden duren.',
      'content-overview.{{ key }}.unarchive.success':
        '{{ article }} {{ name }} wordt gedearchiveerd. Dit kan enkele seconden duren.',
      'content-overview.{{ key }}.remove.success':
        '{{ article }} {{ name }} is succesvol verwijderd.',
      'content-overview.{{ key }}.meta.success':
        '{{ article }} metadata van {{ article | lowercase }} {{ name }} is gewijzigd.',
      'content-meta-data-dialog.{{ key }}.new_title': 'Nieuwe {{ name }}',
      'content-meta-data-dialog.{{ key }}.update_title': 'Bewerk {{ name }}',
    },
  ),
  ...generate(
    [
      { key: 'EXAM', name: 'examen', article: 'het' },
      { key: 'LESSON_TEMPLATE', name: 'sjabloon', article: 'het' },
      { key: 'LESSON', name: 'les', article: 'de' },
    ],
    {
      'content-editor.{{ key }}.publish-confirm.title': 'Publiceer {{ name }}',
      'content-editor.{{ key }}.publish-confirm.text1':
        'Ben je zeker dat je {{ article }} {{ name }} wil publiceren?',
      'content-editor.{{ key }}.publish-confirm.text2': `Je zal geen veranderingen meer kunnen aanbrengen tenzij je een nieuwe versie maakt.`,
    },
  ),
}

export const appNl = {
  ...dutch,
  ...generated,
  ...externalNl
}