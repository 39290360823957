import { Input, Label } from '@ftrprf/tailwind-components';
import { Controller } from 'react-hook-form';
import useFormatMessage from 'hooks/useFormatMessage';

export function Title({ content, control, errors }) {
  const t = useFormatMessage();

  return (
    <div>
      <Label>{t('content.title')}</Label>
      <Controller
        control={control}
        defaultValue={content?.title}
        name="title"
        render={({ field }) => (
          <Input hasErrors={errors.title} type="text" {...field} />
        )}
        rules={{
          required: {
            value: true,
            message: t('validation.required'),
          },
        }}
        type="text"
      />
      {errors.title && (
        <span className="text-red-500 text-xs">{errors.title.message}</span>
      )}
    </div>
  );
}
