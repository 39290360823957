import c from 'utils/functions/c';
import { baseName } from 'utils/functions/path';

import ContentIcon from '../ContentIcon';
import { ListItem } from '../ListSelector';

export default function ContentListSelectorCenter({
  content,
  currentPath,
  onFileClick,
  onFileDoubleClick,
  onFolderClick,
  onFolderDoubleClick,
  pathStack,
  setCurrentPath,
}) {
  return (
    <div className="flex-grow-0 overflow-y-auto">
      {content.map((item) => {
        const { blobUri: url, extension, isFile, path } = item;

        return (
          <ListItem
            key={path}
            className={c(isFile ? 'hover:bg-accent-200' : 'hover:bg-gray-100')}
            onClick={() => {
              if (!isFile) {
                pathStack.push(currentPath);
                setCurrentPath(path);
                onFolderClick(url);
              } else {
                onFileClick(url);
              }
            }}
            onDoubleClick={() => {
              if (isFile) {
                onFileDoubleClick(url);
              } else {
                onFolderDoubleClick();
              }
            }}
          >
            <ContentIcon className="flex-none mr-3" thumbnail {...item} />
            <span className="break-all leading-tight flex-1">
              {baseName(path, extension || '')}
            </span>
          </ListItem>
        );
      })}
    </div>
  );
}
