import { VIEW_MODES } from '@ftrprf/tailwind-components';

import ContentEditorContainer from './ContentEditorContainer';

export default function LessonContainer(props) {
  const insertSlideBelow = ({
    content,
    insertSlideMutate,
    parameters = {},
    sequence,
  }) => {
    const variables = {
      lessonContentId: content.id,
      sequence,
      content: '',
      title: '',
      viewModes: [
        VIEW_MODES.CLASSICAL,
        VIEW_MODES.PROJECTION,
        VIEW_MODES.SELFSTUDY,
      ],
    };
    return insertSlideMutate({ ...variables, ...parameters });
  };

  return (
    <ContentEditorContainer insertSlideBelow={insertSlideBelow} {...props} />
  );
}
