export default function getStyles(t) {
  return [
    {
      name: 'Tip Block',
      element: 'p',
      classes: ['SlideViewer__Badge tip'],
    },
    {
      name: `${t('editor.badge.warning')} Block`,
      element: 'p',
      classes: ['SlideViewer__Badge warning'],
    },
    {
      name: 'Info Block',
      element: 'p',
      classes: ['SlideViewer__Badge info'],
    },
    {
      name: `${t('editor.badge.question')} Block`,
      element: 'p',
      classes: ['SlideViewer__Badge question'],
    },
    {
      name: `${t('editor.style.question')}`,
      element: 'p',
      classes: ['title-with-background title-with-background--question'],
    },
    {
      name: `${t('editor.style.statement')}`,
      element: 'p',
      classes: ['title-with-background title-with-background--statement'],
    },
    {
      name: 'Tip',
      element: 'span',
      classes: ['SlideViewer__Badge tip'],
    },
    {
      name: t('editor.badge.warning'),
      element: 'span',
      classes: ['SlideViewer__Badge warning'],
    },
    {
      name: 'Info',
      element: 'span',
      classes: ['SlideViewer__Badge info'],
    },
    {
      name: t('editor.badge.question'),
      element: 'span',
      classes: ['SlideViewer__Badge question'],
    },
    {
      name: t('editor.style.source'),
      element: 'p',
      classes: ['SlideViewer__Source'],
    },
    {
      name: t('editor.style.code'),
      element: 'span',
      classes: ['SlideViewer__Code'],
    },
  ];
}
