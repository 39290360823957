import { useEffect, useState } from 'react';
import loadScript from 'load-script';

export function useCk5Build({ enabled = true }) {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (!enabled) return;

    // daily cachebreaker
    const date = new Date();
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    // add cachebreaker to scritpt url as parameter
    loadScript(
      `${process.env.REACT_APP_CKEDITOR5_URL}?${year}${month}${day}`,
      (error) => {
        setIsLoaded(!error);
      },
    );
  }, [enabled]);

  return isLoaded;
}
