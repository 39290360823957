import { IDPS } from 'utils/constants/idp';

export function determineOrgansationRoles(organization) {
  const roleConditions = {
    ...organization,
    isBeta: false,
    isSteams: false,
    isDemo: false,
    isCodefever: false,
    isCodecosmos: false,
    isILearn: false,
    isFTRPRF: false,
    isSmartschool: false,
    isTeams: false,
  };

  if (!organization) {
    return roleConditions;
  }

  switch (organization.idp) {
    case IDPS.CODEFEVER:
      roleConditions.isCodefever = true;
      break;
    case IDPS.CODECOSMOS:
      roleConditions.isCodecosmos = true;
      break;
    case IDPS.ILEARN:
      roleConditions.isILearn = true;
      break;
    case IDPS.FTRPRF:
      roleConditions.isFTRPRF = true;
      break;
    case IDPS.SMARTSCHOOL:
      roleConditions.isSmartschool = true;
      break;
    case IDPS.TEAMS:
      roleConditions.isTeams = true;
      break;
    default:
      break;
  }

  roleConditions.isBeta = organization.beta;
  roleConditions.isSteams = organization.steams;
  roleConditions.isDemo = organization.demo;

  return roleConditions;
}
