export const studioDataTestIds = {
  lessons: {
    cardsList: 'cardsList',
    filterBar: {
      status: 'contentOverviewFilterBar_state',
      tags: 'contentOverviewFilterBar_tags',
      title: 'contentOverviewFilterBar_title',
    },
  },
};
