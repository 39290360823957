import { useCallback } from 'react';
import { useIntl } from 'react-intl';

const defaultOptions = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
};

export default function useFormatDate(options) {
  const { formatDate } = useIntl();

  return useCallback(
    (date) =>
      formatDate(
        date instanceof Date ? date : new Date(date),
        options || defaultOptions,
      ),
    [formatDate, options],
  );
}
