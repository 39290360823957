import { useContext } from 'react';

import useFormatMessage from 'hooks/useFormatMessage';

import { ActionStyledAsButton } from '@ftrprf/tailwind-components';
import { FileManagerContext } from '../FileManagerContextProvider';

import FolderDetail from './FolderDetail';
import CurrentPath from './CurrentPath';
import ContentPreview from './ContentPreview';

export default function FolderView() {
  const t = useFormatMessage();
  const {
    activeFolder,
    isSearching,
    navigateToFolder,
    searchResults,
    selectedContent,
  } = useContext(FileManagerContext);

  const selectedContentItem = selectedContent?.[0];

  return (
    <section className="flex grow max-w-[80%]">
      <div className="bg-white max-h-full overflow-auto grow border border-gray-200 scrollbar-hide flex flex-col">
        <CurrentPath />
        <h2 className="sr-only">{t('file.manager.folderview.title')}</h2>
        <div className="flex-auto relative flex flex-col">
          {isSearching && searchResults?.length !== 0 && (
            <div className="m-2">
              <p className="flex items-center">
                {t('file.manager.currently-searching-in')} &quot;
                {activeFolder?.name || t('header.files')}
                &quot;.&nbsp;
                {!activeFolder ||
                  (activeFolder?.id !== null && (
                    <ActionStyledAsButton
                      className="ml-4"
                      onClick={() => navigateToFolder(null, '/')}
                      variant="text"
                    >
                      {t('file.manager.search-in-root')}
                    </ActionStyledAsButton>
                  ))}
              </p>
            </div>
          )}
          <FolderDetail />
        </div>
      </div>
      {selectedContent?.length === 1 && selectedContentItem && (
        <ContentPreview
          key={`content-${selectedContentItem?.id}`}
          content={selectedContentItem}
        />
      )}
    </section>
  );
}
