import { DocumentIcon } from '@heroicons/react/24/solid';
import { useEffect, useState } from 'react';
import c from 'utils/functions/c';

export default function FileIcon({ color, dimensions, extension }) {
  const [icon, setIcon] = useState();

  useEffect(() => {
    if (extension) {
      import(`../icons/${extension.toUpperCase()}.svg`)
        .then((response) => setIcon(response.default))
        .catch(() => {});
    }
  }, [extension]);

  if (icon) {
    return <img className={c(dimensions, color)} src={icon} />;
  }

  return <DocumentIcon className={c(dimensions, color)} />;
}
