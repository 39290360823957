import { Input, Label } from '@ftrprf/tailwind-components';
import { Controller } from 'react-hook-form';
import useFormatMessage from 'hooks/useFormatMessage';

export function SteamsOnlyFields({ content, control }) {
  const t = useFormatMessage();

  return (
    <div>
      {/* STEAMS/TEMPLATE ONLY: Description */}
      <Label className="mt-3">{t('content.description')}</Label>
      <Controller
        control={control}
        defaultValue={content?.description}
        name="description"
        render={({ field }) => <Input type="multiline" {...field} />}
        type="text"
      />

      {/* STEAMS/TEMPLATE ONLY: Summary */}
      <Label className="mt-3">{t('content.summary')}</Label>
      <Controller
        control={control}
        defaultValue={content?.summary}
        name="summary"
        render={({ field }) => <Input type="multiline" {...field} />}
        type="text"
      />
    </div>
  );
}
