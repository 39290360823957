import { SideBar } from '../SideBars/SideBar';
import SideBarContextProvider from '../SideBars/SideBarContextProvider';

import { ContentEditorDetail } from './ContentEditorDetail';

export function SlideEditor({
  content,
  disabled,
  duplicateSlideAndGoTo,
  setShowCreateNewVersionDialog,
  slideId,
}) {
  return (
    <SideBarContextProvider isCk5={content.editorVersion === 5}>
      <ContentEditorDetail
        content={content}
        disabled={disabled}
        isCk5={content.editorVersion === 5}
        onDuplicateSlide={duplicateSlideAndGoTo}
        setShowCreateNewVersionDialog={setShowCreateNewVersionDialog}
        slideId={slideId}
      />

      <SideBar disabled={disabled} slideId={slideId} />
    </SideBarContextProvider>
  );
}
