import { useCallback, useContext, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { RecoilRoot, useRecoilState } from 'recoil';

import {
  Content,
  NotificationContext,
  usePrevious,
} from '@ftrprf/tailwind-components';

import useParams from 'hooks/useParams';
import CONTENT_TYPES from 'utils/constants/contentTypes';
import ErrorMessage from 'components/ErrorMessage';

import useFieldMutation from 'hooks/graphql/useFieldMutation';
import useInsertMutation from 'hooks/graphql/useInsertMutation';
import useRemoveMutation from 'hooks/graphql/useRemoveMutation';
import useFormatMessage from 'hooks/useFormatMessage';

import {
  FIND_LESSON_CONTENT,
  INCREASE_LESSON_CONTENT_VERSION,
  PUBLISH_LESSON_CONTENT,
  UPDATE_LESSON_CONTENT,
} from 'api/content';
import { CREATE_SLIDE, DUPLICATE_SLIDE, REMOVE_SLIDE } from 'api/slide';
import URLS from 'utils/constants/urls';
import useUpdateSlideSequencesMutation from './hooks/useUpdateSlideSequencesMutation';

import { lastSavedAtAtom, lessonContentAtom } from './utils/atom';
import updateSlideSequences from './utils/updateSlideSequences';

import ContentEditorSkeleton from './partials/ContentEditorSkeleton';
import { ContentEditor } from './ContentEditor';

function ContentEditorContainer({
  hasViewModeSelector,
  insertSlideBelow: _insertSlideBelow,
}) {
  const t = useFormatMessage();
  const { lessonContentId, slideId } = useParams();

  const queryOptions = {
    variables: {
      id: lessonContentId,
    },
  };

  const { addNotification } = useContext(NotificationContext);
  const { data, error, loading, refetch } = useQuery(
    FIND_LESSON_CONTENT,
    queryOptions,
  );

  const [lastSavedAt, setLastSavedAt] = useRecoilState(lastSavedAtAtom);

  const [updateContentMutate] = useFieldMutation(
    UPDATE_LESSON_CONTENT,
    'LessonContent',
  );
  const [publishContentMutate] = useFieldMutation(
    PUBLISH_LESSON_CONTENT,
    'LessonContent',
  );

  const [insertSlideMutate] = useInsertMutation(
    CREATE_SLIDE,
    FIND_LESSON_CONTENT,
    queryOptions,
    'slides',
  );

  const [duplicateSlideMutate] = useInsertMutation(
    DUPLICATE_SLIDE,
    FIND_LESSON_CONTENT,
    queryOptions,
    'slides',
  );

  const [removeSlideMutate] = useRemoveMutation(
    REMOVE_SLIDE,
    FIND_LESSON_CONTENT,
    queryOptions,
    'slides',
  );

  const [increaseContentVersionMutate] = useFieldMutation(
    INCREASE_LESSON_CONTENT_VERSION,
    'LessonContent',
  );

  const content = data?.findLessonContent;
  const [currentLessonContent, setLessonContent] =
    useRecoilState(lessonContentAtom);

  useEffect(() => {
    if (content && currentLessonContent?.id !== content.id) {
      setLessonContent(content);
    }
  }, [content, currentLessonContent?.id, setLessonContent]);

  const getOverviewRoute = (type) => {
    switch (type) {
      case CONTENT_TYPES.LESSON:
        return URLS.LESSONS;
      case CONTENT_TYPES.LESSON_TEMPLATE:
        return URLS.LESSON_TEMPLATES;
      case CONTENT_TYPES.EXAM:
        return URLS.EXAMS;
      default:
        return URLS.LESSONS;
    }
  };
  const overviewRoute = getOverviewRoute(content?.type);

  const [setSlideSequences] = useUpdateSlideSequencesMutation(
    content,
    queryOptions,
  );

  // Sort slides on sequence number, then by id
  const slides = [...(content?.slides || [])].sort((a, b) => {
    const result = a.sequence - b.sequence;

    return result === 0 ? a.id - b.id : result;
  });

  const previousSlidesLength = usePrevious(slides.length);
  const previousLoading = usePrevious(loading);

  useEffect(() => {
    // We don't need to update the slide sequences when the slides are just loaded
    if (previousLoading === true) {
      return;
    }

    if (slides.length && slides.length !== previousSlidesLength) {
      const [slideSequences] = updateSlideSequences(slides);

      setSlideSequences(slideSequences);
    }
  }, [slides, previousSlidesLength, setSlideSequences, previousLoading]);

  const insertSlideBelow = useCallback(
    (slide, parameters = {}) => {
      const sequence = slide ? slide.sequence : 1;
      return _insertSlideBelow({
        sequence,
        content,
        insertSlideMutate,
        parameters,
      });
    },
    [_insertSlideBelow, content, insertSlideMutate],
  );

  const duplicateSlide = (slide) =>
    duplicateSlideMutate({
      id: slide.id,
    });

  const updateContent = (variables) =>
    updateContentMutate({
      ...content,
      ...variables,
    }).then(() => {
      addNotification({
        type: 'success',
        content: t(`content-overview.${content.type}.meta.success`),
      });
      setLastSavedAt(new Date());
      void refetch();
    });

  const publishContent = (variables) =>
    publishContentMutate(variables).then(() => {
      addNotification({
        type: 'success',
        content: t(`content-overview.${content.type}.publish.success`),
      });
    });

  const increaseContentVersion = (variables) =>
    increaseContentVersionMutate({ ...variables }).then(() => {
      addNotification({
        type: 'success',
        content: t('content-overview.new-version.success'),
      });
    });

  const removeSlides = (slidesToDelete, slideSequences) => {
    if (slides.length - slidesToDelete.length < 1) {
      addNotification({
        type: 'warning',
        content: t('content-editor.warning.delete'),
      });

      return;
    }

    slidesToDelete.map((slide) =>
      removeSlideMutate({
        id: slide.id,
        slideSequences,
      }),
    );
  };

  useEffect(() => {
    if (!loading && !error && slides.length === 0) {
      insertSlideBelow();
    }
  }, [loading, error, slides, insertSlideBelow]);

  useEffect(() => {
    if (content && lastSavedAt === null) {
      setLastSavedAt(new Date(content.modifiedOn));
    }
  }, [content, lastSavedAt, setLastSavedAt]);

  if (error) {
    return (
      <Content>
        <ErrorMessage error={error} />
      </Content>
    );
  }

  if (loading || slides.length === 0) {
    return <ContentEditorSkeleton />;
  }

  return (
    <ContentEditor
      content={content}
      dataTest="studio-slides"
      defaultSlide={slideId}
      duplicateSlide={duplicateSlide}
      hasViewModeSelector={hasViewModeSelector}
      increaseContentVersion={increaseContentVersion}
      insertSlideBelow={insertSlideBelow}
      overviewRoute={overviewRoute}
      publishContent={publishContent}
      removeSlides={removeSlides}
      setSlideSequences={setSlideSequences}
      slides={slides}
      updateContent={updateContent}
    />
  );
}

// eslint-disable-next-line react/display-name
export default function ({ ...props }) {
  return (
    <RecoilRoot>
      <ContentEditorContainer {...props} />
    </RecoilRoot>
  );
}
