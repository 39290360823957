import c from 'utils/functions/c';
import getDividerStyle from 'utils/functions/getComponentStyle';

const dividerStyles = {
  HORIZONTAL: 'w-full border-t border-gray-300 my-2',
  VERTICAL: 'border-r-2 border-gray-300 h-full',
};

export default function Divider({ className, horizontal, vertical }) {
  const orientation = getDividerStyle(
    dividerStyles,
    { horizontal, vertical },
    'HORIZONTAL',
  );
  return <div className={c(className, dividerStyles[orientation])} />;
}
