import { useContext } from 'react';
import { useMutation } from '@apollo/client';

import { NotificationContext } from '@ftrprf/tailwind-components';

import useFormatMessage from 'hooks/useFormatMessage';

import { DELETE_CONTENT_FILES } from 'api/filemanager';

export const useContentDelete = () => {
  const t = useFormatMessage();
  const { addNotification } = useContext(NotificationContext);

  const [deleteContent, { error, loading }] = useMutation(
    DELETE_CONTENT_FILES,
    {
      onCompleted: (data) => {
        const { message } = data.deleteContentFiles;
        let content = t('file.manager.delete.success');
        let type = 'success';

        switch (message) {
          case 'Some files are still linked to a slide':
            content = t('file.manager.delete.success.partial');
            break;
          case 'Items deleted succesfully':
            break;
          default:
            content = t('file.manager.delete.failure');
            type = 'error';
            break;
        }

        addNotification({
          content,
          type,
        });
      },
    },
  );

  return { deleteContent, loading, error };
};
