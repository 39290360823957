import { useCallback, useContext, useEffect, useRef } from 'react';
import { Input } from '@ftrprf/tailwind-components';
import useFormatMessage from 'hooks/useFormatMessage';
import c from 'utils/functions/c';
import { FileManagerContext } from '../FileManagerContextProvider';

export function SearchInput({ className }) {
  const t = useFormatMessage();
  const searchRef = useRef();

  const { activeFolder, isSearching, searchContent, setIsSearching } =
    useContext(FileManagerContext);

  const onSearch = useCallback(
    (value) => {
      if (!value || value.length < 3) {
        setIsSearching(false);
        return;
      }
      setIsSearching(true);
      searchContent({
        variables: {
          input: {
            name: value,
            folderId: activeFolder?.id || null,
            searchType: 'FILES_AND_FOLDERS',
          },
        },
        fetchPolicy: 'network-only',
      });
    },
    [activeFolder?.id, searchContent, setIsSearching],
  );

  useEffect(() => {
    if (isSearching && !activeFolder) {
      onSearch(searchRef.current.value);
    }
  }, [activeFolder, isSearching, onSearch]);

  return (
    <Input
      ref={searchRef}
      className={c('text-xs', className)}
      label={t('file.manager.search-in-folder')}
      onChange={(e) => onSearch(e.target.value)}
      placeholder={t('file.manager.search-in-folder')}
      type="search"
    />
  );
}
