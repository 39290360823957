/* eslint-disable no-unused-vars */
import { useCallback } from 'react';
import { useMutation } from '@apollo/client';

import isArray from 'utils/functions/isArray';
import isObject from 'utils/functions/isObject';
import pick from 'utils/functions/pick';

const isValueOrNotRefArray = (value) => {
  if (isObject(value) && value.__typename) {
    return false;
  }

  if (!isArray(value) || value.length === 0) {
    return true;
  }

  return !value[0].__ref && !value[0].__typename;
};

export const update =
  (typename, variables, pickFields = []) =>
  (cache) => {
    const fields = {};

    const fieldsToUpdate = pickFields.length
      ? pick(variables, pickFields)
      : variables;

    Object.entries(fieldsToUpdate)
      .filter(([_, value]) => isValueOrNotRefArray(value))
      .forEach(([key, value]) => {
        fields[key] = () => value;
      });

    cache.modify({
      id: cache.identify({
        id: variables.id,
        __typename: typename,
      }),
      fields,
    });
  };

// eslint-disable-next-line default-param-last
export default function useFieldMutation(
  mutation,
  typename,
  fields = [],
  options = {},
) {
  const [mutate, result] = useMutation(mutation, options);

  const enhancedMutation = useCallback(
    async (variables) => {
      let result;

      try {
        result = await mutate({
          variables: fields.length ? pick(variables, fields) : variables,
          update: update(typename, variables, fields),
        }).then(({ data }) => (data ? Object.values(data)[0] : null));
      } catch (error) {
        console.log({ error });
      }

      return result;
    },
    [fields, mutate, typename],
  );

  return [enhancedMutation, result];
}
