import { useContext, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';

import { Dropdown, Input } from '@ftrprf/tailwind-components';

import contentStates from 'utils/constants/contentStates';
import c from 'utils/functions/c';
import isCodeFever from 'utils/isCodeFever';
import Filterbar from '../../../components/Filterbar';
import Form from '../../../components/Form';

import { CodeFeverContext } from '../../../providers/CodeFeverProvider';

import useFormatMessage from '../../../hooks/useFormatMessage';

import { FIND_TAGS_WITH_NAME } from '../../../api/content';
import { studioDataTestIds } from '../../../utils/constants/studioDataTestIds';

const commonClassnames = 'flex-grow';

export default function ContentOverviewFilterbar({
  onChange: onChangeHandler,
}) {
  const t = useFormatMessage();
  const { clans, lessonNumbers, levels, programTypes } =
    useContext(CodeFeverContext);
  const [filter, setFilter] = useState('');

  const { data, fetchMore } = useQuery(FIND_TAGS_WITH_NAME, {
    variables: {
      name: filter,
    },
  });
  useEffect(() => {
    void fetchMore({
      variables: {
        name: filter,
      },
    });
  }, [data, fetchMore, filter]);

  const handleInputChange = (value) => {
    setFilter(value);
  };

  const stateOptions = Object.values(contentStates).map((state) => ({
    key: state,
    value: state,
    label: t(`content-state.${state}`),
  }));
  return (
    // TODO: Make this a formik form
    <Form onChange={onChangeHandler}>
      {({ clear, onChange, values }) => (
        <Filterbar className="u-emulated-flex-gap justify-end" onClear={clear}>
          <div className={c(commonClassnames, 'min-w-56 max-w-md')}>
            <label className="sr-only" htmlFor="contentOverviewFilterBar_title">
              {t('content-overview.filters.title')}
            </label>
            <Input
              data-test={studioDataTestIds.lessons.filterBar.title}
              id="contentOverviewFilterBar_title"
              name="title"
              onChange={(event) =>
                onChange('title', event.target.value || null)
              }
              placeholder={t('content-overview.filters.title')}
              type="text"
              value={values.title || ''}
            />
          </div>
          {isCodeFever && (
            <>
              <div className={c(commonClassnames, 'min-w-56')}>
                <label
                  className="sr-only"
                  htmlFor="contentOverviewFilterBar_programType"
                >
                  {t('content-overview.filters.programme')}
                </label>
                <Dropdown
                  inputId="contentOverviewFilterBar_programType"
                  name="programType"
                  onChange={(selectedOption) =>
                    onChange('programType', selectedOption)
                  }
                  options={programTypes}
                  placeholder={t('content-overview.filters.programme')}
                  value={values.programType}
                />
              </div>
              <div className={c(commonClassnames, 'min-w-56')}>
                <label
                  className="sr-only"
                  htmlFor="contentOverviewFilterBar_clan"
                >
                  {t('content-overview.filters.clan')}
                </label>
                <Dropdown
                  className="h-11"
                  inputId="contentOverviewFilterBar_clan"
                  name="clan"
                  onChange={(selectedOption) =>
                    onChange('clan', selectedOption)
                  }
                  options={clans}
                  placeholder={t('content-overview.filters.clan')}
                  value={values.clan}
                />
              </div>
              <div className={c(commonClassnames, 'min-w-32')}>
                <label
                  className="sr-only"
                  htmlFor="contentOverviewFilterBar_level"
                >
                  {t('content-overview.filters.level')}
                </label>
                <Dropdown
                  className="h-11"
                  inputId="contentOverviewFilterBar_level"
                  name="level"
                  onChange={(selectedOption) =>
                    onChange('level', selectedOption)
                  }
                  options={levels}
                  placeholder={t('content-overview.filters.level')}
                  value={values.level}
                />
              </div>
              <div className={c(commonClassnames, 'min-w-32')}>
                <label
                  className="sr-only"
                  htmlFor="contentOverviewFilterBar_programLessonId"
                >
                  {t('content-overview.filters.lesson')}
                </label>
                <Dropdown
                  className="h-11"
                  inputId="contentOverviewFilterBar_programLessonId"
                  name="programLessonId"
                  onChange={(selectedOption) =>
                    onChange('programLessonId', selectedOption)
                  }
                  options={lessonNumbers}
                  placeholder={t('content-overview.filters.lesson')}
                  value={values.programLessonId}
                />
              </div>
            </>
          )}
          <div className={c(commonClassnames, 'w-32')}>
            <label className="sr-only" htmlFor="contentOverviewFilterBar_state">
              {t('content-overview.filters.state')}
            </label>
            <Dropdown
              className="h-10"
              data-test={studioDataTestIds.lessons.filterBar.status}
              inputId="contentOverviewFilterBar_state"
              name="state"
              onChange={(selectedOption) => onChange('state', selectedOption)}
              options={stateOptions}
              placeholder={t('content-overview.filters.state')}
              value={values.state}
            />
          </div>
          <div className={c(commonClassnames, 'w-32')}>
            <label className="sr-only" htmlFor="contentOverviewFilterBar_tags">
              {t('content-overview.filters.tags')}
            </label>
            <Dropdown
              data-test={studioDataTestIds.lessons.filterBar.tags}
              inputId="contentOverviewFilterBar_tags"
              isMulti
              name="tags"
              onChange={(selectedOption) => onChange('tags', selectedOption)}
              onInputChange={handleInputChange}
              options={data?.findTagsWithName?.map((tag) => ({
                key: tag.id,
                value: tag.name,
                label: tag.name,
              }))}
              placeholder={t('content-overview.filters.tags')}
              value={values.tags}
            />
          </div>
        </Filterbar>
      )}
    </Form>
  );
}
