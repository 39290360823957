import { createContext, useMemo, useState } from 'react';

export const CodeFeverContext = createContext({});

export default function CodeFeverProvider({ children }) {
  const [clans, setClans] = useState([]);
  const [levels, setLevels] = useState([]);
  const [lessonNumbers, setLessonNumbers] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [programTypes, setProgramTypes] = useState([]);
  const [versions, setVersions] = useState([]);

  const context = useMemo(
    () => ({
      clans,
      levels,
      lessonNumbers,
      programs,
      programTypes,
      versions,
      setClans,
      setLevels,
      setLessonNumbers,
      setPrograms,
      setProgramTypes,
      setVersions,
    }),
    [
      clans,
      levels,
      lessonNumbers,
      programs,
      programTypes,
      versions,
      setClans,
      setLevels,
      setLessonNumbers,
      setPrograms,
      setProgramTypes,
      setVersions,
    ],
  );

  return (
    <CodeFeverContext.Provider value={context}>
      {children}
    </CodeFeverContext.Provider>
  );
}
