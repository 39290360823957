import { PlusIcon } from '@heroicons/react/24/outline';
import c from 'utils/functions/c';
import { ActionStyledAsButton } from '@ftrprf/tailwind-components';
import useFormatMessage from '../../../../../hooks/useFormatMessage';
import useSlide from '../../../hooks/useSlide';

import SideBarContent from '../SideBarContent';

// eslint-disable-next-line import/no-cycle
import HintsSideBarItem from './HintsSideBarItem';

export default function HintsSideBar({ disabled, hide, id }) {
  const t = useFormatMessage();
  const { createHint, refetch, removeHint, slide } = useSlide(id);

  if (!slide) {
    return null;
  }

  const pruneHints = async () => {
    await refetch();

    if (slide.hints) {
      slide.hints.forEach((hint) => {
        const { content, title } = hint;
        if (!title && !content) {
          removeHint(hint.id);
        }
      });
    }
  };

  return (
    <SideBarContent
      className={c('overflow-auto', hide && 'hidden')}
      dataTest="studio-hints"
      goBackFunction={pruneHints}
      title={t('hints-sidebar.title')}
    >
      {slide.hints.map((hint, index) => (
        <HintsSideBarItem
          key={hint.id}
          disabled={disabled}
          id={hint.id}
          index={index}
          refetch={refetch}
          removeHint={removeHint}
          showFirst={slide.hints.length === 1}
        />
      ))}
      <div className="flex justify-center my-3">
        <ActionStyledAsButton
          disabled={disabled}
          iconBefore={PlusIcon}
          onClick={createHint}
        >
          {t('hints-sidebar.add_hint')}
        </ActionStyledAsButton>
      </div>
    </SideBarContent>
  );
}
