import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';

import { ALL_FILE_TYPES } from 'utils/constants/fileTypes';
import defaultSort from 'utils/functions/sortByFolderAlphabet';
import useStack from '../../hooks/useStack';

import { FIND_ALL_CONTENT_FILES_BY_PATH } from '../../api/files';

import ListSelector from '../ListSelector';

import ContentListSelectorCenter from './ContentListSelectorCenter';
import ContentListSelectorTop from './ContentListSelectorTop';

export default function ContentListSelector({
  allowedTypes = ALL_FILE_TYPES,
  onExternalClick = () => {},
  onFileClick = () => {},
  onFileDoubleClick = () => {},
  onFolderClick = () => {},
  onFolderDoubleClick = () => {},
  onNavigate = () => {},
  setCurrentPath: setCurrentPathOut = () => {},
}) {
  const [currentPath, _setCurrentPath] = useState('/');
  const [content, setContent] = useState([]);
  const pathStack = useStack();

  const setCurrentPath = (path) => {
    _setCurrentPath(path);
    setCurrentPathOut(path);
  };

  const { data, loading, refetch } = useQuery(FIND_ALL_CONTENT_FILES_BY_PATH, {
    variables: { path: currentPath },
  });
  const contentData = data?.findAllContentFilesByPath;

  useEffect(() => {
    if (!loading) {
      onNavigate(currentPath);
      void refetch();
      const allowedContent =
        allowedTypes.length > 0
          ? Object.values(contentData).filter(
              ({ extension, isFile }) =>
                allowedTypes.includes(extension) || !isFile,
            )
          : Object.values(contentData).filter(({ isFile }) => !isFile);
      setContent(defaultSort(allowedContent));
    }
  }, [currentPath, allowedTypes, onNavigate, loading, contentData, refetch]);

  return (
    <ListSelector
      contentListCenter={
        <ContentListSelectorCenter
          content={content}
          currentPath={currentPath}
          loading={loading}
          onFileClick={onFileClick}
          onFileDoubleClick={onFileDoubleClick}
          onFolderClick={onFolderClick}
          onFolderDoubleClick={onFolderDoubleClick}
          pathStack={pathStack}
          setCurrentPath={setCurrentPath}
        />
      }
      contentListTop={
        <ContentListSelectorTop
          currentPath={currentPath}
          onExternalClick={onExternalClick}
          pathStack={pathStack}
          setCurrentPath={setCurrentPath}
        />
      }
      isEmpty={content.length === 0}
      isLoading={loading}
    />
  );
}
