import { Form, Formik } from 'formik';
import { object, string } from 'yup';
import { escape } from 'lodash-es';
import { ActionStyledAsButton, Label } from '@ftrprf/tailwind-components';

import { useContext } from 'react';
import { PencilIcon, TrashIcon } from '@heroicons/react/24/outline';
import ButtonGroup from '../../../../components/ButtonGroup';
// eslint-disable-next-line import/no-cycle
import Editor from '../../../../components/Editor/Editor';
import * as customPlugins from '../../../../components/Editor/sideBarCustomPlugins';
import FormikInput from '../../../../components/Form/FormikInput';

import useFormatMessage from '../../../../hooks/useFormatMessage';

import SideBarContent from './SideBarContent';
import { SideBarContext } from './SideBarContextProvider';
import { Ck5SlideEditorContainer } from '../Ck5Editor/Ck5SlideEditorContainer';

export default function ModalSideBar({
  disabled,
  initialModal,
  onDismiss,
  onRemove,
  onSubmit,
  slide,
}) {
  const t = useFormatMessage();
  const { isCk5 } = useContext(SideBarContext);

  return (
    <SideBarContent
      className="modalSideBar"
      goBackFunction={isCk5 ? onDismiss : null}
      grow
      title={t('editor.editmodalsidebar.title')}
    >
      <Formik
        initialValues={{
          buttonText: initialModal?.buttonText ?? '',
          modalTitle: initialModal?.modalTitle ?? '',
          modalSize: initialModal?.modalSize ?? 'M',
          modalContent: initialModal?.modalContent ?? '',
        }}
        onSubmit={onSubmit}
        validateOnBlur={false}
        validationSchema={object({
          buttonText: string().required('validation.empty'),
          modalTitle: string().required('validation.empty'),
          modalSize: string().required('validation.empty'),
          modalContent: string().required('validation.empty'),
        })}
      >
        {({
          errors,
          handleChange,
          isValid,
          setFieldTouched,
          setFieldValue,
          touched,
          values,
        }) => (
          <Form className="p-3 flex flex-col h-full bg-gray-100">
            <FormikInput
              disabled={disabled}
              label={t('editor.editmodalsidebar.label-buttontext')}
              name="buttonText"
              onChange={handleChange}
              placeholder={t('editor.editmodalsidebar.label-text')}
              value={values.buttonText?.replaceAll('&nbsp;', '')}
            />
            <FormikInput
              disabled={disabled}
              label={t('editor.editmodalsidebar.label-modaltitle')}
              name="modalTitle"
              onChange={handleChange}
              placeholder={t('editor.editmodalsidebar.label-title')}
              value={values.modalTitle}
            />
            <Label htmlFor="modalSize">
              {t('editor.editmodalsidebar.label-modalsize')}
              {touched.modalSize && errors.modalSize ? (
                <span className="mt-1 text-sm text-red-700">
                  - {t(errors.modalSize)}
                </span>
              ) : null}
            </Label>
            <ButtonGroup
              className="mb-3 w-min"
              disabled={disabled}
              id="modalSize"
              name="modalSize"
              onChange={(value) => {
                if (value || touched.modalSize) {
                  setFieldTouched('modalSize');
                  setFieldValue('modalSize', value);
                }
              }}
              options={[
                { title: 'S', value: 'S' },
                { title: 'M', value: 'M' },
                { title: 'L', value: 'L' },
              ]}
              selectedValue={values.modalSize}
            />
            <Label htmlFor={`${slide?.id}modalSideBar`}>
              {t('editor.editmodalsidebar.label-content')}
              {touched.modalContent && errors.modalContent ? (
                <span className="mt-1 text-sm text-red-700">
                  {' '}
                  - {t(errors.modalContent)}
                </span>
              ) : null}
            </Label>
            {isCk5 ? (
              <Ck5SlideEditorContainer
                disabled={disabled}
                id={`${slide?.id}modalSideBar`}
                isInSideBar
                isLoaded
                onChange={(value) => {
                  const sanitizedValue = escape(value);
                  if (value || touched.modalContent) {
                    void setFieldTouched('modalContent');
                    void setFieldValue('modalContent', sanitizedValue);
                  }
                }}
                value={values.modalContent}
              />
            ) : (
              <Editor
                className="border rounded py-1"
                disabled={disabled}
                id={slide?.id}
                isMainEditor={false}
                name="modalContent"
                onChange={(value) => {
                  if (value || touched.modalContent) {
                    setFieldTouched('modalContent');
                    setFieldValue('modalContent', value);
                  }
                }}
                plugins={customPlugins}
                value={values.modalContent}
              />
            )}
            <div className="mt-3 flex gap-2">
              <ActionStyledAsButton
                disabled={!isValid}
                iconBefore={PencilIcon}
                type="submit"
              >
                {initialModal ? t('global.edit') : t('global.add')}
              </ActionStyledAsButton>
              <ActionStyledAsButton
                iconBefore={TrashIcon}
                onClick={onRemove}
                secondary
              >
                {t('global.delete')}
              </ActionStyledAsButton>
            </div>
          </Form>
        )}
      </Formik>
    </SideBarContent>
  );
}
