import { useParams } from 'react-router-dom';

import useLessonContent from 'hooks/api/studioService/common/queries/useLessonContent';
import { SlideViewer } from './SlideViewer';

export default function SlideViewerContainer() {
  const { lessonContentId: studioId } = useParams();

  const { data, error, loading } = useLessonContent(studioId);

  return <SlideViewer data={data} error={error} loading={loading} />;
}
