import { useEffect } from 'react';

// useTitle can accept multiple titles like useTitle(title1, title2);
// useTitle will filter out booleans so you can do useTitle(isValid && "newTitle");
export default function useTitle(...titles) {
  useEffect(() => {
    const previousTitle = window.document.title;
    const filteredTitles = titles.filter(
      (title) => typeof title === 'string' || title instanceof String,
    );

    if (filteredTitles.length > 0) {
      window.document.title = `${filteredTitles.join(' · ')} · FTRPRF Studio`;

      return () => {
        window.document.title = previousTitle;
      };
    }

    return null;
  }, [titles]);
}
