import { createContext, useMemo, useState } from 'react';
import { IntlProvider } from 'react-intl';

import { LANGUAGE } from 'utils/constants/localStorage';

import { appDe } from '../translations/app.de';
import { appEn } from '../translations/app.en';
import { appEs } from '../translations/app.es';
import { appFr } from '../translations/app.fr';
import { appNl } from '../translations/app.nl';

const languages = {
  de: appDe,
  en: process.env.NODE_ENV === 'test' ? {} : appEn,
  es: appEs,
  fr: appFr,
  nl: appNl,
};

export const supportedLanguages = Object.keys(languages);

export const LanguageContext = createContext({});

const browserLanguage =
  localStorage.getItem(LANGUAGE) || navigator.language?.split('-')[0] || 'en';

export default function LanguageProvider({ children, onError }) {
  const [language, setLanguage] = useState(browserLanguage);

  const onChangeLanguage = (language) => {
    if (language in languages) {
      setLanguage(language);
      localStorage.setItem(LANGUAGE, language);
    }
  };

  const context = useMemo(
    () => ({
      language,
      setLanguage: onChangeLanguage,
    }),
    [language],
  );

  return (
    <LanguageContext.Provider value={context}>
      <IntlProvider
        locale={language}
        messages={languages[language]}
        onError={onError}
      >
        {children}
      </IntlProvider>
    </LanguageContext.Provider>
  );
}
