import { Link } from 'react-router-dom';

import useFormatMessage from 'hooks/useFormatMessage';
import URLS from 'utils/constants/urls';

export default function FileUsagesDetail({ slides }) {
  const t = useFormatMessage();

  if (!slides?.length) {
    return <p>{t('file.manager.not-used-lesson')}</p>;
  }

  return (
    <ul className="list-inside">
      {slides.map((slide) => (
        <li key={`file-usage-${slide.slideId}`}>
          <Link
            className="text-sm w-fit text-accent-600 py-1 hover:shadow-none focus:translate-x-px focus:translate-y-px transform rounded focus:outline focus:outline-2 focus:outline-blue-500 hover:underline cursor-pointer inline-block box-border"
            rel="noopener noreferrer"
            target="_blank"
            to={`${URLS.LESSON}/${slide.lessonId}/edit/${slide.slideId}`}
          >
            <span className="text-xl text-black mr-2 align-middle">&bull;</span>
            {slide.title} - {t('file.manager.slide')} {slide.sequence}
          </Link>
        </li>
      ))}
    </ul>
  );
}
