import { useEditorStyleSheet } from '@ftrprf/tailwind-components';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
// eslint-disable-next-line import/no-cycle
import { Ck5SlideEditor } from './Ck5SlideEditor';
import { ScaleButtons } from './ScaleButtons';
// eslint-disable-next-line import/no-cycle
import { Plugins } from './plugins/Plugins';
import { getRidOfFalseAttribute } from './getRidOfFalseAttribute';
import { getRidOfNbspOverloadInLink } from './getRidOfNbspOverloadInLink';
import { getRidOfNbspOverloadOutsideOfLink } from './getRidOfNbspOverloadOutsideOfLink';

export function Ck5SlideEditorContainer({
  background,
  disabled,
  id,
  isInSideBar = true,
  onChange,
  scale = 1,
  setScale,
  slide,
  value,
}) {
  const editorContainerRef = useRef(null);
  const editorRef = useRef();

  const editor = editorRef.current;
  const editorContainer = editorContainerRef.current;

  const [isEditorReady, setIsEditorReady] = useState(false);
  const [currentPlugin, setCurrentPlugin] = useState('');
  const [selectedElement, setSelectedElement] = useState();
  const [isFullScreen, setIsFullScreen] = useState(false);

  const insertContent = useCallback(
    (content) => {
      const viewFragment = editor.data.processor.toView(`${content}<p></p>`);
      const modelFragment = editor.data.toModel(viewFragment);
      editor.model.insertContent(modelFragment);
    },
    [editor],
  );
  const updateContent = useCallback(
    (newHtml, previousHtml) => {
      const sanitizedNewHtml = getRidOfFalseAttribute(
        getRidOfNbspOverloadInLink(newHtml),
      );
      const prevData = editor.getData();
      const prevHtml = selectedElement?.html || previousHtml;
      const newData = getRidOfNbspOverloadOutsideOfLink(
        prevData?.replace(prevHtml, sanitizedNewHtml),
      );

      editor?.setData(newData);
    },
    [editor, selectedElement?.html],
  );

  const editorContext = useMemo(
    () => ({
      currentPlugin,
      editor,
      editorContainerRef,
      editorRef,
      insertContent,
      isEditorReady,
      isFullScreen,
      onChange,
      selectedElement,
      setCurrentPlugin,
      setIsEditorReady,
      setIsFullScreen,
      setSelectedElement,
      slide,
      updateContent,
      value,
    }),
    [
      currentPlugin,
      editor,
      insertContent,
      isEditorReady,
      isFullScreen,
      onChange,
      selectedElement,
      slide,
      updateContent,
      value,
    ],
  );

  useEffect(() => {
    if (scale) {
      if (editorContainer) {
        editorContainer.style.setProperty('--scale', scale);
      }
    }
  }, [editorContainer, scale]);

  const setScaleProperties = useCallback(() => {
    if (!editorContainer) return;
    const editorWidth = 1366 * scale;
    const editorHeight = 768 * scale;

    const isWidthOverflowing = editorContainer.clientWidth < editorWidth;
    const isHeightOverflowing = editorContainer.clientHeight < editorHeight;

    editorContainer.style.setProperty('--scale', scale);

    editorContainer.style.setProperty(
      '--top',
      isHeightOverflowing
        ? '1rem'
        : `${Math.max(
            (editorContainer.clientHeight - editorHeight) / 2,
            16,
          )}px`,
    );

    editorContainer.style.setProperty(
      '--left',
      isWidthOverflowing
        ? '1rem'
        : `${(editorContainer.clientWidth - editorWidth) / 2}px`,
    );
  }, [editorContainer, scale]);

  useEditorStyleSheet(true);

  return (
    <>
      <Ck5SlideEditor
        background={background}
        disabled={disabled}
        editorContext={editorContext}
        id={id}
        isInSideBar={isInSideBar}
        onReady={setScaleProperties}
      />
      {setScale && (
        <ScaleButtons
          isEditor
          isFullScreen={isFullScreen}
          scale={scale}
          setScale={setScale}
          setScaleProperties={setScaleProperties}
        />
      )}
      <Plugins editorContext={editorContext} isInSideBar />
    </>
  );
}
