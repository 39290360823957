export const substituteMediaEmbeddedIframes = (data, setTriggerReset) => {
  if (data.match(/<figure.+<iframe.+<\/figure>/g)) {
    let editedIframe = false;

    const outputData = `${data}`.replaceAll(/<figure.+<\/figure>/g, (match) => {
      const iframeMatch = match.match(/<iframe[^>]*>[\s\S]*?<\/iframe>/);

      if (iframeMatch) {
        editedIframe = true;
        const iframeTag = iframeMatch[0];

        // Extract attributes from the iframe tag using regular expressions
        const classMatch = iframeTag.match(/class=["'](.*?)["']/);
        const widthMatch = iframeTag.match(/width=["'](.*?)["']/);
        const heightMatch = iframeTag.match(/height=["'](.*?)["']/);
        const srcMatch = iframeTag.match(/src=["'](.*?)["']/);
        const dataAlignment = iframeTag.match(/data-alignment=["'](.*?)["']/);

        // noinspection UnnecessaryLocalVariableJS
        const modifiedIframeTag = `<p><iframe 
              allowfullscreen 
              class="${
                classMatch
                  ? classMatch[1]
                  : 'ck iframe ck-iframe ck-iframe-showNoBorders'
              }" 
              data-alignment="${dataAlignment ? dataAlignment[1] : 'middle'}"
              height="${heightMatch ? heightMatch[1] : '100%'}" 
              src="${srcMatch ? srcMatch[1] : ''}"
              title=''
              width="${widthMatch ? widthMatch[1] : '100%'}" 
            ></iframe></p>`;

        return modifiedIframeTag;
      }

      return match;
    });

    if (editedIframe) {
      setTriggerReset(outputData);
    }

    return outputData;
  }

  return data;
};
