import { Input } from '@ftrprf/tailwind-components';

import Filterbar from '../../../components/Filterbar';
import Form from '../../../components/Form';

import useFormatMessage from '../../../hooks/useFormatMessage';

export default function ExerciseOverviewFilterBar({
  onChange: onChangeHandler,
}) {
  const t = useFormatMessage();

  return (
    <Form onChange={onChangeHandler}>
      {({ clear, onChange, values }) => (
        <Filterbar className="u-emulated-flex-gap justify-end" onClear={clear}>
          <div className="flex-grow min-w-56 max-w-md">
            <Input
              name="title"
              onChange={(event) =>
                onChange('title', event.target.value || null)
              }
              placeholder={t('content-overview.filters.title')}
              type="text"
              value={values.title || ''}
            />
          </div>
        </Filterbar>
      )}
    </Form>
  );
}
