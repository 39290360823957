import './theme/tailwind.css';

import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { EventType } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';

import { QueryClientProvider } from 'react-query';
import queryClient from 'utils/queryClient';
import ApolloProvider from 'providers/ApolloProvider';
import CodeFeverProvider from 'providers/CodeFeverProvider';
import LanguageProvider from 'providers/LanguageProvider';
import TailwindProvider from 'providers/TailwindProvider';
import UserProvider from 'providers/UserProvider';

import initSentry from './utils/initSentry';

import App from './app/App';

import msalInstance from './msalInstance';

initSentry();

if (process.env.REACT_APP_MOCK && process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line global-require
  const { worker } = require('./mocks/setup-server-browser');
  worker.start();
}

if (process.env.NODE_ENV === 'production') {
  console.log(`
  =============================================================================================
  __________________________ ________ ________ __________
  ___  ____/___  __/___  __ \\___  __ \\___  __ \\___  ____/
  __  /_    __  /   __  /_/ /__  /_/ /__  /_/ /__  /_    
  _  __/    _  /    _  _, _/ _  ____/ _  _, _/ _  __/    
  /_/       /_/     /_/ |_|  /_/      /_/ |_|  /_/    v${process.env.REACT_APP_VERSION}
  =============================================================================================  
  `);

  console.log(
    'Wanted: Passionate developers. React / Java Spring Boot. Send a mail to katelijne@ftrprf.be with your CV and motivation!',
  );

  window.console = {
    log: () => {},
    info: () => {},
    warn: () => {},
    error: () => {},
  };
}

const accounts = msalInstance.getAllAccounts();
if (accounts?.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const { account } = event.payload;
    msalInstance.setActiveAccount(account);
  }
});

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <LanguageProvider>
      <MsalProvider instance={msalInstance}>
        <TailwindProvider>
          <Router>
            <UserProvider>
              <ApolloProvider>
                <CodeFeverProvider>
                  <App />
                </CodeFeverProvider>
              </ApolloProvider>
            </UserProvider>
          </Router>
        </TailwindProvider>
      </MsalProvider>
    </LanguageProvider>
  </QueryClientProvider>,
  document.getElementById('root'),
);
