import { useContext } from 'react';

import DragArea from 'components/DragArea';
import FileManagerTree from './partials/FileManagerTree';
import { FileManagerContext } from './FileManagerContextProvider';
import FolderView from './partials/FolderView';

export default function FileManager() {
  const { activePath, fileTreeKey, isFileUploadLoading, uploadFiles } =
    useContext(FileManagerContext);

  return (
    <div className="h-[calc(100vh-115px)] flex text-gray-700 text-sm">
      <DragArea isUploading={isFileUploadLoading} uploadFiles={uploadFiles}>
        {activePath && <FileManagerTree key={fileTreeKey} />}
        <FolderView />
      </DragArea>
    </div>
  );
}
