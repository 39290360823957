import { useLazyQuery } from '@apollo/client';

import { SEARCH_CONTENT_BY_NAME } from 'api/filemanager';

export default function useFileSearch() {
  const [search, { data, error, loading }] = useLazyQuery(
    SEARCH_CONTENT_BY_NAME,
  );

  return { data: data?.searchContentByName, loading, error, search };
}
