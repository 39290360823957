import {
  FaArchive,
  FaCopy,
  FaDesktop,
  FaEye,
  FaHome,
  FaPencilAlt,
  FaTrash,
  FaUsers,
} from 'react-icons/fa';
import { Link } from 'react-router-dom';

import { MenuButton } from '@ftrprf/tailwind-components';

import CONTENT_TYPES from 'utils/constants/contentTypes';
import URLS from 'utils/constants/urls';
import { ListContextMenu } from '../../../../components/ContextMenu/ListContextMenu';
import Divider from '../../../../components/Divider';

import useFormatMessage from '../../../../hooks/useFormatMessage';

export default function ContentCardContextMenu({
  children,
  content,
  contentType,
  onArchive,
  onCopy,
  onRemove,
  onUnarchive,
}) {
  const t = useFormatMessage();

  const disabled = content.archived;

  return (
    <ListContextMenu trigger={children}>
      {contentType === CONTENT_TYPES.EXAM ? (
        <Link target="_blank" to={`${URLS.CONTENT}/${content.id}/classical`}>
          <MenuButton disabled={disabled} iconBefore={FaEye}>
            {t('content-editor.header.preview')}
          </MenuButton>
        </Link>
      ) : (
        <>
          <Link target="_blank" to={`${URLS.CONTENT}/${content.id}/classical`}>
            <MenuButton disabled={disabled} iconBefore={FaUsers}>
              {t('view-modes.classical')}
            </MenuButton>
          </Link>
          <Link target="_blank" to={`${URLS.CONTENT}/${content.id}/selfstudy`}>
            <MenuButton disabled={disabled} iconBefore={FaHome}>
              {t('view-modes.selfstudy')}
            </MenuButton>
          </Link>
          <Link target="_blank" to={`${URLS.CONTENT}/${content.id}/projection`}>
            <MenuButton disabled={disabled} iconBefore={FaDesktop}>
              {t('view-modes.projection')}
            </MenuButton>
          </Link>
          <Divider />
        </>
      )}
      <Link to={`${URLS[contentType]}/${content.id}/edit`}>
        <MenuButton disabled={disabled} iconBefore={FaPencilAlt}>
          {t('global.edit')}
        </MenuButton>
      </Link>
      <MenuButton disabled={disabled} iconBefore={FaCopy} onClick={onCopy}>
        {t('global.copy')}
      </MenuButton>
      {content.archived ? (
        <MenuButton iconBefore={FaArchive} onClick={onUnarchive}>
          {t('global.unarchive')}
        </MenuButton>
      ) : (
        <MenuButton iconBefore={FaArchive} onClick={onArchive}>
          {t('global.archive')}
        </MenuButton>
      )}
      <MenuButton iconBefore={FaTrash} onClick={onRemove}>
        {t('global.delete')}
      </MenuButton>
    </ListContextMenu>
  );
}
