import { useState } from 'react';

import { Content, PageHeader, PageTitle } from '@ftrprf/tailwind-components';

import { ContentCardListSkeleton } from '../../components/ContentCardList';
import ContentMetaDataDialog from '../../components/Dialog/ContentMetaDataDialog';
import ErrorMessage from '../../components/ErrorMessage';
import OverviewEmpty, { EmptyButton } from '../../components/OverviewEmpty';

import useFormatMessage from '../../hooks/useFormatMessage';
import useTitle from '../../hooks/useTitle';

import { ReactComponent as EmptyContent } from '../../assets/vectors/empty-content.svg';

import ContentOverviewCards from './partials/ContentOverviewCards';
import ContentOverviewHeader from './partials/ContentOverviewHeader';

export default function ContentOverview({
  addContent,
  contentList = [],
  contentType,
  createNewLesson,
  currentPage,
  error,
  loading = true,
  refetchContentList,
  setCurrentPage,
  setFilters,
  totalItems,
  totalPages,
}) {
  const t = useFormatMessage();
  const [showNewContentDialog, setShowNewContentDialog] =
    useState(createNewLesson);

  useTitle(t(`content-overview.${contentType}.title`));

  return (
    <>
      <PageHeader>
        <PageTitle>{t(`content-overview.${contentType}.title`)}</PageTitle>
        <ContentOverviewHeader
          contentType={contentType}
          onNewContentClick={() => setShowNewContentDialog(true)}
          setFilters={setFilters}
        />
      </PageHeader>
      <Content>
        <ContentMetaDataDialog
          initialType={contentType}
          isOpen={showNewContentDialog}
          onDismiss={() => setShowNewContentDialog(false)}
          onSubmit={addContent}
        />
        <div className="flex flex-grow flex-col">
          {!error && loading && <ContentCardListSkeleton />}
          {!error && !loading && contentList.length > 0 && (
            <ContentOverviewCards
              contentList={contentList}
              contentType={contentType}
              currentPage={currentPage}
              refetchContentList={refetchContentList}
              setCurrentPage={setCurrentPage}
              totalItems={totalItems}
              totalPages={totalPages}
            />
          )}
          {!error && !loading && contentList.length === 0 && (
            <OverviewEmpty
              BackgroundImage={EmptyContent}
              emptyText={t(`content-overview.${contentType}.empty`)}
            >
              <EmptyButton onClick={() => setShowNewContentDialog(true)}>
                {t(`content-overview.${contentType}.new_content`)}
              </EmptyButton>
            </OverviewEmpty>
          )}
          <ErrorMessage className="mt-4" error={error} />
        </div>
      </Content>
    </>
  );
}
