import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import useUnarchiveContentMutation from '../hooks/graphql/useUnarchiveContentMutation';
import useFormatMessage from '../hooks/useFormatMessage';

export function UnarchiveLesson() {
  const navigate = useNavigate();
  const { id } = useParams();
  const t = useFormatMessage();

  const unarchive = useUnarchiveContentMutation();

  useEffect(() => {
    unarchive(id).then(() => {
      navigate(`/lesson/${id}/edit`);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div>{t('global.unarchiving')}</div>;
}
