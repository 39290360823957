import c from 'utils/functions/c';

export default function ButtonGroup({
  className,
  onChange,
  options,
  selectedValue,
}) {
  const buttons = options.map(({ title, value }) => (
    <button
      key={value}
      autoFocus={selectedValue === value}
      className={c(
        'px-4 py-2 border border-gray-300 text-sm font-medium text-gray-700',
        selectedValue === value
          ? 'z-10 outline-none bg-accent-200'
          : 'bg-white hover:bg-gray-50',
      )}
      onClick={() => onChange(value)}
      type="button"
    >
      {title}
    </button>
  ));

  return (
    <div
      className={c(
        'relative z-0 inline-flex shadow-sm rounded-md overflow-hidden',
        className,
      )}
    >
      {buttons}
    </div>
  );
}
