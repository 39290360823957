import { FaFilter, FaPlus } from 'react-icons/fa';

import { useLocalStorage } from '@ftrprf/hooks';
import { ActionStyledAsButton } from '@ftrprf/tailwind-components';

import { CONTENT_OVERVIEW_FILTERBAR_OPEN } from 'utils/constants/localStorage';
import PageActions from '../../../components/PageActions';

import useFormatMessage from '../../../hooks/useFormatMessage';

import ContentOverviewFilterbar from './ContentOverviewFilterbar';

export default function ContentOverviewHeader({
  contentType,
  onNewContentClick,
  setFilters,
}) {
  const t = useFormatMessage();

  const [isShowingFilter, setIsShowingFilter] = useLocalStorage(
    CONTENT_OVERVIEW_FILTERBAR_OPEN,
    false,
  );

  return (
    <>
      <PageActions>
        <ActionStyledAsButton iconBefore={FaPlus} onClick={onNewContentClick}>
          {t(`content-overview.${contentType}.new_content`)}
        </ActionStyledAsButton>
        {!isShowingFilter ? (
          <ActionStyledAsButton
            iconBefore={FaFilter}
            onClick={() => setIsShowingFilter((s) => !s)}
            secondary
            test="contentFilter"
          >
            {t('content-overview.toggle_filter')}
          </ActionStyledAsButton>
        ) : (
          <ActionStyledAsButton
            iconBefore={FaFilter}
            onClick={() => setIsShowingFilter((s) => !s)}
            test="contentFilter"
            variant="white"
          >
            {t('content-overview.toggle_filter')}
          </ActionStyledAsButton>
        )}
      </PageActions>

      {isShowingFilter && (
        <div className="mt-6">
          <ContentOverviewFilterbar onChange={setFilters} />
        </div>
      )}
    </>
  );
}
