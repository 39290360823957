export default function NoResults(props) {
  return (
    <svg viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg" {...props}>
      <defs>
        <style>{'.cls-1{fill: #d1d5db;}'}</style>
      </defs>
      <title>No Results</title>
      <g data-name="No Results" id="No_Results">
        <path
          className="cls-1"
          d="M68.91 84.1a1 1 0 0 0 1.45 1.44 8 8 0 0 1 11.28 0 1 1 0 0 0 1.45-1.44 10 10 0 0 0-14.18 0ZM85 73.18v-2a1 1 0 0 0-2 0v2a1 1 0 0 0 2 0ZM69 73.18v-2a1 1 0 0 0-2 0v2a1 1 0 0 0 2 0Z"
        />
        <path
          className="cls-1"
          d="M60.43 60.43C46.54 74.32 56.51 98 76 98s29.45-23.7 15.57-37.58a22 22 0 0 0-31.14.01Zm29.7 29.7a20 20 0 1 1 0-28.26 20 20 0 0 1 0 28.26Z"
        />
        <path
          className="cls-1"
          d="M127 117.57 106.43 97a3.52 3.52 0 0 0-4.23-.48l-3.75-3.75A28 28 0 0 0 82 48.65V22h21a1 1 0 0 0 1-1v-9A12 12 0 0 0 92 0H12A12 12 0 0 0 0 12c0 122.81-.32 115.48.58 115.9.65.31.55.19 7.09-3.73l6.16 3.69c.7.42.51.31 7.19-3.69 6.42 3.85 6.47 4.11 7.17 3.69l6.16-3.69 6.15 3.69c.69.41.49.32 7.18-3.69 6.42 3.85 6.47 4.11 7.18 3.69l6.14-3.69 6.16 3.69c.69.41.49.32 7.17-3.69 6.69 4 6.48 4.09 7.16 3.7S82 129 82 103.35a27.77 27.77 0 0 0 10.76-4.9l3.74 3.74a3.41 3.41 0 0 0 .49 4.24L117.57 127a3.41 3.41 0 0 0 2.43 1c1.89 0 2.33-.91 7-5.59a3.43 3.43 0 0 0 0-4.84ZM102 76a26 26 0 1 1-26-26 26 26 0 0 1 26 26Zm0-64v8H82v-8a10 10 0 0 1 20 0ZM80 125.23c-5.54-3.32-5.49-3.5-6.18-3.09l-6.15 3.69-6.15-3.69c-.7-.41-.54-.29-7.18 3.69-6.42-3.85-6.47-4.11-7.18-3.69L41 125.83l-6.15-3.69c-.69-.41-.5-.32-7.18 3.69-6.42-3.85-6.47-4.11-7.18-3.69l-6.15 3.69-6.16-3.69c-.69-.41-.61-.25-6.19 3.1V12A10 10 0 0 1 12 2h73.34a12.12 12.12 0 0 0-5.25 8.57C80 11.72 80 8.41 80 48.28a28 28 0 0 0-23.81 7.91C37.26 75.11 53.32 107.54 80 103.72Zm17.16-30.87 3.52 3.52-2.8 2.8-3.52-3.52a25.53 25.53 0 0 0 2.8-2.8ZM121 125.57a1.43 1.43 0 0 1-2 0C96.49 103.05 98 104.82 98 104s.1-.68 5-5.57a1.4 1.4 0 0 1 2 0c22.51 22.57 21 20.75 21 21.57s0 .61-5 5.57Z"
        />
        <path
          className="cls-1"
          d="M51.48 34.59c1.62-5.2-.66-11.3-4.42-13.56C37.83 15.52 28 21.93 28 31a1 1 0 0 0 2 0c0-7.47 8.11-12.92 16-8.22 5.07 3 6.06 13.7-1.88 17.31A9 9 0 0 0 39 48.36V52a1 1 0 0 0 2 0v-3.64A7 7 0 0 1 45 42a12.05 12.05 0 0 0 6.48-7.41ZM40 57a2 2 0 1 0 2 2 2 2 0 0 0-2-2Zm0 2c1.35 0 1.33 0 0 0Z"
        />
      </g>
    </svg>
  );
}
