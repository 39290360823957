import { useEffect, useState } from 'react';
import { FaPlus } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';
import { useSetRecoilState } from 'recoil';

import {
  ActionStyledAsButton,
  ConfirmationDialog,
  SlideNotAvailable,
  usePrevious,
} from '@ftrprf/tailwind-components';

import FullpageContent from 'components/Content/FullpageContent';
import ContentMetaDataDialog from 'components/Dialog/ContentMetaDataDialog';

import useArchiveContentMutation from 'hooks/graphql/useArchiveContentMutation';
import useCopyContentMutation from 'hooks/graphql/useCopyContentMutation';
import useRemoveContentMutation from 'hooks/graphql/useRemoveContentMutation';
import useFormatMessage from 'hooks/useFormatMessage';
import useTitle from 'hooks/useTitle';

import c from 'utils/functions/c';
import URLS from 'utils/constants/urls';
import { isDraggingAtom, sideBarAtom } from './utils/atom';

import { SlideEditor } from './partials/ContentEditorDetail/SlideEditor';
import ContentEditorHeader from './partials/ContentEditorHeader';
import { SIDE_BARS } from './partials/SideBars/SideBarContextProvider';
import SlideOverview from './partials/SlideOverview/SlideOverview';

export function ContentEditor({
  content,
  dataTest,
  defaultSlide,
  duplicateSlide,
  hasViewModeSelector,
  increaseContentVersion,
  insertSlideBelow,
  overviewRoute,
  publishContent,
  removeSlides,
  setSlideSequences,
  slides,
  updateContent,
}) {
  const t = useFormatMessage();
  const setSideBar = useSetRecoilState(sideBarAtom);
  const setIsDragging = useSetRecoilState(isDraggingAtom);
  const navigate = useNavigate();
  const client = useApolloClient();

  useTitle(content.title);

  const [currentSlideId, setCurrentSlideId_] = useState(
    defaultSlide || slides[0]?.id,
  );

  const copy = useCopyContentMutation();
  const archive = useArchiveContentMutation();
  const remove = useRemoveContentMutation();

  const [showMetaDataDialog, setShowMetaDataDialog] = useState(false);

  const [showPublishConfirmDialog, setShowPublishConfirmDialog] =
    useState(false);

  const [showCreateNewVersionDialog, setShowCreateNewVersionDialog] =
    useState(false);
  const [showCopyConfirmDialog, setShowCopyConfirmDialog] = useState(false);
  const [showArchiveConfirmDialog, setShowArchiveConfirmDialog] =
    useState(false);
  const [showRemoveConfirmDialog, setShowRemoveConfirmDialog] = useState(false);

  const disabled = content.published || content.archived;

  const currentSlideIndex = slides.findIndex(
    (slide) => slide.id === currentSlideId,
  );

  const previousCurrentSlideIndex = usePrevious(currentSlideIndex, -1);

  const isCk5 = content.editorVersion === 5;

  const currentSlide =
    currentSlideIndex !== -1
      ? slides[currentSlideIndex]
      : slides[previousCurrentSlideIndex];

  const [selectedSlideIds, setSelectedSlideIds] = useState(
    currentSlide ? [currentSlide.id] : [],
  );

  const setCurrentSlideId = (id) => {
    setCurrentSlideId_(id);

    setSideBar((sb) => {
      if (sb?.type === SIDE_BARS.TEACHER_INFO_SIDEBAR) {
        // Keep the teacher info sidebar open when swithching between slides
        return { type: SIDE_BARS.TEACHER_INFO_SIDEBAR, id };
      }
      return null;
    });
  };

  const onInsertSlide = (slide, parameters = {}) => {
    insertSlideBelow(slide, parameters).then(({ id }) => {
      if (id) {
        setCurrentSlideId(id);
        setSelectedSlideIds([id]);
        return id;
      }

      return defaultSlide;
    });
  };

  const duplicateSlideAndGoTo = (slide) =>
    duplicateSlide(slide).then((response) => {
      setCurrentSlideId(response.id);
      setSelectedSlideIds([response.id]);
      return response;
    });

  const onSaveTitle = (title) => {
    if (title !== '') {
      updateContent({ title });
    }
  };

  useEffect(() => {
    if (currentSlide)
      history.pushState(
        null,
        '',
        `${content.type === 'EXAM' ? URLS.EXAM : URLS.LESSON}/${
          content.id
        }/edit/${currentSlide.id}`,
      );
  }, [content.id, content.type, currentSlide]);

  return (
    <div
      className="flex flex-col h-full"
      onMouseUp={() => setIsDragging(false)}
    >
      <ContentEditorHeader
        content={content}
        currentSlideId={currentSlideId}
        disabled={disabled}
        hasViewModeSelector={hasViewModeSelector}
        onSaveTitle={onSaveTitle}
        overviewRoute={overviewRoute}
        setShowArchiveConfirmDialog={setShowArchiveConfirmDialog}
        setShowCopyConfirmDialog={setShowCopyConfirmDialog}
        setShowCreateNewVersionDialog={setShowCreateNewVersionDialog}
        setShowMetaDataDialog={setShowMetaDataDialog}
        setShowPublishConfirmDialog={setShowPublishConfirmDialog}
        setShowRemoveConfirmDialog={setShowRemoveConfirmDialog}
      />
      <ContentMetaDataDialog
        content={content}
        initialType={content.type}
        isOpen={showMetaDataDialog}
        onDismiss={() => setShowMetaDataDialog(false)}
        onSubmit={(changedFields) => {
          updateContent(changedFields).then(() => setShowMetaDataDialog(false));
        }}
      />
      <ConfirmationDialog
        content={
          <div>
            {t(`content-editor.${content.type}.publish-confirm.text1`)} <br />
            {t(`content-editor.${content.type}.publish-confirm.text2`)}
          </div>
        }
        isOpen={showPublishConfirmDialog}
        onConfirm={() => {
          publishContent({ id: content.id }).then(() => {
            setShowPublishConfirmDialog(false);
          });
        }}
        onDismiss={() => setShowPublishConfirmDialog(false)}
        title={t(`content-editor.${content.type}.publish-confirm.title`)}
      />

      <ConfirmationDialog
        content={<div>{t('content-editor.new_version_confirm')}</div>}
        isOpen={showCreateNewVersionDialog}
        onConfirm={() =>
          increaseContentVersion({ id: content.id }).then(() => {
            setShowCreateNewVersionDialog(false);
          })
        }
        onDismiss={() => setShowCreateNewVersionDialog(false)}
        title={t('content-editor.header.new_version')}
      />

      <ConfirmationDialog
        isOpen={showCopyConfirmDialog}
        onConfirm={() => {
          copy(content.id).then(() => {
            setShowCopyConfirmDialog(false);
            client.resetStore().then(() =>
              navigate(overviewRoute, {
                replace: true,
              }),
            );
          });
        }}
        onDismiss={() => setShowCopyConfirmDialog(false)}
      />
      <ConfirmationDialog
        isOpen={showArchiveConfirmDialog}
        onConfirm={() => {
          archive(content.id).then(() => {
            setShowArchiveConfirmDialog(false);
            navigate(overviewRoute, {
              replace: true,
            });
          });
        }}
        onDismiss={() => setShowArchiveConfirmDialog(false)}
      />
      <ConfirmationDialog
        isOpen={showRemoveConfirmDialog}
        onConfirm={() => {
          remove(content).then(() => {
            navigate(overviewRoute, {
              replace: true,
            });

            client.resetStore();
            setShowRemoveConfirmDialog(false);
          });
        }}
        onDismiss={() => setShowRemoveConfirmDialog(false)}
      />

      <FullpageContent className="flex flex-grow">
        <div
          className={c('flex border-gray-300 flex-col item w-60 flex-shrink-0')}
        >
          <div className="h-16 flex-shrink-0 flex items-center gap-5 px-4 border-b border-gray-300 bg-gray-50">
            <ActionStyledAsButton
              disabled={disabled}
              iconBefore={FaPlus}
              onClick={() => onInsertSlide(currentSlide)}
            >
              {t('content-editor.add_slide')}
            </ActionStyledAsButton>
          </div>
          <div
            className="flex-shrink-0 flex-grow flex-basis-0 border-r border-gray-300"
            data-test={dataTest}
            id="slideOverviewContainer"
          >
            <SlideOverview
              canSwitchSlides={!showMetaDataDialog}
              currentSlide={currentSlide}
              disabled={disabled}
              hasViewModeSelector={hasViewModeSelector}
              isCk5={isCk5}
              onDuplicateSlide={duplicateSlide}
              onInsertSlide={onInsertSlide}
              onRemoveSlides={removeSlides}
              selectedSlideIds={selectedSlideIds}
              setCurrentSlide={(slide) => {
                if (currentSlideId !== slide.id) {
                  setCurrentSlideId(slide?.id);
                }
              }}
              setSelectedSlideIds={setSelectedSlideIds}
              setShowCreateNewVersionDialog={setShowCreateNewVersionDialog}
              setSlideSequences={setSlideSequences}
              slides={slides}
            />
          </div>
        </div>
        {currentSlide ? (
          <SlideEditor
            content={content}
            disabled={disabled}
            duplicateSlideAndGoTo={duplicateSlideAndGoTo}
            setShowCreateNewVersionDialog={setShowCreateNewVersionDialog}
            slideId={currentSlide?.id}
          />
        ) : (
          <SlideNotAvailable inEditor />
        )}
      </FullpageContent>
    </div>
  );
}
