import { FaCopy, FaPencilAlt, FaTrash } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import { MenuButton } from '@ftrprf/tailwind-components';
import { ListContextMenu } from '../../../../components/ContextMenu/ListContextMenu';

import useFormatMessage from '../../../../hooks/useFormatMessage';

export default function ContentCardContextMenu({
  children,
  duplicateLoading,
  editURL,
  onDelete = () => {},
  onDuplicate,
}) {
  const t = useFormatMessage();

  return (
    <ListContextMenu trigger={children}>
      {editURL && (
        <Link to={editURL}>
          <MenuButton iconBefore={FaPencilAlt}>{t('global.edit')}</MenuButton>
        </Link>
      )}
      <MenuButton
        iconBefore={FaCopy}
        loading={duplicateLoading}
        onClick={onDuplicate}
        preventClose
      >
        {t('global.duplicate')}
      </MenuButton>
      <MenuButton iconBefore={FaTrash} onClick={onDelete}>
        {t('global.delete')}
      </MenuButton>
    </ListContextMenu>
  );
}
