import c from 'utils/functions/c';
import useFormatMessage from '../../../../../../hooks/useFormatMessage';
import { determineClasses } from '../helpers/determineClasses';

/**
 * Component to display an example link.
 *
 * @param label
 * @param {styledLinks} type
 * @param url
 * @returns
 */
export function PreviewLink({ label = '', type, url = '' }) {
  const t = useFormatMessage();

  return (
    <div className="mt-6">
      <p className="font-bold">{t('link-plugin.example')}</p>

      <div className="my-2">
        {url ? (
          <a
            className={c('cursor-pointer select-none', determineClasses(type))}
            href={url}
            rel="noopener noreferrer"
            target="_blank"
          >
            {label?.trim() || url?.trim()}
          </a>
        ) : (
          t('link-plugin.example.valid')
        )}
      </div>
    </div>
  );
}
