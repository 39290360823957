export const dutch = {
  // Globals
  'global.add': 'Voeg toe',
  'global.answer': 'Antwoord',
  'global.archive': 'Archiveer',
  'global.archiving': 'Aan het archiveren',
  'global.cancel': 'Annuleer',
  'global.close': 'Sluiten',
  'global.confirm': 'Bevestig',
  'global.copied': 'Gekopieerd',
  'global.copy': 'Kopieer',
  'global.copying': 'We kopiëren de les, even geduld alstublieft.',
  'global.delete': 'Verwijder',
  'global.download': 'Download',
  'global.duplicate': 'Dupliceer',
  'global.edit': 'Bewerk',
  'global.empty': 'Het lijkt erop dat er hier niets is!',
  'global.folder': 'Map',
  'global.go_back': 'Ga terug',
  'global.height': 'Hoogte',
  'global.name': 'Naam',
  'global.no_results': 'Geen resultaten gevonden',
  'global.not_required': 'Niet verplicht',
  'global.on': 'op',
  'global.question': 'Vraag',
  'global.rename': 'Naam wijzigen',
  'global.save': 'Opslaan',
  'global.saved': 'Opgeslagen',
  'global.saving': 'Opslaan...',
  'global.search': 'Zoek',
  'global.test-plan': 'Testplan',
  'global.try-again': 'Probeer opnieuw',
  'global.unarchive': 'Dearchiveer',
  'global.unarchiving': 'Aan het dearchiveren',
  'global.upload': 'Upload',
  'global.URL': 'URL',
  'global.username': 'Gebruikersnaam',
  'global.view': 'Bekijk',
  'global.width': 'Breedte',

  // Languages
  'languages.de': 'Duits',
  'languages.en': 'English',
  'languages.es': 'Spaans',
  'languages.fr': 'Frans',
  'languages.nl': 'Nederlands',

  // Logout
  'logout': 'Uitloggen',

  // Header
  'header.admin': 'Admin',
  'header.back_to_FTRPRF': 'Terug naar FTRPRF',
  'header.close_menu': 'Menu sluiten',
  'header.dashboard': 'Dashboard',
  'header.exams': 'Test',
  'header.exercises': 'Codeer',
  'header.files': 'Mijn bestanden',
  'header.hello': 'Hallo, ',
  'header.lessons': 'Leer',
  'header.navmenu': 'Navigatiemenu',
  'header.sign_out': 'Uitloggen',
  'header.templates': 'Sjablonen',
  'header.usermenu': 'Gebruikersmenu',

  // viewModes
  'view-modes.classical': 'Klassikaal',
  'view-modes.projection': 'Projectie',
  'view-modes.selfstudy': 'Zelfstudie',
  'view-modes.teacher_manual': 'Handleiding leraar',

  // contentStates
  'content-state.ARCHIVED': 'Gearchiveerd',
  'content-state.CONCEPT': 'Concept',
  'content-state.PUBLISHED': 'Gepubliceerd',

  // content-overview
  'content-editor.warning.delete': 'Een les moet minimaal 1 slide bevatten',
  'content-overview.filters.clan': 'Clan',
  'content-overview.filters.lesson': 'Les',
  'content-overview.filters.level': 'Level',
  'content-overview.filters.programme': 'Programma',
  'content-overview.filters.state': 'Status',
  'content-overview.filters.tags': 'Tags',
  'content-overview.filters.title': 'Titel',
  'content-overview.filters.version': 'Versie',
  'content-overview.new-version.success': 'Een nieuwe versie is succesvol aangemaakt.',
  'content-overview.toggle_filter': 'Filter',

  // Lesson Card
  'content-card.image_alt': 'Afbeelding voor de les',

  // Lesson Content
  'content.clan': 'Clan',
  'content.coach': 'Coach',
  'content.description': 'Beschrijving',
  'content.endGoals': 'Eindtermen',
  'content.filter': 'Enkel letters en cijfers zijn toegestaan',
  'content.groupRoles': 'Groep Rollen',
  'content.group_lesson': 'Groeples',
  'content.image': 'Afbeelding',
  'content.language': 'Taal',
  'content.last_modified_by': 'Laatst aangepast door',
  'content.last_modified_on': 'Laatst aangepast op',
  'content.level': 'Level',
  'content.program': 'Programma',
  'content.summary': 'Samenvatting',
  'content.tags': 'Tags',
  'content.template': 'Kies een sjabloon of werkvorm',
  'content.template.view': 'bekijk sjabloon',
  'content.title': 'Titel',
  'content.type': 'Type',
  'content.version': 'Version',

  // Slide overview
  'slide-thumbnail.contains-question': 'Slide bevat vraag',

  // Studio
  'content-editor.activity.image.select': 'Selecteer afbeelding',
  'content-editor.activity.image_add': 'Icoon toevoegen',
  'content-editor.activity.image_change': 'Icoon wijzigen',
  'content-editor.activity.method': 'Publiek',
  'content-editor.activity.title': 'Activiteit',
  'content-editor.activity.type': 'Type activiteit',
  'content-editor.activity.type_placeholder': 'Zoek of creëer activiteit',
  'content-editor.add_activity': 'Activiteit',
  'content-editor.add_slide': 'Slide',
  'content-editor.buttons.add_hint': 'Nieuwe hint',
  'content-editor.buttons.add_question': 'Voeg een vraag toe',
  'content-editor.buttons.exercise': 'Oefening',
  'content-editor.buttons.flow_diagram': 'Steams schema',
  'content-editor.buttons.hints': 'Hints',
  'content-editor.buttons.manual': 'Handleiding',
  'content-editor.buttons.teacher_info': 'Leraar info',
  'content-editor.change_question.confirm': 'Wanneer je het slidetype wijzigt worden de antwoorden verwijderd.',
  'content-editor.colors.minecraft.agent': 'Agent',
  'content-editor.colors.minecraft.arrays': 'Matrices',
  'content-editor.colors.minecraft.basic': 'Basis',
  'content-editor.colors.minecraft.blocks': 'Blokken',
  'content-editor.colors.minecraft.builder': 'Bouwer',
  'content-editor.colors.minecraft.functions': 'Functies',
  'content-editor.colors.minecraft.gameplay': 'Gameplay',
  'content-editor.colors.minecraft.logic': 'Logisch',
  'content-editor.colors.minecraft.loops': 'Lussen',
  'content-editor.colors.minecraft.math': 'Rekenen',
  'content-editor.colors.minecraft.mobs': 'Mobs',
  'content-editor.colors.minecraft.player': 'Speler',
  'content-editor.colors.minecraft.positions': 'Postities',
  'content-editor.colors.minecraft.shapes': 'Vormen',
  'content-editor.colors.minecraft.text': 'Tekst',
  'content-editor.colors.minecraft.variables': 'Variabelen',
  'content-editor.colors.scratch.besturen': 'Besturen',
  'content-editor.colors.scratch.beweging': 'Beweging',
  'content-editor.colors.scratch.functies': 'Functies',
  'content-editor.colors.scratch.gebeurtenis': 'Gebeurtenis',
  'content-editor.colors.scratch.geluid': 'Geluid',
  'content-editor.colors.scratch.mijn_blokken': 'Mijn blokken',
  'content-editor.colors.scratch.pen_makey': 'Pen en Maykeymakey',
  'content-editor.colors.scratch.uiterlijken': 'Uiterlijken',
  'content-editor.colors.scratch.variabelen': 'Variabelen',
  'content-editor.colors.scratch.waarnemen': 'Waarnemen',
  'content-editor.colors.subtitle-defintion.color': 'Ondertitel en defintie',
  'content-editor.colors.white': 'Wit',
  'content-editor.context_menu.insert_slide': 'Slide invoegen',
  'content-editor.context_menu.move_down': 'Naar beneden',
  'content-editor.context_menu.move_up': 'Naar boven',
  'content-editor.editor_version': 'Versie editor',
  'content-editor.errors.attempting_to_delete_all': 'Je probeert alle slides te verwijderen maar een les moet ten minste 1 slide bevatten.',
  'content-editor.header.actions': 'Acties',
  'content-editor.header.actions.archive': 'Archiveer',
  'content-editor.header.actions.save': 'Opslaan',
  'content-editor.header.archive': 'Archiveer',
  'content-editor.header.copy': 'Kopieer',
  'content-editor.header.last_edit_was': 'Laatste bewerking was',
  'content-editor.header.metadata': 'Metadata',
  'content-editor.header.new_version': 'Nieuwe versie',
  'content-editor.header.preview': 'Bekijk',
  'content-editor.header.publish': 'Publiceer',
  'content-editor.header.settings': 'Instellingen',
  'content-editor.lesson_title.placeholder': 'Lestitel...',
  'content-editor.new_version_confirm': 'Ben je zeker dat je een nieuwe versie wilt maken?',
  'content-editor.no_viewmodes.error': 'Er moet tenminste 1 view modus geselecteerd zijn per slide',
  'content-editor.overview.projection_warning': 'Klassikale slides zijn altijd zichtbaar in de projectieweergave',
  'content-editor.question.multiple-choice.add-answer': 'Nieuw antwoord',
  'content-editor.question.open-question.info': 'Dit is de plek waar studenten hun antwoord op de open vraag zullen invullen.',
  'content-editor.return_to_editor': 'Terug naar editor',
  'content-editor.slide.modify-content': 'Pas inhoud van de slide aan',
  'content-editor.slide.modify_question': 'Pas vraag aan',
  'content-editor.styles.bron': 'Bron',
  'content-editor.styles.default': 'Standaard',
  'content-editor.styles.definition': 'Definitie',
  'content-editor.styles.intertitle': 'Tussentitel',
  'content-editor.styles.question': 'Vraag',
  'content-editor.styles.subtitle': 'Ondertitel',
  'content-editor.styles.text': 'Tekst',
  'content-editor.styles.tip': 'Tip',
  'content-editor.title.placeholder': 'Slidetitel...',
  'exercise-plugin.url.exercise-default': 'Open oefening',
  'html-insert-plugin.title': 'HTML invoegen',
  'link-plugin.display-text': 'Weergavetekst',
  'link-plugin.example': 'Link voorvertoning',
  'link-plugin.example.valid': 'Om hier iets te kunnen weergeven, moet je eerst een URL invullen.',
  'link-plugin.file_name': 'Bestands naam',
  'link-plugin.is_download': 'Is een download link',
  'link-plugin.link-style.DownloadLink': 'Download',
  'link-plugin.link-style.ExerciseLink': 'Oefening',
  'link-plugin.link-style.ExternalLink': 'Extern',
  'link-plugin.link-style.OtherLink': 'Andere',
  'link-plugin.link-style.SlideLink': 'Slide',
  'link-plugin.link-style.SteamsQR': 'QR',
  'link-plugin.link-style.VideoLink': 'Video',
  'link-plugin.open_new_tab': 'Open link in nieuw tabblad',
  'link-plugin.type.download': 'Download',
  'link-plugin.type.external': 'Extern',
  'link-plugin.type.label': 'Kies URL type',
  'link-plugin.type.other': 'Andere',
  'link-plugin.type.slide': 'Slide',
  'link-plugin.type.video': 'Video',
  'link-plugin.url.add-exercise': 'Voeg oefening toe',
  'link-plugin.url.add-url': 'Voeg URL toe',
  'link-plugin.url.dialog': 'URL Dialog',
  'link-plugin.url.enter': 'Voeg URL in',
  'link-plugin.url.length': "Een link moet minstens 5 karakters lang zijn.",
  'link-plugin.url.link-style.label': 'Linkstijl',
  'link-plugin.url.modify-exercise': 'Pas oefening aan',
  'link-plugin.url.modify-url': 'Pas URL aan',
  'link-plugin.url.use-https': "Een link moet altijd beginnen met hetzij 'https://', 'slide://', 'mailto:' of 'tel:'.  Wegens veiligheidsredenen ondersteunen we geen 'http://'-linken.",
  'link-plugin.url.warning': 'Deze slide en de slide waarnaar je verwijst moeten exact dezelfde viewmodes hebben én in dezelfde les zitten. Anders kunnen errors voorkomen bij het openen van de link.',

  // SlideType
  'slide_type.MULTIPLE_CHOICE': 'Meerkeuzevraag',
  'slide_type.OPEN': 'Open vraag',
  'slide_type.TEXT': 'Tekstslide',

  // SlideMethod
  'slide_method.CLASS': 'Klassikaal',
  'slide_method.GROUP': 'Groep',
  'slide_method.INDIVIDUALLY': 'Individueel',

  // SlidePart
  'slide_part.CLOSURE': 'Slot',
  'slide_part.EXERCISE': 'Opdracht uitvoeren',
  'slide_part.INTRODUCTION': 'Inleiding',
  'slide_part.THEORY': 'Theorie',

  // Admin
  'admin.description': 'Beschrijving',
  'admin.firstname': 'Voornaam',
  'admin.groups': 'Groepen',
  'admin.groups.add-group': 'Voeg groep toe',
  'admin.groups.add-group.success': 'Groep toegevoegd!',
  'admin.groups.edit-group': 'Pas groep aan',
  'admin.groups.edit-group.success': 'Groep aangepast!',
  'admin.groups.filter': 'Welke groep zoek je?',
  'admin.groups.remove-group.success': 'Groep verwijderd!',
  'admin.lastname': 'Achternaam',
  'admin.logo': 'Logo',
  'admin.name': 'Naam',
  'admin.organizations': 'Organisaties',
  'admin.organizations.filter': 'Welke organisatie zoek je?',
  'admin.stats': 'Statistieken',
  'admin.users': 'Gebruikers',

  // Multiselect list
  'looking-for': 'Wie zoek je?',
  added: 'Toegevoegd',
  available: 'Beschikbaar',

  // Filterbar
  'filterbar.clear': 'Verwijder filters',
  'filterbar.hide': 'Verberg filters',
  'filterbar.show': 'Toon filters',

  // Dropdown
  'dropdown.no_results': 'Geen resultaten',

  // Slide viewer
  'slide_viewer.scroll_down': 'Scroll naar beneden',
  'slide_viewer.scroll_up': 'Scroll naar boven',
  'slide_viewer.teacher_manual': 'Handleiding leerkracht',
  'slide_viewer.teacher_manual_empty': 'Er is geen extra uitleg nodig bij deze slide',

  // Content selector
  'content-selector.add': 'Sleep bestanden hier',
  'content-selector.add_external': 'Voeg externe bron toe',
  'content-selector.drop_items': 'Plaats bestanden hier',
  'content-selector.empty_folder': 'Deze map is leeg',
  'content-selector.image': 'Voeg afbeelding toe',
  'content-selector.replace_image': 'Vervang afbeelding',
  'content-selector.title.default': 'Mijn bestanden',
  'content-selector.uploaded_image': 'Huidige oefening afbeelding',
  'content-selector.url': 'Voeg url in',

  // Confirmation dialog
  'confirmation_dialog.title': 'Weet je het zeker?',

  // Content Manager
  'content-manager.title': 'Mijn bestanden',

  // Errors
  'errors.label': 'Error',
  'errors.network_error': 'We hebben moeite om de server te bereiken... Gelieve opnieuw te proberen.',
  'errors.session_expired': 'Uw sessie is verlopen. Gelieve opnieuw in te loggen.',
  'errors.something_went_wrong': 'Er is iets fout gegaan. Gelieve opnieuw te proberen.',
  'errors.user_does_not_exist': 'De gebruikersnaam of het wachtwoord is incorrect.',

  // 404 Page
  'errorpage.header': 'Oeps!',
  'errorpage.message': 'Ze is mogelijk verwijderd, van URL veranderd, of tijdelijk niet beschikbaar.',
  'errorpage.refresh': 'Of probeer opnieuw',
  'errorpage.subheader': 'Net zoals eenhoorns, bestaat deze pagina niet.',
  'errorpage.title': '404',
  'errorpage.to_home': 'Terug naar de homepagina',

  // Content Manager actions
  'content-manager.actions.copy': 'Kopieren',
  'content-manager.actions.copy-link': 'Kopieer link',
  'content-manager.actions.copy_to': 'Kopieer naar',
  'content-manager.actions.create': 'Maak',
  'content-manager.actions.create-file.file-too-big': 'Bestand is te groot om te uploaden. Kies een kleiner bestand alstublieft.',
  'content-manager.actions.create-file.success': 'Het bestand is aangemaakt.',
  'content-manager.actions.create-folder.success': 'De map is aangemaakt.',
  'content-manager.actions.create_file': 'Upload Bestand',
  'content-manager.actions.create_folder': 'Nieuwe Map',
  'content-manager.actions.cut': 'Knippen',
  'content-manager.actions.delete': 'Verwijderen',
  'content-manager.actions.delete.confirm_text': 'Als je dit bestand verwijdert zal het niet meer beschikbaar zijn in de lessen.',
  'content-manager.actions.delete.pay_attention': 'Let op!',
  'content-manager.actions.error.only-image': 'Enkel afbeeldingen zijn toegelaten',
  'content-manager.actions.error.only-video': 'Enkel video\'s zijn toegelaten',
  'content-manager.actions.move-file.success': 'Bestand verplaatst',
  'content-manager.actions.move_to': 'Verplaats naar',
  'content-manager.actions.overwrite': 'Bestand Overschrijven',
  'content-manager.actions.paste': 'Plakken',
  'content-manager.actions.paste-file.success': 'Het bestand is toegevoegd.',
  'content-manager.actions.redo': 'Volgende',
  'content-manager.actions.remove-file.success': 'Het bestand is verwijderd.',
  'content-manager.actions.rename': 'Naam wijzigen',
  'content-manager.actions.rename-title': 'Naam wijzigen',
  'content-manager.actions.rename-title.success': 'De naam is gewijzigd',
  'content-manager.actions.rename.path-exists': 'Deze naam is reeds gebruikt in deze map. Wil je toch uploaden?',
  'content-manager.actions.trashcan': 'Prullenbak',
  'content-manager.actions.undo': 'Vorige',
  'files.actions-context_menu.copy_file_to': 'Kopieer bestand naar',
  'files.actions-context_menu.copy_here': 'Kopieer',
  'files.actions-context_menu.move_file_to': 'Verplaats bestand',
  'files.actions-context_menu.move_here': 'Plaats hier',
  'files.drop_file': 'Sleep bestanden naar hier om te beginnen',

  // Content Manager preview
  'content-manager.preview.createdBy': 'Aangemaakt door',
  'content-manager.preview.createdOn': 'Aangemaakt op',
  'content-manager.preview.extension': 'Extensie',
  'content-manager.preview.modifiedBy': 'Laatst gewijzigd door',
  'content-manager.preview.modifiedOn': 'Laatst gewijzigd op',
  'content-manager.preview.mutiple_files': 'Selectie',
  'content-manager.preview.path': 'Bestandsnaam',
  'content-manager.preview.size': 'Grootte',

  // Editor
  'editor.audioplugin.label': 'Audio toevoegen',
  'editor.audioplugin.label-upload': 'Of upload een audiofragment',
  'editor.background.slide': 'Slide achtergrond',
  'editor.badge.question': 'Vraag',
  'editor.badge.warning': 'Waarschuwing',
  'editor.editmodalsidebar.label-buttontext': 'Weergavetekst',
  'editor.editmodalsidebar.label-content': 'Inhoud',
  'editor.editmodalsidebar.label-modalsize': 'Grootte modal',
  'editor.editmodalsidebar.label-modaltitle': 'Titel modal',
  'editor.editmodalsidebar.label-text': 'Tekst',
  'editor.editmodalsidebar.label-title': 'Titel',
  'editor.editmodalsidebar.title': 'Modal knop',
  'editor.exerciseplugin.label': 'Oefening toevoegen',
  'editor.iframeplugin.error.iframe.size': 'Dit veld kan niet groter zijn dan 1500 px',
  'editor.iframeplugin.label': 'Iframe toevoegen',
  'editor.imageplugin.label': 'Afbeelding toevoegen',
  'editor.imageplugin.label-external': 'Externe afbeelding toevoegen',
  'editor.imageplugin.label-upload': 'Of upload een afbeelding',
  'editor.linkplugin.label': 'Link toevoegen',
  'editor.modalplugin.label': 'Popup toevoegen',
  'editor.source-plugin.title': 'Broncode',
  'editor.style.code': 'Code',
  'editor.style.question': 'Vraag achtergrond',
  'editor.style.source': 'Bron',
  'editor.style.statement': 'Stelling achtergrond',
  'editor.videoplugin.label': 'Video toevoegen',
  'editor.videoplugin.label-external': 'Externe video toevoegen',
  'editor.videoplugin.label-upload': 'Of upload een video',

  // Content templates
  'content.templates.icon-header': 'Icon header',
  'content.templates.icon-header-desc': 'Hoofding met titel en afbeelding',
  'content.templates.learn-page': 'Leerwijzer pagina',
  'content.templates.learn-page-desc': 'Titel met daaronder twee kolommen: een met subtitel, checklist en FTRPRF logo. De ander met afbeelding',
  'content.templates.list-arrows.desc': 'Lijst met pijltjes ipv bolletjes',
  'content.templates.list-arrows.title': 'Pijltjes lijst',
  'content.templates.list-checks.desc': 'lijst met groene vinkjes ipv bolletjes',
  'content.templates.list-checks.title': 'Check lijst',
  'content.templates.title-slide': 'Titel slide',
  'content.templates.title-slide-desc': 'Grote gecentreerde titel met afbeelding',
  'content.templates.title-slide-small-title-placeholder': 'Kleine titel - ...',
  'content.templates.title-slide-title-placeholder': 'Titel - ...',

  // Dragarea
  'dragarea.file': 'bestand',
  'dragarea.files': 'bestanden',
  'dragarea.uploading': 'Uploaden van',

  // File Manager
  'file.manager.abbr.not-applicable': 'N.V.T.',
  'file.manager.buttons.title': 'Map en bestand acties',
  'file.manager.clear-search': 'Clear search',
  'file.manager.content-moved': 'Inhoud verplaatsen geslaagd',
  'file.manager.content-renamed': 'Naam wijzigen geslaagd',
  'file.manager.copy-link': 'Kopieer link',
  'file.manager.create-folder': 'Nieuwe map',
  'file.manager.created-on': 'Aangemaakt op',
  'file.manager.creator': 'Auteur',
  'file.manager.currently-searching-in': 'Aan het zoeken in',
  'file.manager.cut': 'Knippen',
  'file.manager.delete.confirmation': 'Ben je zeker dat je deze inhoud wil verwijderen?',
  'file.manager.delete.failure': 'Er is iets fout gegaan tijdens het verwijderen.',
  'file.manager.delete.success': 'Inhoud verwijderd',
  'file.manager.delete.success.partial': 'Alle ongebruikte bestanden en/of folders zijn verwijderd.  Sommige bestanden worden nog gebruikt en zijn niet verwijderd.  Zorg ervoor dat ze niet meer gebruikt worden, pas dan kan u ze verwijderen.',
  'file.manager.empty-folder': 'Lege map',
  'file.manager.file-detail': 'File informatie',
  'file.manager.file-info': 'Informatie',
  'file.manager.file-size': 'Bestandsgrootte',
  'file.manager.files-uploaded': 'Bestand(en) geüpload',
  'file.manager.folder-name': 'Naam map',
  'file.manager.folderview.title': 'Folder detail',
  'file.manager.go-to-folder': 'Bekijk in bovenliggende map',
  'file.manager.last-modified-by': 'Laatst bewerkt door',
  'file.manager.last-modified-on': 'Laatst bewerkt op',
  'file.manager.manage-files': 'Beheer jouw bestanden',
  'file.manager.modifiedBy': 'Bewerkt door',
  'file.manager.modifiedOn': 'Bewerkingsdatum',
  'file.manager.move-confirmation': 'Ben je zeker dat je deze inhoud wil verplaatsen?',
  'file.manager.new-folder': 'Nieuwe map',
  'file.manager.new-folder-confirm': 'Aanmaken',
  'file.manager.not-applicable': 'Niet van toepassing',
  'file.manager.not-used': 'Niet gebruikt',
  'file.manager.not-used-lesson': "Dit bestand wordt niet gebruikt in een les.",
  'file.manager.overwrite.confirmation': 'Dit bestand bestaat al. Ben je zeker dat je het wil overschrijven?',
  'file.manager.paste': 'Plak',
  'file.manager.path': 'Pad',
  'file.manager.preview.title': 'Map en bestand preview en info',
  'file.manager.search-in-folder': 'Zoek in huidige map',
  'file.manager.search-in-root': 'zoek in "mijn bestanden"',
  'file.manager.search-no-results': 'Niets gevonden',
  'file.manager.search-no-results-in': 'Niets gevonden in',
  'file.manager.select-all': 'Selecteer alles',
  'file.manager.slide': 'Slide',
  'file.manager.slides': 'Slides',
  'file.manager.tree-title': 'Bestandsboom',
  'file.manager.upload': 'Bestanden uploaden',
  'file.manager.usage': 'Gebruik',

  // Formik
  'validation.empty': 'Dit veld mag niet leeg zijn',
  'validation.external_audio_url': 'Deze URL bevat geen audiofragment',
  'validation.external_image_url': 'This URL does not seem to contain an image[En]',
  'validation.external_video_url': 'Deze URL bevat geen Youtube, Vimeo of Dailymotion video',
  'validation.number': 'Dit veld moet een getal bevatten',
  'validation.required': 'Dit veld is vereist',
  'validation.url': 'Dit veld moet een geldige URL bevatten',

  // SlideSideBar
  'slide-sidebar.copy_slide_link': 'Kopieer link naar slide',
  'slide-sidebar.files': 'Mijn bestanden',
  'slide-sidebar.general': 'Algemeen',
  'slide-sidebar.link_copied': 'Link gekopieerd!',
  'slide-sidebar.slide_title': 'Slidetitel',
  'slide-sidebar.slide_type': 'Slidetype',

  // Steams
  'slide-sidebar-steams.activity': 'Type activiteit',
  'slide-sidebar-steams.chapter': 'Hoofdstuk',
  'slide-sidebar-steams.general': 'Algemeen',
  'slide-sidebar-steams.motivation': 'Motivatie',
  'slide-sidebar-steams.motivation.placeholder': 'Motivatie...',
  'slide-sidebar-steams.public': 'Kies publiek',
  'slide-sidebar-steams.time': 'Tijdsbesteding',
  'slide-sidebar-steams.time_text': 'Voeg aftelklok toe',
  'slide-sidebar-steams.visibility': 'Zichtbaarheid',
  'slide-sidebar-steams.visibility_choose': 'Kies rollen',
  'slide-sidebar-steams.visibility_text': 'Maak deze slide enkel zichtbaar voor bepaalde rollen',
  'slide-sidebar.steams': 'STEAMS',

  // QuestionAnswerMultipleChoice
  'question-answer-multiple-choice.button': 'Selecteer antwoord',

  // QuestionAnswerMultipleChoiceSideBar
  'question-answer-multiple-choice-sidebar.answer': 'Antwoord',
  'question-answer-multiple-choice-sidebar.explanation': 'Uitleg',
  'question-answer-multiple-choice-sidebar.is_correct_answer': 'Correct antwoord?',
  'question-answer-multiple-choice-sidebar.warning.content': 'Leerlingen hebben mogelijks deze vraag al beantwoord. Hierdoor kan je enkel kleine wijzigingen aanbrengen zoals typefouten verbeteren. Indien je toch grotere aanpassingen wenst te doen moet je de slide dupliceren.',
  'question-answer-multiple-choice-sidebar.warning.duplicate': 'Slide dupliceren',
  'question-answer-multiple-choice-sidebar.warning.small': 'Kleine aanpassing',
  'question-answer-multiple-choice-sidebar.warning.title': 'Opgelet, deze vraag is reeds gepubliceerd',

  // HintsSideBar
  'hints-sidebar.add_hint': 'Nieuwe hint',
  'hints-sidebar.item.content': 'Inhoud',
  'hints-sidebar.item.hint_x': 'Hint',
  'hints-sidebar.item.title': 'Titel',
  'hints-sidebar.title': 'Hints',

  // TeacherInfoSideBar
  'teacher-info-sidebar.description': 'Hier kunnen notities geplaatst worden voor de leraar. Deze info wordt nooit gedeeld met leerlingen.',
  'teacher-info-sidebar.title': 'Informatie voor de leraar',

  // MultipleChoiceQuestionSideBar
  'multiple-choice-question-sidebar.max_selectable_answers': 'Max. keuzemogelijkheden',
  'multiple-choice-question-sidebar.score_per_correct_answer': 'Aantal punten per correct antwoord',
  'multiple-choice-question-sidebar.should_find_all_correct': 'Student moet ze allemaal vinden',
  'multiple-choice-question-sidebar.shuffle_answers': 'Antwoorden door elkaar halen?',
  'multiple-choice-question-sidebar.subtract_per_wrong_answer': 'Aantal minpunten per fout antwoord',

  // OpenQuestionSideBar
  'open-question-sidebar.example_solution': 'Voorbeeldoplossing',
  'open-question-sidebar.placeholder': 'Placeholder',
  'open-question-sidebar.points': 'Totaal punten',
  'open-question-sidebar.show_the_answer': 'Toon het antwoord aan de leerling nadat hij heeft geantwoord',
  'open-question-sidebar.show_the_answer_no': 'Nee',
  'open-question-sidebar.show_the_answer_yes': 'Ja',

  // LastSavedAt
  'last-saved-at.a_few_seconds_ago': 'een paar seconden geleden',

  // ExerciseOverview
  'exercise-overview.delete_fail': 'Fout bij verwijderen van oefening',
  'exercise-overview.delete_success': 'De oefening werd verwijderd',
  'exercise-overview.duplicate-error': 'Fout bij het dupliceren van de oefening',
  'exercise-overview.duplicate-success': 'De oefening werd succesvol gedupliceerd',
  'exercise-overview.empty': 'Er zijn nog geen oefeningen',
  'exercise-overview.empty_page': 'Er zijn geen oefeningen op deze pagina',
  'exercise-overview.empty_page_action': 'Ga naar de eerste pagina',
  'exercise-overview.new_exercise': 'Nieuwe oefening',
  'exercise-overview.title': 'Oefeningen',

  // ExerciseCreateDialog
  'exercise-create-dialog.label.title.dutch': 'Nederlandse titel',
  'exercise-create-dialog.label.title.english': 'Engelse titel',
  'exercise-create-dialog.label.title.french': 'Franse titel',
  'exercise-create-dialog.label.title.german': 'Duitse titel',
  'exercise-create-dialog.label.title.spanish': 'Spaanse titel',
  'exercise-create-dialog.title': 'Nieuwe oefening',
  'exercise-version-create-dialog.submit': 'Voeg toe',
  'exercise-version-create-dialog.title': 'Voeg versie toe',
  'exercise-version-dialog.name': 'Naam',
  'exercise-version-dialog.versionType': 'Versie type',
  'exercise-version-update-dialog.submit': 'Wijzig',
  'exercise-version-update-dialog.title': 'Wijzig versie',
  'scratch.version.DEMO': 'Demo',
  'scratch.version.EXTRA': 'Extra',
  'scratch.version.OTHER': 'Andere',
  'scratch.version.SOLUTION': 'Oplossing',
  'scratch.version.STARTER': 'Starter',

  // Exercise
  'exercise.error': 'Fout bij het laden van je oefening',
  'exercise.forbidden_error': 'Je hebt geen toegang tot deze oefening',

  // Scratch
  'scratch.confirmation.content': 'Ben je zeker dat je de oefening wilt herstarten?',
  'scratch.header.turbo': 'Turbo',
  'scratch.header.upload-exercise': 'Upload oefening',
  'scratch.test-plan.error': 'Er is geen testplan gelinkt aan deze oefening',
  'scratch.upload-test-plan.error': 'Er liep iets mis bij het uploaden van het testplan',
  'scratch.upload-test-plan.success': 'Het testplan is aangemaakt',

  // Coach Codi
  'assistant.always-here-to-help': "Altijd hier om jou te helpen.",
  'assistant.chat.need-help': 'Hulp nodig? Klik hier voor een hint.',
  'assistant.name': "Coach {name}",
  'codi.messages.test_ready': 'Er staan testresultaten voor je klaar.',
  'codi.messages.tests_loading': 'Ik ben je oefening aan het testen. Dit kan even duren.',
  'codi.messages.tests_more_all_correct': 'Alle testen zijn geslaagd! Goed gewerkt!',
  'codi.messages.tests_more_than_half_correct': 'Meer dan de helft van mijn testen werken al! Goed bezig!',
  'codi.messages.welcome': 'Hallo, ik ben Coach Codi! Ik zal jouw code testen. Druk op de knop rechts om te beginnen.',

  // Scratch gui
  'gui.ftrprf.contextMenu.display_blocks': 'Toon blokken',
  'gui.ftrprf.contextMenu.hide_blocks': 'Verberg blokken',
  'gui.ftrprf.testresult.click_to_test': 'Test je code!',
  'gui.ftrprf.testresult.hide_subtests': 'Verberg alle deeltesten',
  'gui.ftrprf.testresult.show_subtests': 'Toon alle deeltesten',
  'gui.ftrprf.testresult.test': 'Test',
  'gui.ftrprf.testresult.tests': 'Testen',
  'gui.ftrprf.test-tab': 'Coach Codi',
  'gui.ftrprf.test-tab.latest_test': 'Laatste testresultaat',
  'gui.ftrprf.test-tab.loading': 'Aan het controleren...',
  'gui.ftrprf.test-tab.old_tests': 'Oude testresultaten',
  'gui.ftrprf.translation-tab.add': 'Toevoegen',
  'gui.ftrprf.translation-tab.add_translation': 'Nieuwe vertaling toevoegen',
  'gui.ftrprf.translation-tab.chosen_language': 'Gekozen taal',
  'gui.ftrprf.translation-tab.dictionary': 'Woordenboek',
  'gui.ftrprf.translation-tab.edit': 'Bewerken',
  'gui.ftrprf.translation-tab.edit_translation': 'Bewerk vertaling',
  'gui.ftrprf.translation-tab.groupname': 'Groep',
  'gui.ftrprf.translation-tab.translate': 'Vertaal',
  'gui.ftrprf.translation-tab.translation_code': 'Code vertaling',
  'gui.ftrprf.translation-tab.translations': 'Vertalingen',

  // Logout
  'logout.extra': 'Even geduld, we loggen je uit op al onze platformen...',
  'logout.title': 'Bezig met uitloggen',

  // impersonate
  "impersonate.description": "Helaas kan je nog niet aan je avontuur beginnen. Probeer zo dadelijk eens opnieuw!",
  "impersonate.header": "We gaan aan boord, blijf alsjeblieft zitten...",
  "impersonate.header.failed": "We probeerden je aan boord te krijgen",
  'global.unimpersonate': 'Stop impersonaten',

  // question disclosure
  "question.hide.answers": "Verberg antwoorden",
  "question.show.answers": "Toon antwoorden",
};
