import { useCallback, useEffect, useRef } from 'react';
import { gql, useApolloClient } from '@apollo/client';
import { useRecoilState } from 'recoil';

import useFieldMutation from '../../../hooks/graphql/useFieldMutation';

import { UPDATE_QUESTION_OPEN } from '../../../api/question';

import { questionAtom } from '../utils/atom';

const read = (client, id) =>
  client.readFragment({
    fragment: gql`
      fragment MyQuestion on QuestionOpen {
        id
        type
        publishedAt
        value
        scorePerCorrectAnswer
        placeholder
        solution
        questionAnswerOpen {
          id
          explanation
          optional
        }
      }
    `,
    id: `QuestionOpen:${id}`,
  });

export default function useOpenQuestion(id) {
  const client = useApolloClient();
  const ref = useRef();

  const [question, setQuestion] = useRecoilState(questionAtom(id));

  const refetch = useCallback(() => {
    setQuestion(read(client, id));
  }, [client, id, setQuestion]);

  const [mutate] = useFieldMutation(UPDATE_QUESTION_OPEN, 'Question', [
    'id',
    'solution',
    'placeholder',
    'scorePerCorrectAnswer',
  ]);

  useEffect(() => {
    if (question === null) {
      refetch();
    }
  }, [question, refetch]);

  const debounceMutation = useCallback(
    (options) => {
      if (ref.current) {
        clearTimeout(ref.current);
      }

      ref.current = setTimeout(() => {
        mutate(options);
      }, 250);
    },
    [ref, mutate],
  );

  const update = useCallback(
    (variables) => {
      setQuestion((l) => {
        const newValue = { ...l, ...variables };

        debounceMutation(newValue);

        return newValue;
      });
    },
    [debounceMutation, setQuestion],
  );

  return {
    question,
    setQuestion,
    update,
  };
}
