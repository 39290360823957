import { useState, useContext } from 'react';

import {
  CheckBox,
  Input,
  InputGroup,
  Label,
  ActionStyledAsButton,
} from '@ftrprf/tailwind-components';

import alphabet from '../../../../utils/functions/alphabet';
import Collapsable from '../../../../components/Collapsable';
// eslint-disable-next-line import/no-cycle
import Editor, {
  ImagePlugin,
  LinkPlugin,
} from '../../../../components/Editor/Editor';

import useFormatMessage from '../../../../hooks/useFormatMessage';
import useMultipleChoiceQuestion from '../../hooks/useMultipleChoiceQuestion';
import useQuestionAnswerMultipleChoice from '../../hooks/useQuestionAnswerMultipleChoice';

import SideBarContent from './SideBarContent';
import SideBarFooter from './SideBarFooter';
import { Ck5SlideEditorContainer } from '../Ck5Editor/Ck5SlideEditorContainer';
import { SideBarContext } from './SideBarContextProvider';

export default function QuestionAnswerMultipleChoiceSideBar({
  disabled,
  id,
  index,
  questionId,
}) {
  const t = useFormatMessage();
  const { isCk5 } = useContext(SideBarContext);

  const [, setLocalQuestionState] = useState();
  const [, setLocalExplanationState] = useState();

  const { questionAnswerMultipleChoice, update } =
    useQuestionAnswerMultipleChoice(id);

  const updateLocalQuestionState = (value) => {
    update({ value });
    setLocalQuestionState(value);
  };

  const updateExplanationState = (value) => {
    update({ explanation: value });
    setLocalExplanationState(value);
  };

  const { question, removeQuestionAnswerMultipleChoice } =
    useMultipleChoiceQuestion(questionId);

  if (!questionAnswerMultipleChoice) {
    return null;
  }

  return (
    <div className="flex flex-col">
      <SideBarContent
        className="overflow-auto h-full"
        hFull
        title={`${t('global.answer')} ${alphabet(index)}`}
      >
        <InputGroup className="border-b border-gray-300 p-3">
          <CheckBox
            checked={questionAnswerMultipleChoice.correct}
            disabled={disabled || question.publishedAt}
            label={t(
              'question-answer-multiple-choice-sidebar.is_correct_answer',
            )}
            onChange={(checked) => update({ correct: checked })}
          />
        </InputGroup>

        <div className="flex-grow flex flex-col">
          <Label className="px-3">
            {t('question-answer-multiple-choice-sidebar.answer')}
          </Label>
          {isCk5 ? (
            <Ck5SlideEditorContainer
              disabled={disabled}
              id="sidebar-multiple-choice-answer"
              isInSideBar
              isLoaded
              onChange={updateLocalQuestionState}
              value={questionAnswerMultipleChoice.value || ''}
            />
          ) : (
            <Editor
              className="border-t border-b border-gray-300 py-1"
              disabled={disabled}
              onChange={updateLocalQuestionState}
              plugins={[LinkPlugin, ImagePlugin]}
              value={questionAnswerMultipleChoice.value || ''}
              withBorder={false}
            />
          )}
        </div>

        <div className="flex flex-col">
          <Collapsable
            className="pr-3 pb-2 pt-3"
            trigger={
              <Label className="px-3 flex-grow">
                {t('question-answer-multiple-choice-sidebar.explanation')}{' '}
                <span className="text-xs text-gray-600">
                  {t('global.not_required')}
                </span>
              </Label>
            }
          >
            <Input
              className="border-t border-gray-300 py-1 h-32 m-1 text-sm"
              disabled={disabled}
              onChange={(e) => updateExplanationState(e.target.value)}
              type="multiline"
              value={questionAnswerMultipleChoice.explanation || ''}
            />
          </Collapsable>
        </div>
      </SideBarContent>

      <SideBarFooter>
        <ActionStyledAsButton
          disabled={disabled || question.publishedAt}
          onClick={() => removeQuestionAnswerMultipleChoice(id)}
          secondary
        >
          {t('global.delete')}
        </ActionStyledAsButton>
      </SideBarFooter>
    </div>
  );
}
