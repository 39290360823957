import { useCallback, useContext, useEffect, useState } from 'react';
// eslint-disable-next-line import/no-cycle
import {
  SIDE_BARS,
  SideBarContext,
} from '../../SideBars/SideBarContextProvider';
import { modalHtml } from './helpers/modalHtml';

export function ModalPlugin({
  isInSideBar,
  onDismiss,
  save,
  selectedElement,
  slide,
}) {
  const { currentSideBar, setSideBar } = useContext(SideBarContext);
  const [modalPluginSelectedElement] = useState(selectedElement);
  const [sideBarOnStart] = useState(currentSideBar);

  const dismiss = useCallback(() => {
    onDismiss();
    isInSideBar ? setSideBar(sideBarOnStart) : setSideBar(SIDE_BARS.DEFAULT);
  }, [isInSideBar, onDismiss, setSideBar, sideBarOnStart]);

  useEffect(() => {
    setSideBar({
      initialModal: modalPluginSelectedElement,
      onDismiss: dismiss,
      onRemove: () => {
        save('');
        dismiss();
      },
      onSubmit: (modal) => {
        dismiss();
        save(modalHtml(modal));
      },
      slide,
      type: SIDE_BARS.MODAL_SIDEBAR,
    });
  }, [dismiss, modalPluginSelectedElement, save, setSideBar, slide]);

  return null;
}
