export function mapTagToOption(tag) {
  return {
    key: tag.id,
    value: tag.name,
    label: tag.name,
  };
}

export function mapEndGoalToOption(endGoal) {
  return {
    key: endGoal.id,
    value: endGoal,
    label: endGoal.key,
  };
}
