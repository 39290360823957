import { useContext } from 'react';
import { useApolloClient } from '@apollo/client';

import { NotificationContext } from '@ftrprf/tailwind-components';

import { REMOVE_SCRATCH_EXERCISE } from '../../api/exercise';

import useFormatMessage from '../useFormatMessage';

import useFieldMutation from './useFieldMutation';

export default function useRemoveExerciseMutation() {
  const t = useFormatMessage();
  const client = useApolloClient();
  const [removeLessonMutate] = useFieldMutation(
    REMOVE_SCRATCH_EXERCISE,
    'Exercise',
  );
  const { addNotification } = useContext(NotificationContext);

  const remove = (id) =>
    removeLessonMutate({ id })
      .then(() => {
        client.reFetchObservableQueries();

        addNotification({
          type: 'success',
          content: t('exercise-overview.delete_success'),
        });
      })
      .catch(() => {
        addNotification({
          type: 'error',
          content: t('exercise-overview.delete_fail'),
        });
      });

  return remove;
}
