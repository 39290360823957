import { gql } from '@apollo/client';

export const CREATE_SLIDE = gql`
  mutation CreateSlide(
    $lessonContentId: Int!
    $title: String
    $content: String
    $sequence: Int!
    $viewModes: [ViewMode!]!
    $motivation: String
  ) {
    createSlide(
      lessonContentId: $lessonContentId
      input: {
        sequence: $sequence
        viewModes: $viewModes
        title: $title
        content: $content
        motivation: $motivation
      }
    ) {
      id
      title
      content
      sequence
      motivation
      viewModes
      info
      questions {
        id
        type
        publishedAt
        ... on QuestionOpen {
          value
          scorePerCorrectAnswer
          placeholder
          solution
          questionAnswerOpen {
            id
            explanation
            optional
          }
        }
        ... on QuestionMultipleChoice {
          value
          scorePerCorrectAnswer
          subtractPerWrongAnswer
          maxSelectableAnswers
          shouldFindAllCorrect
          shuffleAnswers
          questionAnswersMultipleChoice {
            id
            value
            correct
            explanation
          }
        }
      }
      hints {
        id
        title
        content
      }
    }
  }
`;

export const UPDATE_SLIDE = gql`
  mutation UpdateSlide(
    $id: Int!
    $title: String
    $content: String
    $sequence: Int!
    $viewModes: [ViewMode!]!
    $info: String
    $motivation: String
    $groupRoles: [LessonGroupRoleInputForSlide]
    $background: String
  ) {
    updateSlide(
      id: $id
      input: {
        title: $title
        content: $content
        sequence: $sequence
        viewModes: $viewModes
        info: $info
        motivation: $motivation
        groupRoles: $groupRoles
        background: $background
      }
    ) {
      id
      title
      content
      sequence
      viewModes
      info
      motivation
    }
  }
`;

export const REMOVE_SLIDE = gql`
  mutation RemoveSlide($id: Int!, $slideSequences: [SlideSequence]) {
    removeSlide(id: $id, slideSequences: $slideSequences) {
      id
      statuscode
      message
    }
  }
`;

export const UPDATE_SLIDE_SEQUENCES = gql`
  mutation UpdateSlideSequences(
    $lessonContentId: Int!
    $slideSequences: [SlideSequence]
  ) {
    updateSlideSequences(
      lessonContentId: $lessonContentId
      slideSequences: $slideSequences
    ) {
      id
    }
  }
`;

export const DUPLICATE_SLIDE = gql`
  mutation DuplicateSlide($id: Int!) {
    duplicateSlide(id: $id) {
      id
      title
      content
      sequence
      motivation
      viewModes
      info
      questions {
        id
        type
        publishedAt
        ... on QuestionOpen {
          value
          scorePerCorrectAnswer
          placeholder
          solution
          questionAnswerOpen {
            id
            explanation
            optional
          }
        }
        ... on QuestionMultipleChoice {
          value
          scorePerCorrectAnswer
          subtractPerWrongAnswer
          maxSelectableAnswers
          shouldFindAllCorrect
          shuffleAnswers
          questionAnswersMultipleChoice {
            id
            value
            correct
            explanation
          }
        }
      }
      hints {
        id
        title
        content
      }
    }
  }
`;
