import { BsFiles } from 'react-icons/bs';
import { FaFile, FaFolder } from 'react-icons/fa';

import { FILE_EXTENSIONS, FILE_TYPES } from 'utils/constants/fileTypes';
import c from 'utils/functions/c';

import VideoPreview from './FilePreview/VideoPreview';

export default function ContentIcon({
  blobUri: url,
  className,
  extension: ext,
  isFile,
  multiple,
  name,
  thumbnail,
}) {
  if (multiple) {
    return (
      <span className={className}>
        <BsFiles />
      </span>
    );
  }

  if (!isFile) {
    return (
      <span className={className}>
        <FaFolder />
      </span>
    );
  }

  const extension = ext ? ext.toLowerCase() : '';
  const fileType = Object.values(FILE_EXTENSIONS).find((typeExtensions) =>
    typeExtensions.extensions.includes(extension),
  );

  if (!fileType) {
    return (
      <span className={className}>
        <FaFile />
      </span>
    );
  }

  let iconElement;

  if (fileType.type === FILE_TYPES.IMAGE && url) {
    iconElement = (
      <img
        alt={name}
        className={c('inline object-contain', thumbnail ? 'w-6 h-6' : 'h-full')}
        src={url}
      />
    );
  }

  if (fileType.type === FILE_TYPES.VIDEO && url) {
    iconElement = thumbnail ? null : <VideoPreview url={url} />;
  }

  return <span className={className}>{iconElement || fileType.icon}</span>;
}
