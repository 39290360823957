import { useEffect, useMemo } from 'react';
import { useLazyQuery } from '@apollo/client';

import { INITIALIZE_CONTENT_NAVIGATION_TREE_FROM_PATH } from 'api/filemanager';

export default function useContentNavigationTreeFromPath(
  path,
  enabled = false,
) {
  const [fetch, { data, error, loading }] = useLazyQuery(
    INITIALIZE_CONTENT_NAVIGATION_TREE_FROM_PATH,
    {
      fetchPolicy: 'network-only',
    },
  );
  const parsedData = useMemo(() => {
    if (!data) {
      return undefined;
    }
    return JSON.parse(data.initializeContentNavigationTreeFromPath.content);
  }, [data]);

  useEffect(() => {
    if (enabled && !data && !loading && !error) {
      fetch({
        variables: {
          path,
        },
      });
    }
  }, [data, enabled, error, fetch, loading, path]);

  return { data: parsedData, loading, error, fetch };
}
