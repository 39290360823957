import { cloneElement, useState } from 'react';
import { FaChevronDown } from 'react-icons/fa';

import c from 'utils/functions/c';

export default function Collapsable({
  children,
  className = '',
  initialShown = false,
  trigger,
}) {
  const [isShown, setIsShown] = useState(initialShown);

  return (
    <>
      <div
        className={c(className, 'flex flex-grow items-center justify-center')}
        onClick={() => setIsShown((prev) => !prev)}
      >
        {cloneElement(trigger, {
          className: c(trigger.props.className, 'cursor-pointer'),
        })}
        <FaChevronDown
          className={c(
            'w-3 cursor-pointer',
            isShown
              ? 'transform -scale-y-1 transition duration-150'
              : 'transition duration-150',
          )}
        />
      </div>
      {isShown && children}
    </>
  );
}
