import { useCallback } from 'react';
import { useMutation } from '@apollo/client';

import {
  UPDATE_SCRATCH_EXERCISE,
  UPDATE_SCRATCH_EXERCISE_BLOB,
} from '../../api/exercise';

import useContentMutation from './useContentMutation';

export default function useExerciseMutation() {
  const { uploadFiles, uploading } = useContentMutation({
    path: '/scratch_exercise_images_all/',
  });
  const [
    updateExercise,
    { error: errorExerciseLoading, loading: updateExerciseLoading },
  ] = useMutation(UPDATE_SCRATCH_EXERCISE);
  const [
    updateExerciseBlob,
    { error: errorBlobLoading, loading: updateBlobLoading },
  ] = useMutation(UPDATE_SCRATCH_EXERCISE_BLOB);

  const uploadThumbnail = useCallback(
    (id, thumbnail) =>
      uploadFiles({
        files: [thumbnail],
        names: [`${id}.png`],
        showNotification: false,
      }).then(
        ({ data: { createContentFiles } }) => createContentFiles[0].blobUri,
      ),
    [uploadFiles],
  );

  const updateFullExercise = useCallback(
    ({
      variables: {
        file,
        id,
        thumbnail,
        titleDe,
        titleEn,
        titleEs,
        titleFr,
        titleNl,
        versionId,
      },
    }) => {
      uploadThumbnail(id, thumbnail).then((thumbnailUrl) => {
        updateExercise({
          variables: {
            id,
            titleEn,
            titleNl,
            titleFr,
            titleDe,
            titleEs,
            image: thumbnailUrl,
          },
        });
        updateExerciseBlob({ variables: { versionId, file } });
      });
    },
    [updateExercise, updateExerciseBlob, uploadThumbnail],
  );

  return [
    updateFullExercise,
    {
      loading: updateExerciseLoading || updateBlobLoading || uploading,
      error: errorExerciseLoading || errorBlobLoading,
    },
  ];
}
