import { useState } from 'react';
import { useQuery } from '@apollo/client';

const SCROLL_PERCENTAGE = 0.2;

export default function useInfiniteScrollQuery(
  query,
  { variables } = { variables: {} },
) {
  const [page, setPage] = useState(0);

  const {
    data,
    error,
    fetchMore: apolloFetchMore,
    loading,
  } = useQuery(query, {
    variables: { page, ...variables },
  });

  const maxPages =
    data && data.findAllExercises ? data.findAllExercises.pages - 1 : 0;

  const onInfiniteScroll = ({ target }) => {
    const THRESHOLD =
      target.clientHeight * SCROLL_PERCENTAGE + target.clientHeight;
    const scrollBottom = target.scrollHeight - target.scrollTop;

    if (scrollBottom < THRESHOLD && page < maxPages) {
      void apolloFetchMore({ page: page + 1 });
      setPage((prev) => prev + 1);
    }
  };

  const fetchMore = ({ variables }) => {
    if (variables.filter) {
      setPage(0);
    }

    void apolloFetchMore({ variables: { page: 0, ...variables } });
  };

  return { onInfiniteScroll, data, loading, error, fetchMore };
}
