import { useContext, useRef, useState } from 'react';
import {
  ArrowDownTrayIcon,
  ArrowTopRightOnSquareIcon,
  ClipboardIcon,
} from '@heroicons/react/24/solid';

import {
  NotificationContext,
  ActionStyledAsButton,
} from '@ftrprf/tailwind-components';

import useFormatMessage from 'hooks/useFormatMessage';

import useContentRename from 'hooks/graphql/file-manager/mutations/useContentRename';
import { fileDateFormatter } from '../utils/fileDateFormatter';
import { fileSizeFormatter } from '../utils/fileSizeFormatter';

import { FileManagerContext } from '../FileManagerContextProvider';

import ThumbnailLarge from './ThumbnailLarge';
import FileUsagesDetail from './FileUsagesDetail';

let saveTimer;

export default function ContentPreview({ content }) {
  const t = useFormatMessage();
  const {
    isSearching,
    navigateToFolder,
    refetchCurrentFolderContent,
    reloadFileTree,
    setIsSearching,
  } = useContext(FileManagerContext);
  const { addNotification } = useContext(NotificationContext);

  const [fileName, setFileName] = useState(content.name);
  const nameInputRef = useRef();

  const { renameContent } = useContentRename();

  const onNameChange = (e) => {
    const { value } = e.target;
    clearTimeout(saveTimer);
    saveTimer = setTimeout(async () => {
      nameInputRef.current.blur();
      await renameContent({
        variables: { contentFileId: content.id, newName: value },
      });
      refetchCurrentFolderContent();
      if (!content.isFile) {
        reloadFileTree();
      }
    }, 3000);

    setFileName(value);
  };

  const copyDownloadLink = () => {
    navigator.clipboard.writeText(content.blobUri);
    addNotification({
      type: 'success',
      content: t('global.copied'),
    });
  };

  return (
    <div className="min-w-[8rem] w-[30%] ml-2 overflow-auto scrollbar-hide">
      <h3 className="sr-only">{t('file.manager.preview.title')}</h3>
      <div className="bg-white mb-2 border border-gray-200 border-r-0">
        <div className="flex flex-none h-10 border-b-2 items-center px-2 gap-2 max-w-full">
          {content.blobUri && (
            <div className="flex gap-2">
              <ActionStyledAsButton
                iconBefore={ClipboardIcon}
                iconMargin="mr-0"
                onClick={copyDownloadLink}
                secondary
                size="small"
              >
                <span className="sr-only">{t('file.manager.copy-link')}</span>
              </ActionStyledAsButton>

              <ActionStyledAsButton
                download
                href={content.blobUri}
                iconBefore={ArrowDownTrayIcon}
                iconMargin="mr-0"
                secondary
                size="small"
                target="_blank"
              >
                <span className="sr-only">{t('global.download')}</span>
              </ActionStyledAsButton>
            </div>
          )}

          <div className="flex w-full">
            <input
              ref={nameInputRef}
              className="w-full h-full outline-none border rounded-l p-1"
              disabled={isSearching}
              onChange={onNameChange}
              value={fileName}
            />
            {content.extension && (
              <p className="font-bold p-1 border bg-gray-100 rounded-r border-l-0">
                .{content.extension}
              </p>
            )}
          </div>
        </div>
        <div className="grow grid place-items-center png-background">
          <ThumbnailLarge
            blobUri={content.blobUri}
            extension={content.extension}
            isFile={content.isFile}
            thumbnail={content.thumbnailLarge}
          />
        </div>
      </div>
      <div className="bg-white mb-2 border border-gray-200 border-r-0">
        <h3 className="h-10 font-bold border-b-2 border-accent-500 text-accent-500 grid place-items-center">
          {t('file.manager.file-info')}
        </h3>
        {isSearching && (
          <ActionStyledAsButton
            className="justify-center"
            iconBefore={ArrowTopRightOnSquareIcon}
            onClick={() => {
              navigateToFolder({
                id: content.parentId,
                path: content.path.replace(`/${content.name}`, ''),
              });
              setIsSearching(false);
            }}
            secondary
          >
            {t('file.manager.go-to-folder')}
          </ActionStyledAsButton>
        )}

        <dl className="py-4 px-2">
          <div className="flex justify-center items-center lg:gap-2 flex-col lg:flex-row">
            <dt className="font-bold lg:text-right w-1/2">
              {t('file.manager.creator')}
            </dt>
            <dd className="w-1/2">{content.createdBy}</dd>
          </div>

          <div className="flex justify-center items-center lg:gap-2 flex-col lg:flex-row">
            <dt className="font-bold lg:text-right w-1/2">
              {t('file.manager.created-on')}
            </dt>
            <dd className="w-1/2">{fileDateFormatter(content.createdOn)}</dd>
          </div>

          <div className="flex justify-center items-center lg:gap-2 flex-col lg:flex-row">
            <dt className="font-bold lg:text-right w-1/2">
              {t('file.manager.last-modified-by')}
            </dt>
            <dd className="w-1/2">{content.modifiedBy}</dd>
          </div>

          <div className="flex justify-center items-center lg:gap-2 flex-col lg:flex-row">
            <dt className="font-bold lg:text-right w-1/2">
              {t('file.manager.last-modified-on')}
            </dt>
            <dd className="w-1/2">{fileDateFormatter(content.modifiedOn)}</dd>
          </div>

          <div className="flex justify-center items-center lg:gap-2 flex-col lg:flex-row">
            <dt className="font-bold lg:text-right w-1/2">
              {t('file.manager.file-size')}
            </dt>
            <dd className="w-1/2">{fileSizeFormatter(content.fileSize)}</dd>
          </div>
        </dl>
      </div>
      {content.isFile && (
        <div className="bg-white mb-2 border border-gray-200 border-r-0">
          <h3 className="h-10 font-bold border-b-2 border-accent-500 text-accent-500 grid place-items-center">
            {t('file.manager.usage')}
          </h3>
          <div className="p-4">
            <FileUsagesDetail slides={content.slides} />
          </div>
        </div>
      )}
    </div>
  );
}
